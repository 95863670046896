//人気の求人
import React from "react";
import {
  makeStyles,
  withStyles,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { ReactComponent as IconListH } from "../../assets/images/list_h.svg";
import { MedSearchColors } from "../../assets/theme";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3%",
  },
  btnWrap: {
    textAlign: "right",
  },
}));

const JobsListItem = withStyles((theme) => ({
  root: {
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
  },
}))(ListItem);

const jobsList = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
export default function PopularJobsList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">人気の求人</Typography>
      <Typography variant="h2">人気の求人</Typography>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        {jobsList.map((item) => (
          <Grid item xs={12} md={6} lg={3}>
            <List>
              <JobsListItem alignItems="flex-start" button>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: MedSearchColors.primary }}>
                    <IconListH />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="東名厚木病院  消化器內科"
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="h4">
                        勤務地
                      </Typography>
                      {"　"}
                      <Typography component="span" variant="h5">
                        神奈川県　厚木市
                      </Typography>
                      <Divider style={{ margin: "8px auto" }} />
                      <Typography component="span" variant="h4">
                        勤務形態
                      </Typography>{" "}
                      <Typography component="span" variant="h5">
                        常勤
                      </Typography>
                      <Divider style={{ margin: "8px auto" }} />
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          component="div"
                          variant="h4"
                          style={{ minWidth: 55 }}
                        >
                          募集科目
                        </Typography>
                        <Typography component="div" variant="h5">
                          消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科
                        </Typography>
                      </div>
                    </React.Fragment>
                  }
                />
              </JobsListItem>
            </List>
          </Grid>
        ))}
      </Grid>
      <div className={classes.btnWrap}>
        <Button variant="text" color="default" endIcon={<ChevronRightIcon />}>
          その他の求人
        </Button>
      </div>
    </div>
  );
}
