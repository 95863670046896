import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
  Grid,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: 980,
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  "& .MuiDialogTitle-root": {
    // padding: "8px 18px",
  },
  "& .MuiDialogContent-root": {
    padding: "2%",
    overflowX: "hidden",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
  },
}));

const CategoryCheckbox = styled(Checkbox)({
  color: "white",
  padding: 5,
  "&.Mui-checked": {
    color: "white",
  },
});
export default function DoctorSpaceCheckboxDialog({
  title,
  dataList,
  //   setSelectedList = []
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [categoryGroup, setCategoryGroup] = useState(
    Object.entries(
      _.groupBy(
        dataList.map((item) => ({ ...item, checked: false })),
        "category"
      )
    ).map(([category, items]) => ({
      category: category,
      items: items,
      selected: false,
    }))
  );

  const changeCategory = (selectedCategory) => {
    let tempGroup = categoryGroup.map((obj) =>
      obj.category !== selectedCategory
        ? obj
        : {
            ...obj,
            selected: !obj.selected,
            items: obj.items.map((item) => ({
              ...item,
              checked: !obj.selected,
            })),
          }
    );
    let tempSelecedList = [];
    tempGroup.forEach((obj) =>
      obj.items.forEach((item) => {
        if (item.checked)
          tempSelecedList.push({ id: item.id, title: item.title });
      })
    );

    setCategoryGroup([...tempGroup]);
    setSelectedList([...tempSelecedList]);
  };

  const changeItem = (selectedCategory, selectedItemId) => {
    const tempGroup = categoryGroup.map((obj) =>
      obj.category !== selectedCategory
        ? obj
        : {
            ...obj,
            items: obj.items.map((item) =>
              item.id !== selectedItemId
                ? item
                : { ...item, checked: !item.checked }
            ),
          }
    );
    let tempSelecedList = [];
    tempGroup.forEach((obj) =>
      obj.items.forEach((item) => {
        if (item.checked)
          tempSelecedList.push({ id: item.id, title: item.title });
      })
    );

    setCategoryGroup([...tempGroup]);
    setSelectedList([...tempSelecedList]);
  };

  const handleReset = () => {
    let tempGroup = categoryGroup.map((obj) => ({
      ...obj,
      selected: false,
      items: obj.items.map((item) => ({ ...item, checked: false })),
    }));
    setCategoryGroup([...tempGroup]);
    setSelectedList([]);
  };
  return (
    <div>
      <TextField
        label={title}
        onClick={() => setOpenDialog(true)}
        fullWidth
        value={selectedList.map((item) => item.title)}
      />
      <CustomizedDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle
          disableTypography
          style={{
            color: "#1e78e6",
            fontWeight: 700,
            fontSize: 18,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {title}
          <IconButton
            color="default"
            onClick={() => {
              handleReset();
              setOpenDialog(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="center"
          >
            {categoryGroup.map((category) => (
              <Grid
                item
                key={category.category}
                xs={12}
                sm={categoryGroup.length === 1 ? 12 : 6}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel
                    style={{
                      borderLeft: "8px solid rgb(240, 190, 100)",
                      backgroundColor: "rgb(25, 200, 151)",
                      color: "white",
                    }}
                  >
                    <FormControlLabel
                      checked={category.selected}
                      control={
                        <CategoryCheckbox
                          onChange={() => changeCategory(category.category)}
                        />
                      }
                      label={category.category}
                      labelPlacement="start"
                    />
                  </FormLabel>
                  <FormGroup style={{ flexDirection: "row" }}>
                    {category.items.map(
                      (item) =>
                        item.title !== "" && (
                          <FormControlLabel
                            key={item.id}
                            control={
                              <Checkbox
                                disabled={category.selected}
                                size="small"
                                checked={item.checked}
                                onChange={(e) =>
                                  changeItem(category.category, item.id)
                                }
                              />
                            }
                            label={
                              <span
                                style={{
                                  marginRight: 12,
                                  fontSize: 14,
                                }}
                              >
                                {item.title}
                              </span>
                            }
                          />
                        )
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => setOpenDialog(false)}
          >
            設　定{" "}
          </Button>
          <Button variant="text" color="secondary" onClick={handleReset}>
            クリア
          </Button>
          {/* <Button
            variant="text"
            color="default"
            onClick={() => setOpenDialog(false)}
          >
            キャンセル
          </Button> */}
        </DialogActions>
      </CustomizedDialog>
    </div>
  );
}
