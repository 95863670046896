//新着求人情報 & よくある質問 Q &A
import { makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { MedSearchColors } from "../../../assets/theme";
import NewJobsList from "./NewJobsList";
import QandAList from "./QandAList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: MedSearchColors.primary010,
    padding: "80px 5%",
  },
}));

function InfoList() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        alignContent="center"
      >
        <Grid item xs={12} md={6}>
          <NewJobsList />
        </Grid>
        <Grid item xs={12} md={6}>
          <QandAList />
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoList;
