import React, { useState } from "react";
import { IconButton, makeStyles, Typography, Button } from "@material-ui/core";
import MedSearchList from "../../../../components/common/MedSearchList";
import { useHistory } from "react-router-dom";

import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { applicantList } from "./DemoList";
import { MedSearchPagination } from "../../../../components/common/MedSearchPagination";

const useStyles = makeStyles((theme) => ({
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  contentWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: 46,
    [theme.breakpoints.down("sm")]: {
      height: 60,
      justifyContent: "center",
    },
  },
  itemWrap: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function InvoiceList() {
  const classes = useStyles();
  const [chronological, setChronological] = useState(false);
  const history = useHistory();

  return (
    <div>
      <div className={classes.infoWrap}>
        <Typography variant="h2">合計 341 件ＯＯＯＯＯＯＯＯＯＯ</Typography>
        <Button
          variant="text"
          onClick={() => setChronological(!chronological)}
          endIcon={chronological ? <ExpandLess /> : <ExpandMore />}
        >
          時間
        </Button>
      </div>

      {applicantList.map((item) => (
        <MedSearchList key={item.id}>
          <div className={classes.contentWrap}>
            <Typography variant="h3">No.</Typography>
            <Typography variant="h5">{item.no}</Typography>
          </div>
          <div className={classes.contentWrap}>
            <Typography variant="h3">医療機関、あるいは法人名</Typography>
            <Typography variant="h5">{item.corporation}</Typography>
          </div>

          <div className={classes.contentWrap}>
            <Typography variant="h3">支払期日</Typography>
            <Typography variant="h5">{item.date}</Typography>
          </div>
          <div className={classes.contentWrap}>
            <Typography variant="h3">請求金額</Typography>
            <Typography variant="h5">{item.amount}</Typography>
          </div>

          <IconButton
            title="View"
            onClick={() => history.push("/employer/management/invoice/review")}
            style={{ minWidth: 50 }}
          >
            <PictureAsPdfOutlinedIcon color="default" />
          </IconButton>
        </MedSearchList>
      ))}

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
