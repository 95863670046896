//曜日を指定
import React, { useState } from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { defaultWorkingDayOfWeek } from "./DataList";
export default function WorkingDayOfWeek() {
  const [workingDayList, setWorkingDayList] = useState(defaultWorkingDayOfWeek);
  const handleDaysChange = (event, changeDay) => {
    const tempList = workingDayList.map((day) =>
      day.id === changeDay.id ? { ...day, checked: event.target.checked } : day
    );
    setWorkingDayList([...tempList]);
  };
  return (
    <>
      <MedSearchPrimaryAccordion
        HeaderIcon={<EventAvailableIcon />}
        HeaderTitle="曜日を指定"
        isExpanded={true}
      >
        <FormControl component="fieldset">
          <FormGroup style={{ flexDirection: "row" }}>
            {workingDayList.map((day) => (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={day.checked}
                    onChange={(e) => handleDaysChange(e, day)}
                    name={day.name}
                  />
                }
                label={day.day}
              />
            ))}
          </FormGroup>
        </FormControl>
      </MedSearchPrimaryAccordion>
    </>
  );
}
