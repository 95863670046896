import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 600,
    width: 380,
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },

  evaluationSection: {
    marginTop: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: 150,
  },
}));

export default function ConfirmForm() {
  const [attendance, setAttendance] = useState(null);
  const [sendBy, setSendBy] = useState(null);
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <FormControl required component="fieldset">
        <FormLabel component="legend">医師出勤確認</FormLabel>
        <RadioGroup
          value={attendance}
          onChange={(e) => setAttendance(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="true" control={<Radio />} label="出勤済" />

          <FormControlLabel value="false" control={<Radio />} label="欠勤" />
        </RadioGroup>
      </FormControl>
      <NumberFormat
        required
        customInput={TextField}
        thousandSeparator={true}
        label="確定給与額"
        variant="standard"
        placeholder="単位は円，半角数字のみ入力可"
        helperText="固定給＋成果給＋残業代を合算した金額"
        InputProps={{
          endAdornment: <InputAdornment position="end">万円</InputAdornment>,
        }}
      />

      <TextField required label="請求書宛名" />
      <FormControl required component="fieldset" style={{ marginTop: 20 }}>
        <FormLabel component="legend">送付方法</FormLabel>
        <RadioGroup
          value={sendBy}
          onChange={(e) => setSendBy(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="email" control={<Radio />} label="E-mail" />

          <FormControlLabel value="mail" control={<Radio />} label="郵送" />
        </RadioGroup>
        {sendBy === "email" ? (
          <TextField label="Emailアドレス" required fullWidth />
        ) : (
          <TextField label="郵送先住所(郵送の場合)" required fullWidth />
        )}
      </FormControl>

      <div className={classes.evaluationSection}>
        <Typography variant="h3">評　価</Typography>
        <Rating
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        <TextField
          label="評価"
          multiline
          maxRows={4}
          variant="outlined"
          fullWidth
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ color: "white" }}
        onClick={() => history.goBack()}
      >
        送　信
      </Button>
    </div>
  );
}
