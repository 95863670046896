//募集要項ー編集
import { makeStyles } from "@material-ui/styles";
import React from "react";
import MedSearchInfoList from "../../../../../components/MedSearchInfoList";
import {
  demoConfirmData1,
  demoConfirmData2,
  demoConfirmData3,
  demoConfirmData4,
  demoConfirmData5,
  demoConfirmData6,
} from "./DemoData";

export default function Step7() {
  return (
    <div>
      <MedSearchInfoList title="医療機関情報" dataList={demoConfirmData1} />
      <MedSearchInfoList title="職務内容" dataList={demoConfirmData2} />
      <MedSearchInfoList title="医師条件" dataList={demoConfirmData3} />
      <MedSearchInfoList
        title="日時・給与・人数指定"
        dataList={demoConfirmData4}
      />
      <MedSearchInfoList title="その他支給" dataList={demoConfirmData5} />
      <MedSearchInfoList title="その他特記事項" dataList={demoConfirmData6} />
    </div>
  );
}
