import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1440,
    height: "fit-content",
    minHeight: "100vh",
    margin: "2% auto",
    marginTop: 100,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftSide: {
    width: "fit-content",
    maxWidth: 420,
    borderRight: "2px solid #eeeeee",
    paddingRight: "1%",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      borderRight: 0,
      padding: "1%",
      maxWidth: "100%",
    },
  },
  rightSide: {
    width: "100%",
    padding: "0 3%",
    height: "auto",
    maxWidth: 1160,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: "1%",
    },
  },
}));

export default function MedSearchLayoutTwoColumn({
  leftSideContent,
  rightSideContent,
}) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>{leftSideContent}</div>
      <div className={classes.rightSide}>{rightSideContent}</div>
    </div>
  );
}
