//成果給
import React, { useState } from "react";
import MedSearchFilterCheckBar from "../common/MedSearchFilterCheckBar";
import StarBorderIcon from "@material-ui/icons/StarBorder";
export default function PerformanceSalary() {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <MedSearchFilterCheckBar
        title="成果給"
        icon={<StarBorderIcon />}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    </>
  );
}
