import React from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { ReactComponent as IconUserFriends } from "../../assets/images/icon_collapse/user-friends.svg";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@material-ui/core";

function MedSearchFilterGender() {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <MedSearchPrimaryAccordion
      HeaderIcon={<IconUserFriends />}
      HeaderTitle="性別"
      isExpanded={true}
    >
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
        style={{ flexDirection: "row", justifyContent: "space-around" }}
      >
        <FormControlLabel
          value="male"
          control={<Radio size="small" />}
          label={<Typography variant="h5">{"男"}</Typography>}
        />
        <FormControlLabel
          value="female"
          control={<Radio size="small" />}
          label={<Typography variant="h5">{"女"}</Typography>}
        />
      </RadioGroup>
    </MedSearchPrimaryAccordion>
  );
}

export default MedSearchFilterGender;
