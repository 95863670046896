import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import ApproveDialog from "./ApproveDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 560,
    margin: "0 auto",
    paddingTop: "2%",
  },
  itemTitle: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 18,
  },
}));

export default function ApproveIndex() {
  const classes = useStyles();
  const [sendBy, setSendBy] = useState("email");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const history = useHistory();
  const [openApproveDialog, setOpenApproveDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Typography variant="h1" color="initial">
        採　用
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={6}>
          <NumberFormat
            required
            customInput={TextField}
            thousandSeparator={true}
            label="想定年収"
            variant="standard"
            placeholder="年収を記載。数字のみ入力可"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="請求書宛名" required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <p className={classes.itemTitle}>医療機関、あるいは法人名　</p>
          <TextField label="担当部署" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={classes.itemTitle}>{"　"}</p>
          <TextField label="担当者名" />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">送付方法 *</FormLabel>
            <RadioGroup
              value={sendBy}
              onChange={(e) => setSendBy(e.target.value)}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="E-mail"
              />

              <FormControlLabel value="mail" control={<Radio />} label="郵送" />
            </RadioGroup>
          </FormControl>
          {sendBy === "email" ? (
            <TextField label="Emailアドレス" required fullWidth />
          ) : (
            <TextField label="郵送先住所" required fullWidth />
          )}
        </Grid>
        <Grid item xs={12}>
          <p className={classes.itemTitle}>開始予定時期</p>
          <DatePicker
            required
            autoOk
            variant="dialog"
            openTo="year"
            views={["year", "month"]}
            label="年/月"
            format="yyyy/MM"
            value={selectedDate}
            onChange={handleDateChange}
            cancelLabel="キャンセル"
            clearLabel="削除"
            okLabel="完了"
            style={{ marginRight: 12, maxWidth: 100 }}
          />
          <TextField
            select
            label="日"
            onChange={(e) => setSelectedDay(e.target.value)}
            style={{ width: 50 }}
          >
            <MenuItem value={0}>{"未定"}</MenuItem>
            {Array.from(Array(31), (e, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            color="primary"
            variant="contained"
            style={{ color: "white", marginRight: 12 }}
            onClick={() => setOpenApproveDialog(true)}
          >
            確　定
          </Button>
          <Button
            color="default"
            variant="contained"
            onClick={() => history.goBack()}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>

      <ApproveDialog open={openApproveDialog} setOpen={setOpenApproveDialog} />
    </div>
  );
}
