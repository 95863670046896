import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import { MedSearchColors } from "../../../../assets/theme";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const Education = () => {
  const [educationList, setEducationList] = useState([
    {
      startDate: null,
      endDate: null,
      school: "",
      degree: "",
      graduation: true,
    },
  ]);

  const addEducation = () => {
    let tempList = educationList;
    tempList.push({
      startDate: null,
      endDate: null,
      school: "",
      degree: "",
      graduation: true,
    });
    setEducationList([...tempList]);
  };
  const removeEducation = () => {
    //剩下最後一個不能刪
    if (educationList.length === 1) return;

    let tempList = educationList;
    tempList.pop();
    setEducationList([...tempList]);
  };

  const changeData = (index, key, val) => {
    let tempList = educationList;
    tempList[index][key] = val;
    console.log(tempList);

    setEducationList([...tempList]);
  };

  return (
    <>
      <Typography variant="h6" style={{ margin: "50px 0 10px 0" }}>
        学歴
      </Typography>
      {educationList.map((edu, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            backgroundColor: MedSearchColors.bgcard,
            padding: 8,
            margin: "6px 0px",
          }}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              label="学校名"
              value={edu.school}
              onChange={(e) => changeData(index, "school", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="取得学位"
              value={edu.degree}
              onChange={(e) => changeData(index, "degree", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="gender1"
                value={edu.graduation}
                onChange={(e) => {
                  changeData(
                    index,
                    "graduation",
                    e.target.value === "true" ? true : false
                  );
                  if (e.target.value === "false")
                    changeData(index, "endDate", null);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="卒業(修了)済"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="在学中"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7}>
            <DatePicker
              required
              clearable
              value={edu.startDate}
              format="yyyy/MM/dd"
              onChange={(val) => changeData(index, "startDate", val)}
              label="入学年月日"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
            />
            {edu.graduation && (
              <DatePicker
                required
                clearable
                value={edu.endDate}
                format="yyyy/MM/dd"
                onChange={(val) => changeData(index, "endDate", val)}
                label="卒業(修了)年月日"
                cancelLabel="削除"
                clearLabel="キャンセル"
                okLabel="完了"
              />
            )}
          </Grid>
        </Grid>
      ))}

      <div
        style={{
          textAlign: "center",
          margin: "2px auto",
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addEducation}
          size="large"
        >
          追加
        </Button>

        <Button
          variant="text"
          color="primary"
          startIcon={<RemoveIcon />}
          onClick={removeEducation}
          size="large"
        >
          削除
        </Button>
      </div>
    </>
  );
};

export default Education;
