export const MedSearchColors = {
  secondary: "#19c897",
  dark04gray: "#999",
  dark03dark: "#595757",
  border: "#dbdcdc",
  dark02darkgray: "#231916",
  zyellow: "#f7b62d",
  bgcard: "#f5f5f5",
  dark05lightgray: "#eee",
  zred: "#c30f23",
  white: "#fefefe",
  shadow: "rgba(0, 0, 0, 0.25)",
  zblue: "#066eb7",
  xp: "#f696c8",
  xy: "#f0be64",
  xb: "#50a0e6",
  dark01: "#000",
  primary: "#14c8c8",
  primary010: "rgba(20, 200, 200, 0.1)",
  primary005: "rgba(20, 200, 200, 0.05)",

  orange: "#ff8c46",
};
