import { Typography, Avatar, TextField } from "@material-ui/core";
import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

import Certificate from "./Certificate";

const fabMenuList = [
  {
    id: 1,
    icon: <SaveOutlinedIcon />,
    title: "登　録",
    link: "/doctor/resume",
  },
  {
    id: 2,
    icon: <ReplyOutlinedIcon />,
    title: "戻　る",
    link: "/doctor/resume",
  },
];

export default function UploadCertifiacte() {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 600,
      }}
    >
      {/* <MedSearchFab menuList={fabMenuList} /> */}
      <Typography variant="h1">証明書UPLOAD</Typography>

      <Certificate />
    </div>
  );
}
