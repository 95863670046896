//新着求人情報
import React from "react";
import {
  Typography,
  makeStyles,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { MedSearchColors } from "../../../assets/theme";
const useStyles = makeStyles((theme) => ({
  listBox: {
    backgroundColor: MedSearchColors.bgcard,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: 20,
  },
  btnWrap: {
    textAlign: "right",
  },
}));

const InfoListItem = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    marginBottom: 8,
  },
}))(ListItem);

const newJobsList = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
];

function NewJobsList() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">新着求人情報</Typography>
      <div className={classes.listBox}>
        <Typography variant="h2">新着求人情報</Typography>
        <List component="nav" aria-label="secondary mailbox folders">
          {newJobsList.map((item) => (
            <InfoListItem button key={item.id}>
              <ListItemText
                primary="【道東×皮膚科クリニック】破格の年収．．"
                secondary="2021-01-14 18:30"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </InfoListItem>
          ))}
        </List>
        <div className={classes.btnWrap}>
          <Button variant="text" color="default" endIcon={<ChevronRightIcon />}>
            その他の求人
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewJobsList;
