import { ThemeProvider, makeStyles } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MedSearchTheme } from "./assets/theme";
import DoctorsListIndex from "./views/DoctorsList/DoctorsListIndex";
import HomePage from "./views/Common/HomePage";
import MedSearchFooter from "./views/Common/MedSearchFooter";
import MedSearchHeader from "./views/Common/MedSearchHeader";
import EmployerLandingPageContent from "./views/EmployerLandingPage/EmployerLandingPageContent";
import ManagementIndex from "./views/Employer/Management/ManagementIndex";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import JobsIndex from "./views/General/Jobs/JobsIndex";
import DoctorIndex from "./views/Doctor/DoctorIndex";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "7e4668ecd997fd8bb72ebe080bdea639T1JERVI6MzQ4NTksRVhQSVJZPTE2NzE5NDUyMDEwMDAsS0VZVkVSU0lPTj0x"
);

const localeMap = {
  ja: jaLocale,
};

const useStyles = makeStyles((theme) => ({
  EmployerLandingPageMainContent: {
    minHeight: "calc(100vh - 150px)",
    margin: "80px auto",
    width: "100%",
    maxWidth: 1440,
  },
  contentRoot: {
    width: "100%",
    maxWidth: 1440,
    minHeight: "calc(100vh - 260px)",
    margin: "0px auto",
    marginTop: 80,
    marginBottom: 30,
  },
}));

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={MedSearchTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["ja"]}>
        <Router>
          <Switch>
            <Route path="/employer">
              <MedSearchHeader employerMode={true} />
              <div className={classes.EmployerLandingPageMainContent}>
                <Switch>
                  <Route
                    path="/employer/management"
                    component={ManagementIndex}
                  />
                  <Route
                    path="/employer/doctors"
                    component={DoctorsListIndex}
                  />
                  <Route path="/employer">
                    <EmployerLandingPageContent />
                  </Route>
                </Switch>
              </div>
              <MedSearchFooter employerMode={true} />
            </Route>
            <Route path="/">
              <MedSearchHeader />
              <div className={classes.contentRoot}>
                <Switch>
                  <Route path="/jobs/:type">
                    <JobsIndex />
                  </Route>
                  <Route path="/" component={HomePage} exact />
                  <Route path="/doctor">
                    <DoctorIndex />
                  </Route>
                </Switch>
              </div>
              <MedSearchFooter />
            </Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
