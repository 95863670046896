import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useScrollYPosition } from "../../hooks/useScrollYPosition";

import HowToUse from "./HowToUse";
import LinkCards from "./LinkCards";
import QandA from "./QandA";
import ServiceFeatures from "./ServiceFeatures";
import Aos from "aos";
import "aos/dist/aos.css";
import CommonBanner from "../Common/CommonBanner";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { MedSearchColors } from "../../assets/theme";

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 1440,
  },
  linkSection: {
    margin: "1% auto",
  },
  section: {
    padding: "1% 5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    // width: "100%",
    "&:nth-child(1)": {
      // minHeight: "90vh",
      // marginTop: -20,
    },
  },
}));

function EmployerLandingPageContent() {
  const classes = useStyle();
  const scrollYPosition = useScrollYPosition();

  // 滾動頁面時連動header的menu
  const [headerMenuIndex, setHeaderMenuIndex] = useState(0);

  //サービスの特徴 區塊 ref
  const serviceFeaturesRef = useRef(null);
  //ご利用方法  區塊 ref
  const howToUseRef = useRef(null);
  //よくある質問（Q&A） 區塊 ref
  const qandaRef = useRef(null);

  const fabRef = useRef();

  // 滾動頁面時連動header的menu
  useEffect(() => {
    //頁面滾動到「サービスの特徴」區間
    const serviceFeaturesEl =
      serviceFeaturesRef.current.getBoundingClientRect();
    const serviceFeaturesScope = serviceFeaturesEl.y + serviceFeaturesEl.height;
    if (
      serviceFeaturesScope > 0 &&
      serviceFeaturesScope < serviceFeaturesEl.height
    )
      setHeaderMenuIndex(0);

    //頁面滾動到「ご利用方法」區間
    const howToUseEl = howToUseRef.current.getBoundingClientRect();
    const howToUseScope = howToUseEl.y + howToUseEl.height;
    if (howToUseScope > 0 && howToUseScope < howToUseEl.height)
      setHeaderMenuIndex(1);

    //頁面滾動到「よくある質問（Q&A）」區間
    const qandaEl = qandaRef.current.getBoundingClientRect();
    const qandaScope = qandaEl.y + qandaEl.height;
    if (qandaScope > 0 && qandaScope < qandaEl.height) setHeaderMenuIndex(4);
  }, [scrollYPosition]);

  useEffect(() => {
    //進入頁面時，回到頂部
    window.scrollTo(0, 0);

    //初始化 AOS
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className={classes.root}>
      <CommonBanner isEmplpoyer={true} />
      <Fab
        ref={fabRef}
        variant="extended"
        className={classes.floatBtn}
        style={{
          position: "fixed",
          bottom: "4%",
          right: "6%",
          zIndex: 200,
          backgroundColor: MedSearchColors.primary,
          color: "white",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <NavigationIcon />
        TOP
      </Fab>

      <section id="linksection" className={classes.linkSection}>
        <LinkCards />
      </section>
      <section
        id="servicefeatures"
        className={classes.section}
        ref={serviceFeaturesRef}
        // data-aos="fade-down"
      >
        <ServiceFeatures />
      </section>
      <section
        id="howtouse"
        className={classes.section}
        ref={howToUseRef}
        // data-aos="fade-down"
      >
        <HowToUse />
      </section>
      <section
        className={classes.section}
        ref={qandaRef}
        id="qanda"
        //  data-aos="fade-down"
      >
        <span></span>
        <QandA />
      </section>
    </div>
  );
}

export default EmployerLandingPageContent;
