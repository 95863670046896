import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Avatar,
} from "@material-ui/core";
import React from "react";
import { MedSearchColors } from "../../assets/theme";
import MaterialTable from "material-table";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MedSearchFab from "../../components/common/MedSearchFab";
import ChatIcon from "@material-ui/icons/Chat";
import MedSearchDoctorProfile2 from "../../components/MedSearchDoctorProfile2";

const useStyles = makeStyles((theme) => ({
  profile: {
    marginTop: 20,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: "16px 14px",
    margin: "30px auto",
    width: "100%",
  },
}));

const fabMenuList = [
  {
    id: 1,
    icon: <ChatIcon />,
    title: "スカウト開始 (チャット)",
    link: "",
  },
  {
    id: 2,
    icon: <FavoriteIcon />,
    title: "お気に入り",
    link: "",
  },
];

function DoctorDetail() {
  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">スカウト対象医師の詳細</Typography>
      <Avatar
        style={{
          backgroundColor: "#a9dcf3",
          width: 100,
          height: 100,
          marginTop: "3%",
          marginBottom: "5%",
        }}
      >
        <AccountCircleIcon style={{ width: 100, height: 100 }} />
      </Avatar>
      <MedSearchDoctorProfile2 />
    </div>
  );
}

export default DoctorDetail;
