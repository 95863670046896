import { makeStyles } from "@material-ui/core";
import React from "react";
import { MedSearchColors } from "../../assets/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    minWidth: 88,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: 2,
    padding: "4px 12px",
    color: "white",
  },
}));

export default function MedSearchStatusBox({ content, colorIndex }) {
  const classes = useStyles();
  const bgColorList = [
    MedSearchColors.secondary,
    MedSearchColors.zblue,
    MedSearchColors.primary,
    MedSearchColors.orange,
    // MedSearchColors.zred,
    MedSearchColors.dark03dark,
  ];
  return (
    <span
      className={classes.root}
      style={{ backgroundColor: bgColorList[colorIndex] }}
    >
      {content}
    </span>
  );
}
