export const skillList = [
  { id: 1, title: "電子カルテ", category: "手技" },
  { id: 2, title: "縫合", category: "手技" },
  { id: 3, title: "挿管", category: "手技" },
  { id: 4, title: "胃ろう交換", category: "手技" },
  { id: 5, title: "バルーン交換", category: "手技" },
  { id: 6, title: "気切チューブ交換", category: "手技" },
  { id: 7, title: "CV管理", category: "手技" },
  { id: 8, title: "胃カメラ", category: "手技" },
  { id: 9, title: "大腸ファイバー", category: "手技" },
  { id: 10, title: "腹部エコー", category: "手技" },
  { id: 11, title: "心エコー", category: "手技" },
  { id: 12, title: "胸腔穿刺", category: "手技" },
  { id: 13, title: "その他", category: "手技" },
];
