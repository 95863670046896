import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useJapanCity from "../../hooks/useJapanCity";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));
function MedSearchAddressSetting() {
  const classes = useStyles();

  const [japanZip, setJapanZip] = useState("5400002");
  const [prefixAddress, errorMessage] = useJapanCity(japanZip);
  const [fullAddress, setFullAddress] = useState("");
  useEffect(() => {
    setFullAddress(prefixAddress);
  }, [prefixAddress]);
  const handleZipCodeChange = (val) => {
    const re = /^[0-9\b]+$/;
    if (val === "" || re.test(val)) {
      setJapanZip(val);
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        required
        label="住所"
        defaultValue="540-0002"
        value={japanZip}
        className={classes.editTextField}
        style={{ marginRight: 12, maxWidth: 120, width: "100%" }}
        InputProps={{
          startAdornment: <InputAdornment position="start">〒</InputAdornment>,
        }}
        onChange={(e) => handleZipCodeChange(e.currentTarget.value)}
      />
      <TextField
        required
        label=" "
        value={fullAddress}
        style={{ width: "100%" }}
        onChange={(e) => setFullAddress(e.currentTarget.value)}
      />
    </div>
  );
}

export default MedSearchAddressSetting;
