import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useHistory } from "react-router";
import { RecuitmentContext } from "./RecuitmentContext";
import { MedSearchColors } from "../../../../assets/theme";
import MedSearchDialog from "../../../../components/common/MedSearchDialog";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
  TypesOfEmployment,
} from "../../../../assets/constant/TypesOfEmployment";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      backgroundColor: MedSearchColors.secondary,
      color: "white",
    },
  },
}));

//建立新的 募集 時，可選：
export const TypesOfEmploymentList = [
  { type: SPOT }, // スポット
  { type: FULL_TIME }, // 常勤
  { type: PART_TIME }, // 非常勤
];

function RecruitmentCreateNewFAB() {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const { setCreateType } = useContext(RecuitmentContext);

  const history = useHistory();

  const handleSelected = (type) => {
    setCreateType(type);
    history.push("/employer/management/recruitment/create");
  };
  const fabMenuList = [
    {
      id: 1,
      icon: <PlaylistAddIcon />,
      title: "新規募集要項作成",
      link: "",
      click: () => setOpenDialog(true),
    },
  ];

  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      <MedSearchDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="タイプを選択"
        content={
          <List style={{ width: 280 }}>
            {TypesOfEmploymentList.map((item) => (
              <>
                <ListItem
                  key={item.type}
                  autoFocus
                  button
                  onClick={() => handleSelected(item.type)}
                  className={classes.listItem}
                >
                  <ListItemText
                    primary={TypesOfEmployment[item.type]}
                    style={{ textAlign: "center" }}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        }
      ></MedSearchDialog>
    </div>
  );
}

export default RecruitmentCreateNewFAB;
