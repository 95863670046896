import React, { useState, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { ReactComponent as IconJapanMap } from "../../assets/images/japan_map/icon_japan_map.svg";

import {
  makeStyles,
  withStyles,
  IconButton,
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { ReactComponent as BannerNextBtn } from "../../assets/images/btn_arrow_banner_right.svg";
import { ReactComponent as BannerPrevBtn } from "../../assets/images/btn_arrow_banner.svg";

import { MedSearchColors } from "../../assets/theme";
import MedSearchJapanCityDialog from "../../components/MedSearchJapanCityDialog";
import MedSearchMedicalDepartmentDialog from "../../components/MedSearchMedicalDepartmentDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1440,
    margin: "0px auto",
  },
  bannerWrap: {
    position: "relative",
    padding: 0,
  },
  bannerNextBtn: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "3%",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  bannerPrevBtn: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "3%",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchBtn: {
    color: "white",
    width: "100%",
    letterSpacing: 2,
    fontWeight: 500,
    fontSize: 18,
    padding: 5,
  },
}));

const SearchBarGrid = withStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 950,
    backgroundColor: MedSearchColors.bgcard,
    margin: "0 auto",
    marginTop: "-3.5%",
    padding: 4,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      width: "97%",
    },
  },
}))(Grid);

export default function CommonBanner({ isEmplpoyer = false }) {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const [isJapanCityDialogOpen, setIsJapanCityDialogOpen] = useState(false);
  const [isMedicalDepartmentDialogOpen, setIsMedicalDepartmentDialogOpen] =
    useState(false);
  //已選的城市清單
  const [selectedCityList, setSelectedCityList] = useState([]);
  //已選的科別清單
  const [selectedDeparmentList, setSelectedDeparmentList] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className={classes.root}>
      <div className={classes.bannerWrap}>
        <Slider {...settings} ref={sliderRef} className={classes.bannerSlider}>
          {isEmplpoyer ? (
            <img src="assets/images/banner_2.jpg" alt="" />
          ) : (
            <img src="assets/images/banner_1.jpg" alt="" />
          )}
        </Slider>
        <IconButton
          className={classes.bannerNextBtn}
          onClick={() => sliderRef.current.slickNext()}
        >
          <BannerNextBtn />
        </IconButton>
        <IconButton
          className={classes.bannerPrevBtn}
          onClick={() => sliderRef.current.slickPrev()}
        >
          <BannerPrevBtn />
        </IconButton>
      </div>
      {!isEmplpoyer && (
        <SearchBarGrid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12} sm={12} md={2}>
            <Typography
              variant="h3"
              style={{ fontSize: 20, textAlign: "center" }}
            >
              簡易求人検索
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              label="地域"
              variant="outlined"
              size="small"
              color="secondary"
              value={selectedCityList}
              style={{ backgroundColor: "white", width: "100%" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginLeft: -8, marginTop: 3 }}
                  >
                    <IconJapanMap
                      style={{
                        width: 24,
                        height: "auto",
                        fill: MedSearchColors.secondary,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              onClick={() => setIsJapanCityDialogOpen(true)}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              label="診療科目"
              variant="outlined"
              size="small"
              color="secondary"
              value={selectedDeparmentList}
              style={{ backgroundColor: "white", width: "100%" }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment style={{ marginLeft: -8 }}>
                    <LocalHospitalIcon color="secondary" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onClick={() => setIsMedicalDepartmentDialogOpen(true)}
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField
              label="キーワード"
              variant="outlined"
              size="small"
              color="secondary"
              style={{ width: "100% ", backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={4} md={2} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchBtn}
            >
              求人検索
            </Button>
          </Grid>
        </SearchBarGrid>
      )}

      <MedSearchJapanCityDialog
        isJapanCityDialogOpen={isJapanCityDialogOpen}
        setIsJapanCityDialogOpen={setIsJapanCityDialogOpen}
        selectedCityList={selectedCityList}
        setSelectedCityList={setSelectedCityList}
      />
      <MedSearchMedicalDepartmentDialog
        isMedicalDepartmentDialogOpen={isMedicalDepartmentDialogOpen}
        setIsMedicalDepartmentDialogOpen={setIsMedicalDepartmentDialogOpen}
        setSelectedDeparmentList={setSelectedDeparmentList}
      />
    </div>
  );
}
