//応募医師管理
import { Tab, Tabs, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ApplicationList from "./ApplicantList";

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: "3% auto",
    maxWidth: "fit-content",
    width: "100%",
  },
}));

export default function ApplicantIndex() {
  const classes = useStyles();
  const [tabsIndex, setTabsIndex] = useState(0);
  const handleChangeTabsIndex = (event, newValue) => {
    setTabsIndex(newValue);
  };
  const statusList = [
    { id: 1, title: "要回答", status: 0 },
    { id: 3, title: "最終確認待ち", status: 2 },
    { id: 4, title: "採用決定", status: 3 },
    { id: 5, title: "全て", status: 4 },
  ];
  return (
    <div>
      <Typography variant="h1">応募医師管理</Typography>
      <Tabs
        value={tabsIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTabsIndex}
        variant="scrollable"
        className={classes.tabs}
      >
        {statusList.map((item) => (
          <Tab key={item.id} label={item.title} />
        ))}
      </Tabs>
      <ApplicationList />
    </div>
  );
}
