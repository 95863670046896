//募集要項ー編集
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
}));

export default function Step2() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: "3%" }}>
        必須入力＊
      </Typography>
      <TextField
        required
        label="職務の種別"
        variant="standard"
        placeholder="例：○科外来、下部内視鏡検査、病棟管理、検診・・など"
        className={classes.textFiled}
      />

      <TextField
        multiline
        minRows={3}
        required
        label="職務の詳細"
        variant="standard"
        placeholder="業務内容や業務量などを出来る限り具体的に記載。
        例：外来なら受付時間、診察患者数、患者属性など。
        日当直なら担当病床数、時間外診療の内容と件数など。
        "
        className={classes.textFiled}
      />
    </div>
  );
}
