//高額時給
import React, { useState } from "react";
import MedSearchFilterCheckBar from "../common/MedSearchFilterCheckBar";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

export default function HighHourlyWage() {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <MedSearchFilterCheckBar
        title="高額時給"
        icon={<AttachMoneyIcon />}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    </>
  );
}
