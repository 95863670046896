//勤務先情報
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { DropzoneDialog } from "material-ui-dropzone";
import MedSearchAddressSetting from "../../../../../components/common/MedSearchAddressSetting";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  uploadImgWrap: {
    maxWidth: 600,
    marginBottom: "5%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("730")]: {
      flexDirection: "column",
      height: 510,
    },
    "& figure": {
      position: "relative",
      // width: "100%",
      width: 194,
      height: 120,
      [theme.breakpoints.down("730")]: {
        minWidth: 280,
        minHeight: 160,
      },
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  removeImgBtn: {
    position: "absolute",
    top: "3%",
    right: "2%",
    width: 38,
    height: 38,
  },
  uploadImgBtn: {
    width: 194,
    height: 120,
    [theme.breakpoints.down("730")]: {
      minWidth: 280,
      minHeight: 160,
    },
  },
}));

function Step1() {
  const classes = useStyles();
  const [uploadImg, setUploadImg] = useState(false);
  let { type } = useParams();
  // type => 1 為 スポット
  // type => 2 為 常勤
  // type => 3 為 非常勤

  return (
    <div>
      <div className={classes.uploadImgWrap}>
        <figure>
          <img src="/assets/images/Demo/hospital-1.png" alt="" />

          <IconButton
            style={{ background: "white" }}
            className={classes.removeImgBtn}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </figure>
        <Button
          variant="contained"
          onClick={() => setUploadImg(true)}
          className={classes.uploadImgBtn}
        >
          <CameraAltIcon />
        </Button>
        <Button
          variant="contained"
          onClick={() => setUploadImg(true)}
          className={classes.uploadImgBtn}
        >
          <CameraAltIcon />
        </Button>

        <DropzoneDialog
          dialogTitle="Upload Image"
          open={uploadImg}
          onSave={(e) => setUploadImg(e)}
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setUploadImg(false)}
        />
      </div>
      <Typography variant="h6" style={{ marginBottom: "3%" }}>
        必須入力＊
      </Typography>
      <TextField
        required
        label="勤務先名"
        variant="standard"
        defaultValue="必須"
        style={{ width: "100%", marginBottom: "3%" }}
      />
      <MedSearchAddressSetting />
      {type === ":1" ? (
        <TextField
          required
          label="緊急連絡先"
          variant="standard"
          defaultValue="必須"
          style={{ width: "100%", marginBottom: "3%" }}
        />
      ) : null}

      <Typography variant="h6" style={{ margin: "3% 0px" }}>
        任意入力
      </Typography>
      <TextField
        label="アクセス"
        variant="standard"
        placeholder={
          type === ":1"
            ? "例：最寄り駅、駅からの所要時間など"
            : "例：最寄り駅と路線名、駅からの所要時間や距離など"
        }
        style={{ width: "100%", marginBottom: "3%" }}
      />
      {type === ":1" ? (
        <TextField
          label="集合場所"
          variant="standard"
          placeholder="例：初回勤務時の連絡窓口など"
          style={{ width: "100%", marginBottom: "3%" }}
        />
      ) : null}
    </div>
  );
}

export default Step1;
