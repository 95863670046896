import React, { useState, useEffect } from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import MedSearchSecondaryAccordion from "../common/MedSearchSecondaryAccordion";
import { ReactComponent as IconIncome } from "../../assets/images/icon_collapse/income.svg";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { MedSearchMedicalDepartmentList } from "../../assets/data/MedSearchMedicalDepartmentList";
import _ from "lodash";

function MedSearchFilterDepartment({ title }) {
  const [medSearchDepartmentList, setMedSearchDepartmentList] = useState({});

  useEffect(() => {
    const tempList = MedSearchMedicalDepartmentList.map((department) => ({
      ...department,
      checked: false,
    }));
    setMedSearchDepartmentList(_.groupBy(tempList, "category"));
  }, []);

  return (
    <MedSearchPrimaryAccordion
      HeaderIcon={<IconIncome />}
      HeaderTitle={title}
      isExpanded={false}
    >
      {Object.entries(medSearchDepartmentList).map(
        ([category, departmentList]) => (
          <MedSearchSecondaryAccordion
            key={category}
            HeaderTitle={category}
            isExpanded={true}
          >
            {departmentList.map((department) => (
              <FormControlLabel
                key={department.department_id}
                control={<Checkbox size="small" />}
                label={
                  <Typography variant="h5" style={{ marginLeft: -4 }}>
                    {department.department}
                  </Typography>
                }
                style={{ height: 34 }}
              />
            ))}
          </MedSearchSecondaryAccordion>
        )
      )}
    </MedSearchPrimaryAccordion>
  );
}

export default MedSearchFilterDepartment;
