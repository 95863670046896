import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import _ from "lodash";

import { MedSearchMedicalDepartmentList } from "../assets/data/MedSearchMedicalDepartmentList";
import { MedSearchColors } from "../assets/theme";
import { withStyles } from "@material-ui/styles";

const RegionCheckbox = withStyles({
  root: { color: "white", padding: 5 },
  colorPrimary: {
    color: "white",
    "&.Mui-checked": {
      color: "white",
    },
  },
})(Checkbox);

export default function MedSearchMedicalDepartmentDialog(props) {
  const {
    isMedicalDepartmentDialogOpen,
    setIsMedicalDepartmentDialogOpen,
    setSelectedDeparmentList,
  } = props;

  //記錄每一個科系的checkbox選擇狀態
  const categoryObjects = {};
  const [isInternalChecked, setIsInternalChecked] = useState(false); //內科系 的checkbox是否點選
  const [isSurgeryChecked, setIsSurgeryChecked] = useState(false); //外科系 的checkbox是否點選
  const [isOphthalmologyChecked, setIsOphthalmologyChecked] = useState(false); //眼科・皮膚科・耳鼻科 的checkbox是否點選
  const [isPediatricsChecked, setIsPediatricsChecked] = useState(false); //小児科・産婦人科 的checkbox是否點選
  const [isPsychiatryChecked, setIsPsychiatryChecked] = useState(false); //精神科・心療内科 的checkbox是否點選
  const [isOthersChecked, setIsOthersChecked] = useState(false); //その他 的checkbox是否點選
  categoryObjects["内科系"] = isInternalChecked;
  categoryObjects["外科系"] = isSurgeryChecked;
  categoryObjects["眼科・皮膚科・耳鼻科"] = isOphthalmologyChecked;
  categoryObjects["小児科・産婦人科"] = isPediatricsChecked;
  categoryObjects["精神科・心療内科"] = isPsychiatryChecked;
  categoryObjects["その他"] = isOthersChecked;

  //從MedSearchMedicalDepartmentList取得資料並於useEffect中加入checked欄位以進行選取判斷
  const [medicalDepartmentList, setMedicalDepartmentList] = useState({});

  //從MedSearchMedicalDepartmentList取得資料並於useEffect中加入checked欄位以進行選取判斷
  useEffect(() => {
    const tempList = MedSearchMedicalDepartmentList.map((department) => ({
      ...department,
      checked: false,
    }));

    setMedicalDepartmentList(_.groupBy(tempList, "category"));
  }, []);

  //當使用者點選或取消checkbox時更新已選清單
  useEffect(() => {
    const tempSelectedList = [];
    Object.entries(medicalDepartmentList).map(([cateory, departmentList]) => {
      departmentList.map((department) => {
        if (department.checked) tempSelectedList.push(department.department);
      });
    });
    setSelectedDeparmentList(tempSelectedList);
  }, [medicalDepartmentList]);

  //選擇某科別時，將該科別的 所有子項目 設定為選取
  const handleCheckedCategory = (category, e) => {
    const tempMedicalDepartmentList = medicalDepartmentList;
    tempMedicalDepartmentList[category] = tempMedicalDepartmentList[
      category
    ].map((department) => ({ ...department, checked: e.target.checked }));
    setMedicalDepartmentList({
      ...tempMedicalDepartmentList,
    });

    switch (category) {
      case "内科系":
        setIsInternalChecked(e.target.checked);
        break;
      case "外科系":
        setIsSurgeryChecked(e.target.checked);
        break;
      case "眼科・皮膚科・耳鼻科":
        setIsOphthalmologyChecked(e.target.checked);
        break;
      case "小児科・産婦人科":
        setIsPediatricsChecked(e.target.checked);
        break;
      case "精神科・心療内科":
        setIsPsychiatryChecked(e.target.checked);
        break;
      case "その他":
        setIsOthersChecked(e.target.checked);
        break;

      default:
        break;
    }
  };

  //記錄 選擇 單一科
  const handleCheckedDepartment = (category, departmentId, e) => {
    const tempMedicalDepartmentList = medicalDepartmentList;

    tempMedicalDepartmentList[category] = tempMedicalDepartmentList[
      category
    ].map((department) => ({
      ...department,
      checked:
        department.department_id.toString() === departmentId.toString()
          ? e.target.checked
          : department.checked,
    }));

    setMedicalDepartmentList({ ...tempMedicalDepartmentList });
  };

  //清除所有已選項目
  const clearAllChecked = () => {
    //取消所有科目的選取：
    //内科系
    setIsInternalChecked(false);
    //外科系
    setIsSurgeryChecked(false);
    //眼科・皮膚科・耳鼻科
    setIsOphthalmologyChecked(false);
    //小児科・産婦人科
    setIsPediatricsChecked(false);
    //精神科・心療内科
    setIsPsychiatryChecked(false);
    //その他
    setIsOthersChecked(false);

    //取消所有項目的選取：
    const tempObjs = {};
    Object.entries(medicalDepartmentList).map(([category, departmentList]) => {
      let tempList = departmentList.map((department) => ({
        ...department,
        checked: false,
      }));
      tempObjs[category] = tempList;
    });
    setMedicalDepartmentList({ ...tempObjs });
  };

  return (
    <div>
      <Dialog
        open={isMedicalDepartmentDialogOpen}
        onClose={() =>
          setIsMedicalDepartmentDialogOpen(false) & clearAllChecked()
        }
        maxWidth="lg"
      >
        <DialogTitle
          disableTypography={true}
          style={{
            fontWeight: 800,
            fontSize: 18,
            color: MedSearchColors.dark03dark,
          }}
        >
          {"科目を選ぶ"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="center"
          >
            {/* catetory( 如 內科系)   departmentList (如 内科全般、消化器、呼吸器…) */}
            {Object.entries(medicalDepartmentList).map(
              ([category, departmentList]) => (
                <Grid item xs={12} sm={6} key={category}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel
                      style={{
                        borderLeft: `8px solid ${MedSearchColors.primary}`,
                        backgroundColor: MedSearchColors.xb,
                        color: "white",
                      }}
                    >
                      <FormControlLabel
                        key={category}
                        control={
                          <RegionCheckbox
                            size="small"
                            color="primary"
                            checked={categoryObjects[category]}
                            onChange={(e) => handleCheckedCategory(category, e)}
                          />
                        }
                        label={category}
                        labelPlacement="start"
                      />
                    </FormLabel>

                    <FormGroup style={{ flexDirection: "row" }}>
                      {departmentList.map((department) => (
                        <FormControlLabel
                          key={department.department_id}
                          control={
                            <Checkbox
                              disabled={categoryObjects[category]}
                              size="small"
                              checked={department.checked}
                              onChange={(e) =>
                                handleCheckedDepartment(
                                  category,
                                  department.department_id,
                                  e
                                )
                              }
                            />
                          }
                          label={
                            <span style={{ marginRight: 12, fontSize: 14 }}>
                              {department.department}
                            </span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => setIsMedicalDepartmentDialogOpen(false)}
            color="primary"
          >
            設定
          </Button>
          <Button
            style={{ color: MedSearchColors.zred }}
            onClick={clearAllChecked}
          >
            クリア
          </Button>
          <Button
            onClick={() =>
              setIsMedicalDepartmentDialogOpen(false) & clearAllChecked()
            }
            color="default"
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
