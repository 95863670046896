import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    padding: 0,
  },

  slider: {
    maxWidth: 600,
    width: "100%",
    height: "auto",
  },
  sliderImg: {
    width: "100%",
  },
  dotsStyle: {
    listStyle: "none",
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 120,
    maxWidth: 600,
    width: "100%",
    margin: "0 auto",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  imgBtn: {
    width: "100%",
    "& img": {
      width: "100%",
      padding: "1%",
    },
  },
}));

function EmployerPhotoGallery() {
  const classes = useStyles();
  const settings = {
    customPaging: (i) => (
      <a key={i} href="" className={classes.imgBtn}>
        <img src={`/assets/images/Demo/hospital-${i + 1}.png`} alt="" />
      </a>
    ),

    dots: true,
    // dotsClass: "slick-dots slick-thumb",
    dotsClass: `${classes.dotsStyle}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className={classes.root}>
      <Slider {...settings} className={classes.slider}>
        {[1, 2, 3].map((item) => (
          //   <div key={item}>
          <img
            key={item}
            src={`/assets/images/Demo/hospital-${item}.png`}
            alt=""
            className={classes.sliderImg}
          />
          //   </div>
        ))}
      </Slider>
    </div>
  );
}

export default EmployerPhotoGallery;
