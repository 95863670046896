//募集要項の管理
import React, { useState, useContext } from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import MedSearchList from "../../../../components/common/MedSearchList";
import { MedSearchColors } from "../../../../assets/theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import { MedSearchPagination } from "../../../../components/common/MedSearchPagination";
import {
  FULL_TIME,
  PART_TIME,
  SCOUT,
  SPOT,
  TypesOfEmployment,
} from "../../../../assets/constant/TypesOfEmployment";
import RecruitmentCreateNewFAB from "./RecruitmentCreateNewFAB";

const useStyles = makeStyles((theme) => ({
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 500,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  statusDraft: {
    width: "fit-content",
    minWidth: 88,
    fontSize: 16,
    padding: "4px 12px",
    backgroundColor: MedSearchColors.orange,
    color: "white",
  },
  statusEditing: {
    minWidth: 72,
    fontSize: 16,
    padding: "4px 12px",
    backgroundColor: MedSearchColors.dark03dark,
    color: "white",
  },
  statusRecruitment: {
    width: "fit-content",
    minWidth: 72,
    fontSize: 16,
    padding: "4px 12px",
    backgroundColor: MedSearchColors.primary,
    color: "white",
  },
  statusStopping: {
    minWidth: 72,
    fontSize: 16,
    padding: "4px 12px",
    backgroundColor: MedSearchColors.dark04gray,
    color: "white",
  },
  statusCompletion: {
    minWidth: 72,
    fontSize: 16,
    padding: "4px 12px",
    backgroundColor: MedSearchColors.dark05lightgray,
    color: MedSearchColors.dark04gray,
  },
  contentWrap: {
    width: "100%",
    marginLeft: 16,
    marginRight: 26,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "10px 6px",
    },
  },
  btnWrap: {
    minWidth: 160,
    textAlign: "right",
  },
  dialog: {},
  dialogTitle: {
    backgroundColor: MedSearchColors.primary,
    fontSize: 22,
    color: "white",
    width: 300,
    textAlign: "center",
  },
  listItem: {
    "&:hover": {
      backgroundColor: MedSearchColors.secondary,
      color: "white",
    },
  },
}));

function RecruitmentIndex() {
  const classes = useStyles();

  return (
    <div>
      <RecruitmentCreateNewFAB />

      <Typography variant="h1">募集要項の管理</Typography>
      <div className={classes.infoWrap}>
        <Typography variant="h2">一時保存 1</Typography>
        <Typography variant="h2">編集済 1</Typography>
        <Typography variant="h2">募集中 1</Typography>
        <Typography variant="h2">停止中 1</Typography>
        <Typography variant="h2">完了 1</Typography>
      </div>
      <MedSearchList>
        <span className={classes.statusDraft}>一時保存</span>

        <div className={classes.contentWrap}>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              1W-4385-12459 / 2021-01-14 18:30
            </Typography>
            <Typography variant="h3">
              【道東×皮膚科クリニック】破格の年収
            </Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[FULL_TIME]}
          </Typography>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              所在地
            </Typography>
            <Typography variant="h3">北海道</Typography>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <IconButton title="募集開始">
            <PublishIcon color="primary" />
          </IconButton>
          <IconButton title="編集">
            <EditIcon />
          </IconButton>
          <IconButton title="削除">
            <DeleteOutlineIcon />
          </IconButton>
          {/* <IconButton title="募集停止">
            <DeleteOutlineIcon />
          </IconButton> */}
        </div>
      </MedSearchList>
      <MedSearchList>
        <span className={classes.statusEditing}>編集済</span>

        <div className={classes.contentWrap}>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              1W-4385-12459 / 2021-01-14 18:30
            </Typography>
            <Typography variant="h3">
              【道東×皮膚科クリニック】破格の年収
            </Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[PART_TIME]}
          </Typography>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              所在地
            </Typography>
            <Typography variant="h3">北海道</Typography>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <IconButton title="募集開始">
            <PublishIcon color="primary" />
          </IconButton>
          <IconButton title="編集">
            <EditIcon />
          </IconButton>
          <IconButton title="削除">
            <DeleteOutlineIcon />
          </IconButton>
          {/* <IconButton title="募集停止">
            <DeleteOutlineIcon />
          </IconButton> */}
        </div>
      </MedSearchList>
      <MedSearchList>
        <span className={classes.statusRecruitment}>募集中</span>
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              1W-4385-12459 / 2021-01-14 18:30
            </Typography>
            <Typography variant="h3">
              【道東×皮膚科クリニック】破格の年収
            </Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[SPOT]}
          </Typography>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              所在地
            </Typography>
            <Typography variant="h3">北海道</Typography>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <IconButton title="募集停止">
            <GetAppIcon />
          </IconButton>
        </div>
      </MedSearchList>
      <MedSearchList>
        <span className={classes.statusStopping}>停止中</span>
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              1W-4385-12459 / 2021-01-14 18:30
            </Typography>
            <Typography variant="h3">
              【道東×皮膚科クリニック】破格の年収
            </Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[FULL_TIME]}
          </Typography>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              所在地
            </Typography>
            <Typography variant="h3">北海道</Typography>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <IconButton title="募集開始">
            <PublishIcon color="primary" />
          </IconButton>
          <IconButton title="編集">
            <EditIcon />
          </IconButton>
          <IconButton title="削除">
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      </MedSearchList>
      <MedSearchList>
        <span className={classes.statusCompletion}>完　了</span>
        <div className={classes.contentWrap}>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              1W-4385-12459 / 2021-01-14 18:30
            </Typography>
            <Typography variant="h3">
              【道東×皮膚科クリニック】破格の年収
            </Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[SCOUT]}
          </Typography>
          <div>
            <Typography variant="h4" style={{ margin: "6px 0" }}>
              所在地
            </Typography>
            <Typography variant="h3">北海道</Typography>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <IconButton title="募集要項を再利用 ">
            <FileCopyOutlinedIcon />
          </IconButton>
          <IconButton title="削除">
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      </MedSearchList>
      <MedSearchPagination count={7} color="default" />
    </div>
  );
}

export default RecruitmentIndex;
