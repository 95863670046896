import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Typography,
  Avatar,
  Button,
  Badge,
} from "@material-ui/core";
import MedSearchList from "../../../../components/common/MedSearchList";
import { MedSearchColors } from "../../../../assets/theme";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BlockIcon from "@material-ui/icons/Block";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { applicantList } from "./DemoList";
import MedSearchStatusBox from "../../../../components/common/MedSearchStatusBox";
import { MedSearchPagination } from "../../../../components/common/MedSearchPagination";

import {
  CANCELED,
  CONSIDERATION,
  NEGOTIATION,
  RECRUITMENT,
  WAITING_CONFIRMATION,
} from "./ApplicantStatusConstant";
import {
  SPOT,
  TypesOfEmployment,
} from "../../../../assets/constant/TypesOfEmployment";

const useStyles = makeStyles((theme) => ({
  applicant: {
    minWidth: "fit-content",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    margin: "0 2%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "3% 0",
    },
  },
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

export default function ApplicantList() {
  const classes = useStyles();
  const [chronological, setChronological] = useState(false);
  const history = useHistory();
  //在指定狀態下，「採用」btn 設為disable
  const approvedBtnDisableList = [RECRUITMENT, CANCELED, WAITING_CONFIRMATION];
  //在指定狀態下，「不採用」btn 設為disable
  const rejectedBtnDisableList = [RECRUITMENT, CANCELED, WAITING_CONFIRMATION];

  //顯示 剩餘時間
  const visibleBadegList = [CONSIDERATION, NEGOTIATION];

  return (
    <div>
      <div className={classes.infoWrap}>
        <Typography variant="h2">合計 341 件ＯＯＯＯＯＯＯＯＯＯ</Typography>
        <Button
          variant="text"
          onClick={() => setChronological(!chronological)}
          endIcon={chronological ? <ExpandLess /> : <ExpandMore />}
        >
          時間
        </Button>
      </div>

      {applicantList.map((applicant) => (
        <MedSearchList key={applicant.id}>
          <div className="avatarWrap">
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          </div>

          <div className={classes.applicant}>
            <span>{applicant.name}</span>
            <Badge
              badgeContent={
                applicant.status === CONSIDERATION ? "残り13hr" : "残り15日"
              }
              color="error"
              invisible={!visibleBadegList.includes(applicant.status)}
            >
              <MedSearchStatusBox
                content={applicant.statusContent}
                colorIndex={applicant.status}
              />
            </Badge>
          </div>

          <div className={classes.contentWrap}>
            <Typography variant="h3">{applicant.title}</Typography>
            <Typography variant="h4">{applicant.subtitle}</Typography>
          </div>
          <Typography variant="h5" style={{ color: MedSearchColors.orange }}>
            {TypesOfEmployment[applicant.employmentType]}
          </Typography>
          <div className={classes.btnWrap}>
            <IconButton
              title="医師詳細"
              onClick={() =>
                history.push({
                  pathname: "/employer/management/applicant/info",
                  state: { applicant },
                })
              }
            >
              <DescriptionOutlinedIcon color="default" />
            </IconButton>

            {/* 非常勤・常勤・スカウト 的 "檢討中" 狀態 才能點 */}

            <IconButton
              title="交渉継続"
              disabled={
                applicant.employmentType === SPOT ||
                approvedBtnDisableList.includes(applicant.status)
              }
            >
              <ForumOutlinedIcon />
            </IconButton>
            <IconButton
              title="採用"
              disabled={
                approvedBtnDisableList.includes(applicant.status) ||
                applicant.employmentType === SPOT
              }
              onClick={() =>
                history.push("/employer/management/applicant/approve")
              }
            >
              <CheckIcon
                color={
                  approvedBtnDisableList.includes(applicant.status)
                    ? "default"
                    : "secondary"
                }
              />
            </IconButton>
            <IconButton
              title="不採用"
              disabled={rejectedBtnDisableList.includes(applicant.status)}
            >
              <BlockIcon
                style={{
                  color: rejectedBtnDisableList.includes(applicant.status)
                    ? ""
                    : MedSearchColors.zred,
                }}
              />
            </IconButton>
            <IconButton title="Download">
              <CloudDownloadOutlinedIcon />
            </IconButton>
            <IconButton title="メッセージ">
              <MessageOutlinedIcon />
            </IconButton>
          </div>
        </MedSearchList>
      ))}

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
