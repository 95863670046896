import React from "react";
import CommonFilter from "./CommonFilter";
import CommonBanner from "./CommonBanner";
import RecommendedInfo from "./RecommendedInfo";
import InfoList from "./InfoList/InfoList";
import PopularJobsList from "./PopularJobsList";

function HomePage() {
  return (
    <>
      <CommonBanner displaySearchBar={true} />
      <CommonFilter />
      <RecommendedInfo />
      <InfoList />
      <PopularJobsList />
    </>
  );
}

export default HomePage;
