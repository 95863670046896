import React from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { ReactComponent as IconUserFriends } from "../../assets/images/icon_collapse/user-friends.svg";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  TextField,
} from "@material-ui/core";

export default function Qualification() {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <MedSearchPrimaryAccordion
      HeaderIcon={<IconUserFriends />}
      HeaderTitle="資格"
      isExpanded={false}
    >
      <RadioGroup
        value={value}
        onChange={handleChange}
        style={{ flexDirection: "row", justifyContent: "space-around" }}
      >
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label={<Typography variant="h5">{"専門医"}</Typography>}
        />
        <FormControlLabel
          value="2"
          control={<Radio size="small" />}
          label={<Typography variant="h5">{"指導医"}</Typography>}
        />
        <FormControlLabel
          value="3"
          control={<Radio size="small" />}
          label={<Typography variant="h5">{"その他"}</Typography>}
        />
      </RadioGroup>

      {value === "3" && (
        <TextField label="その他" variant="outlined" size="small" />
      )}
    </MedSearchPrimaryAccordion>
  );
}
