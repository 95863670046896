import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
} from "@material-ui/core";
import { MedSearchColors } from "../../../assets/theme";

import { ReactComponent as Hospital01Svg } from "../../../assets/images/icon/icon_leftmenu_hospital01.svg";
import { ReactComponent as Hospital02Svg } from "../../../assets/images/icon/icon_leftmenu_hospital02.svg";
import { ReactComponent as Hospital03Svg } from "../../../assets/images/icon/icon_leftmenu_hospital03.svg";
import { ReactComponent as Hospital04Svg } from "../../../assets/images/icon/icon_leftmenu_hospital04.svg";
import { ReactComponent as Hospital06Svg } from "../../../assets/images/icon/icon_leftmenu_hospital06.svg";
import { ReactComponent as Hospital07Svg } from "../../../assets/images/icon/icon_leftmenu_hospital07.svg";
import { ReactComponent as Hospital08Svg } from "../../../assets/images/icon/icon_leftmenu_hospital08.svg";
import { ReactComponent as Hospital09Svg } from "../../../assets/images/icon/icon_leftmenu_hospital09.svg";
import { ReactComponent as Doctor08Svg } from "../../../assets/images/icon/icon_leftmenu_doctor08.svg";
import { ReactComponent as Doctor04Svg } from "../../../assets/images/icon/icon_leftmenu_doctor04.svg";
import { ReactComponent as Doctor07Svg } from "../../../assets/images/icon/icon_leftmenu_doctor07.svg";
import { ReactComponent as HeartSvg } from "../../../assets/images/icon/heart.svg";

import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  list: {
    minWidth: 260,
    transition: "0.3s",

    [theme.breakpoints.down("sm")]: {
      minWidth: 60,
    },
  },
  menuListItem: {
    minHeight: 60,
    borderBottom: `1px solid ${MedSearchColors.border}`,
    "&:hover .MuiTypography-root": {
      color: MedSearchColors.primary,
    },
    "&:hover .MuiListItemIcon-root": {
      color: MedSearchColors.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 60,
    },
  },
  menuIcon: { minWidth: 44 },
  menuTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },

    "&.active>.MuiTypography-root": {
      fontSize: 16,
      // fontWeight: 600,
      color: MedSearchColors.primary,
    },
    "&>.MuiTypography-root": {
      fontSize: 16,
      // fontWeight: 600,
      color: MedSearchColors.dark03dark,
    },
  },
}));

const menuList = [
  {
    id: 1,
    icon: <SvgIcon component={Hospital01Svg} title="雇用主の情報設定" />,
    title: "雇用主の情報設定",
    link: "/employer/management/profile",
  },
  {
    id: 2,
    icon: <SvgIcon component={Hospital02Svg} title="募集要項の管理" />,
    title: "募集要項の管理",
    link: "/employer/management/recruitment",
  },
  {
    id: 3,
    icon: <SvgIcon component={Hospital03Svg} title="スカウト対象医師" />,
    title: "スカウト対象医師",
    link: "/employer/doctors",
  },
  {
    id: 4,
    icon: <SvgIcon component={HeartSvg} title="お気に入り医師" />,
    title: "お気に入り医師",
    link: "/employer/management/favorite",
  },
  {
    id: 5,
    icon: <SvgIcon component={Hospital04Svg} title="応募医師管理" />,
    title: "応募医師管理",
    link: "/employer/management/applicant",
  },
  {
    id: 6,
    icon: <SvgIcon component={Doctor04Svg} title="勤務確定通知" />,
    title: "勤務確定通知",
    link: "/employer/management/notification",
  },
  {
    id: 7,
    icon: <SvgIcon component={Hospital06Svg} title="出勤・給与の確認" />,
    title: "出勤・給与の確認",
    link: "/employer/management/cap",
  },
  {
    id: 8,
    icon: <SvgIcon component={Hospital07Svg} title="請求書一覧" />,
    title: "請求書一覧",
    link: "/employer/management/invoice",
  },
  {
    id: 9,
    icon: <SvgIcon component={Hospital08Svg} title="メッセージ・チャット" />,
    title: "メッセージ・チャット",
    link: "",
  },
  {
    id: 10,
    icon: <SvgIcon component={Hospital09Svg} title="勤務医の位置情報" />,
    title: "勤務医の位置情報",
    link: "/employer/management/trace",
  },
  {
    id: 11,
    icon: <SvgIcon component={Doctor07Svg} title="カレンダー" />,
    title: "カレンダー",
    link: "/employer/management/calendar",
  },
  {
    id: 12,
    icon: <SvgIcon component={Doctor08Svg} title="アカウント設定" />,
    title: "アカウント設定",
    link: "/employer/management/settings",
  },
];

function SideMenu() {
  const classes = useStyle();

  return (
    <List className={classes.list}>
      {menuList.map((item) => (
        <>
          <ListItem
            key={item.id}
            className={classes.menuListItem}
            button
            component={Link}
            to={item.link}
          >
            <ListItemIcon className={classes.menuIcon} title={item.title}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              // className={cx(classes.menuTitle, item.id === 1 ? "active" : null)}
              className={classes.menuTitle}
            >
              {item.title}
            </ListItemText>
          </ListItem>
        </>
      ))}
    </List>
  );
}

export default SideMenu;
