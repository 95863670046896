import { Typography, Avatar, TextField } from "@material-ui/core";
import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import BasicInfo from "./BasicInfo";
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import Qualification from "./Qualification";
import Society from "./Society";
import PR from "./PR";

const fabMenuList = [
  {
    id: 1,
    icon: <SaveOutlinedIcon />,
    title: "登　録",
    link: "/doctor/resume",
  },
  {
    id: 2,
    icon: <ReplyOutlinedIcon />,
    title: "戻　る",
    link: "/doctor/resume",
  },
];

function EditIndex() {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 600,
      }}
    >
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">医師詳細情報</Typography>
      <Avatar
        style={{
          backgroundColor: "#a9dcf3",
          width: 100,
          height: 100,
          marginTop: "3%",
          marginBottom: "5%",
        }}
      >
        <AccountCircleIcon style={{ width: 100, height: 100 }} />
      </Avatar>

      <BasicInfo />
      <Education />
      <WorkExperience />
      <Qualification />
      <Society />
      <PR />
      <TextField label="備考" multiline maxRows={5} minRows={3} fullWidth />
    </div>
  );
}

export default EditIndex;
