//スポット
export const SPOT = 0;
//常勤
export const FULL_TIME = 1;
//非常勤
export const PART_TIME = 2;
//スカウト
export const SCOUT = 3;

export const TypesOfEmployment = ["スポット", "常勤", "非常勤", "スカウト"];
