import { makeStyles, Typography } from "@material-ui/core";
import { cardClasses } from "@mui/material";
import React from "react";
import { MedSearchColors } from "../assets/theme";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: "3%" },
  list: {
    marginTop: "1%",
    listStyle: "none",
    width: "100%",
    maxWidth: 800,
    lineHeight: 2.5,
    paddingLeft: 12,

    borderBottom: `2px solid ${MedSearchColors.primary}`,
  },
  title: {
    marginLeft: 6,
  },
  listItem: {
    display: "flex",
    borderBottom: `1px solid ${MedSearchColors.border}`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  listTitle: {
    minWidth: 160,
    fontSize: 16,
    color: MedSearchColors.dark04gray,
  },
  listValue: {
    fontSize: 16,
    color: MedSearchColors.dark02darkgray,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 8,
    },
  },
}));

function MedSearchInfoList({ title, dataList }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <ul className={classes.list}>
        {dataList.map((item) => (
          <li key={item.id} className={classes.listItem}>
            <div className={classes.listTitle}>{item.title}</div>
            <div className={classes.listValue}>{item.value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MedSearchInfoList;
