export const demoConfirmData1 = [
  { id: 1, title: "勤務先名", value: "船橋病院" },
  { id: 2, title: "住所", value: "千葉県　船橋市" },
  { id: 3, title: "アクセス", value: "新京成線　最寄駅より徒歩 10 分以内" },
  { id: 4, title: "集合場所", value: "集合場所" },
  { id: 5, title: "緊急連絡先", value: "0120-148-051" },
];

export const demoConfirmData2 = [
  { id: 1, title: "職務の種別", value: "病棟管理、一般外来、当直" },
  {
    id: 2,
    title: "職務の詳細",
    value:
      "有 2～3 回/月。夜間当直料：1回分は給与に含む、2 回目以降 50,000 円/回，当直体制：内科系 1 名、外科系 1 名、研修医",
  },
];

export const demoConfirmData3 = [
  {
    id: 1,
    title: "募集科目",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科",
  },
  { id: 2, title: "居住地域", value: "神奈川県　厚木市" },
  {
    id: 3,
    title: "その他条件",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
  },
];

export const demoConfirmData4 = [
  { id: 1, title: "勤務日時指定", value: "平日　8：30～17：15" },
  { id: 2, title: "給与", value: "5 年標準：980万円\n10 年標準：1,340万円" },
  { id: 3, title: "募集人数", value: 5 },
];
export const demoConfirmData5 = [
  { id: 1, title: "成果報酬", value: "" },
  { id: 2, title: "交通費", value: "有" },
  { id: 3, title: "残業代・その他", value: "" },
];
export const demoConfirmData6 = [
  {
    id: 1,
    title: "備考",
    value:
      "※年俸は、経験・能力に応じて考慮します。\n賞与：無\n歩合：無\n昇給：有\n査定：有\n退職金：有\n試用期間：無\n昇給等は法人や病院の実績等が考慮されます。",
  },
  { id: 2, title: "見学・トライアル", value: "無" },
];

export const demoConfirmData7 = [
  { id: 1, title: "名前", value: "織田信長" },
  { id: 2, title: "電話番号", value: "0120-148-051" },
  { id: 3, title: "住所", value: "千葉県　船橋市" },
];

export const demoConfirmData8 = [
  { id: 1, title: "名前", value: "明智光秀" },
  { id: 2, title: "電話番号", value: "0120-123-456" },
  { id: 3, title: "勤務日時", value: "平日　8：30～17：15" },
  {
    id: 4,
    title: "履歴書・証明書の内容",
    value: (
      <img
        alt=""
        src="/assets/images/Demo/img_certificatesample.jpg"
        width="260px"
        height="auto"
        style={{ margin: "2%" }}
      />
    ),
  },
];

export const demoConfirmData9 = [
  { id: 1, title: "勤務先名", value: "船橋病院" },
  { id: 2, title: "住所", value: "千葉県　船橋市" },
  {
    id: 3,
    title: "募集科目",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科",
  },
];

export const demoBasicInfoList = [
  // { id: 1, title: "管理者 Email", value: "manager@test.com" },
  { id: 2, title: "貴院（貴社）名称", value: "東名厚木病院" },
  { id: 3, title: "所在地", value: "神奈川県　厚木市" },
  { id: 4, title: "郵送先住所", value: "243-8571 神奈川県厚木市船子 232" },
  { id: 5, title: "電話番号", value: "064-229-1771" },
  // { id: 6, title: "管理者氏名", value: "日本花子" },
  // { id: 7, title: "所属部署・役職", value: "" },
];

export const demoHospitalInfo = [
  { id: 1, title: "施設形態", value: "病院" },
  {
    id: 2,
    title: "診療科目",
    value:
      "内科 消化器内科 呼吸器内科 循環器内科 神経内科 心療内科 整形外科 リハビリテーション科 人工透析科",
  },
  { id: 3, title: "病床数", value: "100〜199 床" },
  { id: 4, title: "救急指定", value: "二次" },
  { id: 5, title: "常勤医師数", value: 30 },
  { id: 6, title: "電子カルテ", value: "有" },
  {
    id: 7,
    title: "医療設備",
    value:
      "人工透析、リハビリ室、MRI(1.5T)、CT（64.16）、エコー（頸・腹、心）他",
  },
  {
    id: 8,
    title: "認定施設",
    value:
      "日本内科学会教育関連病院 \n日本循環器学会研修施設 \n日本血液学会研修施設",
  },
  // { id: 9, title: "保育所", value: "有" },
  { id: 10, title: "交通手段", value: "243-8571 神奈川県厚木市船子 232" },
  { id: 11, title: "備考", value: "" },
];
