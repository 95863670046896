import React from "react";
import { makeStyles } from "@material-ui/core";
import { MedSearchColors } from "../../assets/theme";

import DoctorDetail from "./DoctorDetail";
import { Switch, Route, Redirect } from "react-router";
import Filter from "../../views/DoctorsList/Filter";
import DoctorList from "./DoctorList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  searchBarWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchIcon: {
    color: MedSearchColors.primary,
  },
  filter: {
    width: "100%",
    maxWidth: 380,
    padding: "0 30px 0 0",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: 10,
    },
  },
  content: {
    position: "relative",
    width: "100%",
    maxWidth: 1020,
    padding: " 0 0 0 30px",
    "&::before": {
      position: "absolute",
      content: '""',
      left: 0,
      top: 5,
      width: 2,
      height: 668,
      backgroundColor: MedSearchColors.dark05lightgray,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      "&::before": {
        display: "none",
      },
    },
  },
}));

function DoctorsListIndex() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <Filter />
      </div>
      <div className={classes.content}>
        <Switch>
          <Route exact path="/employer/doctors">
            <DoctorList />
          </Route>
          <Route exact path="/employer/doctors/info">
            <DoctorDetail />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default DoctorsListIndex;
