import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Avatar,
  Divider,
  makeStyles,
} from "@material-ui/core";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import { MedSearchColors } from "../../assets/theme";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { HashLink } from "react-router-hash-link";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    height: 220,
    // backgroundPosition: "0 0, center",
    transform: "scale(1)",

    transition: "0.5s",
    "&:hover": {
      width: "110%",
      transform: "scale(1.1)",
    },
  },
  content: {
    background: "red",
  },
}));

const MedSearchCard = withStyles({
  root: {
    overflow: "hidden",

    "& .MuiCardMedia-root": {
      transform: "scale(1)",
      transition: "0.5s",
      backgroundPosition: "0 0, center",
    },
    "& .MuiAvatar-root": {
      backgroundColor: MedSearchColors.primary010,
      margin: "10px auto",
      width: 100,
      height: 100,
      transition: "0.5s",
    },
    "& #title": {
      marginTop: 20,
      textAlign: "center",
      color: MedSearchColors.primary,
      fontSize: 26,
      fontWeight: 700,
      transition: "0.5s",
      lineHeight: 2,
    },

    "&:hover": {
      "& .MuiCardMedia-root": {
        transform: "scale(1.1)",
      },
      "& .MuiAvatar-root": {
        boxShadow: "1px 1px 3px 0 #cccccc",
      },
      "& #title": {
        fontSize: 30,
      },
    },
  },
})(Card);

const cardList = [
  {
    id: 1,
    title: "サービスの特徴",
    icon: (
      <MedicalServicesOutlinedIcon
        style={{ color: MedSearchColors.primary, width: 66, height: 66 }}
      />
    ),
    img: "/assets/images/Employer/link-1.jpeg",
    link: "#servicefeatures",
  },
  {
    id: 2,
    title: "求人の手順",
    icon: (
      <AccountTreeOutlinedIcon
        style={{ color: MedSearchColors.primary, width: 66, height: 66 }}
      />
    ),
    img: "/assets/images/Employer/link-2.jpeg",
    link: "#howtouse",
  },
  {
    id: 3,
    title: "Q & A",
    icon: (
      <QuestionAnswerOutlinedIcon
        style={{ color: MedSearchColors.primary, width: 66, height: 66 }}
      />
    ),
    img: "/assets/images/Employer/link-3.jpeg",
    link: "#qanda",
  },
];

function LinkCards() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={5}
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        width: "100%",
        maxWidth: 1000,
        margin: "0px auto",
      }}
    >
      {cardList.map((item) => (
        <Grid key={item.id} item xs={12} md={4}>
          <HashLink to={item.link}>
            <Card style={{ maxWidth: 300, margin: "0 auto" }}>
              <CardMedia
                title=""
                image={item.img}
                style={{
                  width: "100%",
                  height: 220,
                  backgroundSize: "cover",
                }}
              />
              <Divider />
              <CardContent>
                <Avatar
                  style={{
                    backgroundColor: MedSearchColors.primary010,
                    margin: "10px auto",
                    width: 100,
                    height: 100,
                  }}
                >
                  {item.icon}
                </Avatar>
                <p
                  style={{
                    marginTop: 20,
                    textAlign: "center",
                    color: MedSearchColors.primary,
                    fontSize: 30,
                    fontWeight: 700,
                  }}
                >
                  {item.title}
                </p>
              </CardContent>
            </Card>
          </HashLink>
        </Grid>
      ))}
    </Grid>
  );
}

export default LinkCards;
