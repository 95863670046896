import React, { useState, useEffect } from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { ReactComponent as IconIncome } from "../../assets/images/icon_collapse/income.svg";
import { Button, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import MedSearchMedicalDepartmentDialogV2 from "../MedSearchMedicalDepartmentDialogV2";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 5,
    backgroundColor: "white",
    padding: "6px 8px",
    borderRadius: 0,
  },
}));
export default function MedSearchFilterDepartment2() {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDeparmentList, setSelectedDeparmentList] = useState([]);

  return (
    <MedSearchPrimaryAccordion
      HeaderIcon={<IconIncome />}
      HeaderTitle="希望科目"
      isExpanded={true}
    >
      <div>
        {selectedDeparmentList.map((department) => (
          <Chip label={department} className={classes.chip} />
        ))}
      </div>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
      >
        希望科目
      </Button>
      <MedSearchMedicalDepartmentDialogV2
        open={openDialog}
        setOpen={setOpenDialog}
        setSelectedDeparmentList={setSelectedDeparmentList}
      />
    </MedSearchPrimaryAccordion>
  );
}
