import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import { MedSearchColors } from "../../../../assets/theme";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import MedSearchMedicalDepartmentDialogV2 from "../../../../components/MedSearchMedicalDepartmentDialogV2";

export default function WorkExperience() {
  const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false);
  const [selectedDeparmentList, setSelectedDeparmentList] = useState([]);
  const [experienceList, setExperienceList] = useState([
    {
      startDate: null,
      endDate: null,
      experience: "", // 職歷
      position: "", // 職位
      department: "", //科目
      resigned: true, //退職済
    },
  ]);

  const addExperience = () => {
    let tempList = experienceList;
    tempList.push({
      startDate: null,
      endDate: null,
      experience: "", // 職歷
      position: "", // 職位
      department: "", //科目
      resigned: true, //退職済
    });
    setExperienceList([...tempList]);
  };
  const removeExperience = () => {
    //剩下最後一個不能刪
    if (experienceList.length === 1) return;

    let tempList = experienceList;
    tempList.pop();
    setExperienceList([...tempList]);
  };

  const changeData = (index, key, val) => {
    let tempList = experienceList;
    tempList[index][key] = val;
    setExperienceList([...tempList]);
  };

  return (
    <>
      <Typography variant="h6" style={{ margin: "50px 0 10px 0" }}>
        職歴
      </Typography>
      {experienceList.map((exp, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            backgroundColor: MedSearchColors.bgcard,
            padding: 8,
            margin: "6px 0px",
          }}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              label="職歴"
              value={exp.experience}
              onChange={(e) => changeData(index, "experience", e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="職位"
              value={exp.position}
              onChange={(e) => changeData(index, "position", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="担当科目"
              value={selectedDeparmentList.toString()}
              onClick={() => setOpenDepartmentDialog(true)}
              fullWidth
            />
            <MedSearchMedicalDepartmentDialogV2
              open={openDepartmentDialog}
              setOpen={setOpenDepartmentDialog}
              setSelectedDeparmentList={setSelectedDeparmentList}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <FormLabel component="legend">職務期間</FormLabel>
              <RadioGroup
                row
                name="resigned"
                value={exp.resigned}
                onChange={(e) => {
                  changeData(
                    index,
                    "resigned",
                    e.target.value === "true" ? true : false
                  );
                  if (e.target.value === "false")
                    changeData(index, "endDate", null);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="退職済"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="在職中"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7}>
            <DatePicker
              required
              clearable
              value={exp.startDate}
              format="yyyy/MM/dd"
              onChange={(val) => changeData(index, "startDate", val)}
              label="入職年月日"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
            />
            {exp.resigned && (
              <DatePicker
                required
                clearable
                value={exp.endDate}
                format="yyyy/MM/dd"
                onChange={(val) => changeData(index, "endDate", val)}
                label="退職年月日"
                cancelLabel="削除"
                clearLabel="キャンセル"
                okLabel="完了"
              />
            )}
          </Grid>
        </Grid>
      ))}

      <div
        style={{
          textAlign: "center",
          margin: "2px auto",
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addExperience}
          size="large"
        >
          追加
        </Button>

        <Button
          variant="text"
          color="primary"
          startIcon={<RemoveIcon />}
          onClick={removeExperience}
          size="large"
        >
          削除
        </Button>
      </div>
    </>
  );
}
