//ご利用方法
import React from "react";
import Typography from "@material-ui/core/Typography";
import MedSearchEmployerAccordion from "../../components/MedSearchEmployerAccordion";

function HowToUse() {
  return (
    <div style={{ width: "100%", maxWidth: 1000, margin: "0px auto" }}>
      <Typography variant="h1" color="initial">
        ご利用方法
      </Typography>
      <MedSearchEmployerAccordion headerTitle={"Step1 求人前の準備"}>
        <Typography>(1) アカウント登録</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          　　アカウント登録がまだの方は、こちらからお申込みください。
        </Typography>
        <Typography>(2) 履歴書および証明書</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          　　ログイン後、管理者様の証明書および雇用主の確認資料のアップロードをお願いします。
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"Step2 医師求人の方法"}>
        <Typography>医師求人には以下の二つの方法があります：</Typography>
        <Typography style={{ margin: "10px 0px" }}>
          ⑴ 募集要項を作成する方法
        </Typography>

        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          　ログイン後、「募集要項の管理」から、スポット、非常勤、常勤のいずれかを選択し、求人情報をご登録の上、　募集を開始して頂くと、求人情報が医師に公開されます。
        </Typography>

        <Typography style={{ margin: "10px 0px" }}>
          ⑵ 医師をスカウトする方法
        </Typography>

        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          　ログイン後、「スカウト対象医師」から、条件に合致する医師を検索し、「採用検討」を選択すると、　チャットを通じて採用検討中の医師との交渉を開始できます。
          <br />
          <br />
          　（採用決定までの間、チャット以外での交渉は原則禁止です。）
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"Step3 求人以降の流れ"}>
        <Typography>
          以下の３つのパターンのうち、いずれか１つに該当します。
        </Typography>

        <Typography>＊『スポットの場合』</Typography>
        <img
          src="/assets/images/flow/1.png"
          alt=""
          style={{ margin: 16, width: "100%" }}
        />
        <Typography>＊『非常勤および常勤の場合』</Typography>

        <img
          src="/assets/images/flow/2.png"
          alt=""
          style={{ margin: 16, width: "100%" }}
        />

        <Typography>＊『スカウトの場合』</Typography>
        <img
          src="/assets/images/flow/3.png"
          alt=""
          style={{ margin: 16, width: "100%" }}
        />
      </MedSearchEmployerAccordion>
    </div>
  );
}

export default HowToUse;
