export const MedSearchJapanCityList = [
  { region_id: 1, region: "北海道", city_id: 1, city: "北海道" },
  { region_id: 2, region: "東北", city_id: 2, city: "青森県" },
  { region_id: 2, region: "東北", city_id: 3, city: "岩手県" },
  { region_id: 2, region: "東北", city_id: 4, city: "宮城県" },
  { region_id: 2, region: "東北", city_id: 5, city: "秋田県" },
  { region_id: 2, region: "東北", city_id: 6, city: "山形県" },
  { region_id: 2, region: "東北", city_id: 7, city: "福島県" },
  { region_id: 3, region: "関東", city_id: 8, city: "東京都" },
  { region_id: 3, region: "関東", city_id: 9, city: "神奈川県" },
  { region_id: 3, region: "関東", city_id: 10, city: "埼玉県" },
  { region_id: 3, region: "関東", city_id: 11, city: "千葉県" },
  { region_id: 3, region: "関東", city_id: 12, city: "茨城県" },
  { region_id: 3, region: "関東", city_id: 13, city: "群馬県" },
  { region_id: 3, region: "関東", city_id: 14, city: "栃木県" },
  { region_id: 3, region: "関東", city_id: 15, city: "山梨県" },
  { region_id: 4, region: "北信越", city_id: 16, city: "新潟県" },
  { region_id: 4, region: "北信越", city_id: 17, city: "長野県" },
  { region_id: 4, region: "北信越", city_id: 18, city: "富山県" },
  { region_id: 4, region: "北信越", city_id: 19, city: "石川県" },
  { region_id: 4, region: "北信越", city_id: 20, city: "福井県" },
  { region_id: 5, region: "東海", city_id: 21, city: "愛知県" },
  { region_id: 5, region: "東海", city_id: 22, city: "岐阜県" },
  { region_id: 5, region: "東海", city_id: 23, city: "静岡県" },
  { region_id: 5, region: "東海", city_id: 24, city: "三重県" },
  { region_id: 6, region: "近畿", city_id: 25, city: "大阪府" },
  { region_id: 6, region: "近畿", city_id: 26, city: "兵庫県" },
  { region_id: 6, region: "近畿", city_id: 27, city: "京都府" },
  { region_id: 6, region: "近畿", city_id: 28, city: "滋賀県" },
  { region_id: 6, region: "近畿", city_id: 29, city: "奈良県" },
  { region_id: 6, region: "近畿", city_id: 30, city: "和歌山県" },
  { region_id: 7, region: "中国", city_id: 31, city: "岡山県" },
  { region_id: 7, region: "中国", city_id: 32, city: "広島県" },
  { region_id: 7, region: "中国", city_id: 33, city: "山口県" },
  { region_id: 7, region: "中国", city_id: 34, city: "鳥取県" },
  { region_id: 7, region: "中国", city_id: 35, city: "島根県" },
  { region_id: 8, region: "四国", city_id: 36, city: "徳島県" },
  { region_id: 8, region: "四国", city_id: 37, city: "香川県" },
  { region_id: 8, region: "四国", city_id: 38, city: "愛媛県" },
  { region_id: 8, region: "四国", city_id: 39, city: "高知県" },
  { region_id: 9, region: "九州・沖縄", city_id: 40, city: "福岡県" },
  { region_id: 9, region: "九州・沖縄", city_id: 41, city: "佐賀県" },
  { region_id: 9, region: "九州・沖縄", city_id: 42, city: "長崎県" },
  { region_id: 9, region: "九州・沖縄", city_id: 43, city: "熊本県" },
  { region_id: 9, region: "九州・沖縄", city_id: 44, city: "大分県" },
  { region_id: 9, region: "九州・沖縄", city_id: 45, city: "宮崎県" },
  { region_id: 9, region: "九州・沖縄", city_id: 46, city: "鹿児島県" },
  { region_id: 9, region: "九州・沖縄", city_id: 47, city: "沖縄県" },
];
