import React from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function MedSearchEmployerAccordion(props) {
  const { headerTitle, children } = props;
  return (
    <Accordion expanded>
      {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
      <AccordionSummary>
        <Typography variant="h3">{headerTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default MedSearchEmployerAccordion;
