import { useEffect, useState } from "react";

const useJapanCity = (zipCode) => {
  const [prefixAddress, setPrefixAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (zipCode.length === 7) {
      const zip = zipCode.substring(0, 3) + "-" + zipCode.substring(3, 7);
      fetchData(zip);
    } else {
      setPrefixAddress("");
      setErrorMessage("");
    }
  }, [zipCode]);

  const fetchData = async (zip) => {
    try {
      const response = await fetch(
        `https://api.zipaddress.net/?zipcode=${zip}`
      );
      const jsonData = await response.json();
      console.log("jsonData:", jsonData);
      if (jsonData.code === 200) {
        const addressData = jsonData.data;

        setPrefixAddress(
          addressData.pref + addressData.city + addressData.town
        );
        setErrorMessage("");
      } else {
        setErrorMessage(jsonData.message);
        setPrefixAddress("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return [prefixAddress, errorMessage];
};

export default useJapanCity;
