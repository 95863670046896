import React from "react";
import MedSearchFilterDepartment from "./MedSearchFilterDepartment";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import WorkRegion from "./WorkRegion";
import WorkOnSatAndSun from "./WorkOnSatAndSun";
import HighAnnualInconme from "./HighAnnualInconme";
import PerformanceSalary from "./PerformanceSalary";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchBar from "./SearchBar";
import MedSearchFilterDepartment2 from "./MedSearchFilterDepartment2";

export default function FullTimeJobsFilter() {
  return (
    <>
      <MedSearchFilterDepartment2 />
      {/* <MedSearchFilterDepartment title={"希望科目"} /> */}
      <WorkRegion />
      <HighAnnualInconme />

      <MedSearchPrimaryAccordion
        HeaderIcon={<PlaylistAddIcon />}
        HeaderTitle="さらに条件を追加する "
        isExpanded={false}
        lightTheme={true}
      >
        <WorkOnSatAndSun />
        <PerformanceSalary />
        <SearchBar />
      </MedSearchPrimaryAccordion>
    </>
  );
}
