//請求書一覧
import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import InvoiceList from "./InvoiceList";

export default function InvoiceIndex() {
  return (
    <div>
      <Typography variant="h1">請求書一覧</Typography>
      <InvoiceList />
    </div>
  );
}
