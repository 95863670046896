import React from "react";
import { makeStyles, Button, Divider } from "@material-ui/core";
import { MedSearchColors } from "../../assets/theme";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: "40px 70px",
  },
  footerWrap: {
    width: "100%",
    maxWidth: 1440,
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  logo: {
    width: "100%",
    maxWidth: 275,
    height: "auto",
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  linkWrap: {
    display: "flex",
    "&>button": {
      fontSize: 14,
      color: MedSearchColors.dark03dark,
      margin: "0 5px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  companyInfoWrap: {
    display: "flex",
    justifyContent: "flex-end",
    "&>span": {
      margin: 10,
      color: MedSearchColors.dark01,
      fontSize: 14,
    },
    "&>hr": {
      marginTop: 10,
      height: 20,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const menuList = {};

menuList["common"] = [
  { id: 0, title: "採用者の方へ", link: "/employer" },
  { id: 1, title: "サイトマップ", link: "/" },
  { id: 2, title: "プライバシーポリシー", link: "/" },
  { id: 3, title: "利用規約", link: "/" },
  { id: 4, title: "広告掲載について", link: "/employer" },
  { id: 6, title: "お問い合わせ", link: "/" },
  { id: 7, title: "医療計算機", link: "/" },
  { id: 8, title: "よくあるご質問", link: "/" },
];

menuList["employer"] = [
  { id: 0, title: "勤務医の方へ", link: "/" },
  { id: 1, title: "サイトマップ", link: "/" },
  { id: 2, title: "プライバシーポリシー", link: "/" },
  { id: 3, title: "利用規約", link: "/" },
  { id: 4, title: "広告掲載について", link: "/" },
  { id: 6, title: "お問い合わせ", link: "/" },
];

function MedSearchFooter({ employerMode = false }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.footerWrap}>
        <figure
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => history.push(employerMode ? "/employer" : "/")}
        >
          <img
            src="assets/images/logo.png"
            alt="MedSearch"
            className={classes.logo}
          />
        </figure>

        <div>
          <div className={classes.linkWrap}>
            {menuList[employerMode ? "employer" : "common"].map((item) => (
              <Button key={item.id} onClick={() => history.push(item.link)}>
                {item.title}
              </Button>
            ))}
          </div>
          <div className={classes.companyInfoWrap}>
            <span>03-6261-0781</span>
            <Divider orientation="vertical" flexItem />
            <span>service@doctorspace.com</span>
            <Divider orientation="vertical" flexItem />
            <span>東京都港区赤坂2-22-25</span>
            <Divider orientation="vertical" flexItem />

            <span>Mon-Fri 09:00-18:00</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedSearchFooter;
