import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Switch, Route } from "react-router";
import EmployerInfoIndex from "./EmployerInfo/EmployerInfoIndex";
import SideMenu from "./SideMenu";
import RecruitmentIndex from "./Recruitment/RecruitmentIndex";
import CreateIndex from "./Recruitment/Create/CreateIndex";
import { RecuitmentContext } from "./Recruitment/RecuitmentContext";
import ApplicantIndex from "./Applicant/ApplicantIndex";
import ApplicantInfo from "./Applicant/ApplicantInfo";
import NotificationIndex from "./Notification/NotificationIndex";
import NotificationInfo from "./Notification/NotificationInfo";
import CommutingAndPayIndex from "./CommutingAndPay/CommutingAndPayIndex";
import CommutingAndPayInfo from "./CommutingAndPay/CommutingAndPayInfo";
import InvoiceIndex from "./Invoice/InvoiceIndex";
import ReviewPDF from "./Invoice/ReviewPDF";
import TraceIndex from "./Trace/TraceIndex";
import AccountSettingsIndex from "./AccountSettings/AccountSettingsIndex";
import EmployerInfoSettings from "./EmployerInfo/EmployerInfoSettings";
import CalendarIndex from "./Calendar/CalendarIndex";
import ApproveIndex from "./Applicant/Approve/ApproveIndex";
import FavoriteIndex from "./Favorite/FavoriteIndex";
import DoctorInfo from "./Favorite/DoctorInfo";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1440,
    height: "fit-content",
    minHeight: "100vh",
    margin: "2% auto",
    marginTop: 100,
    display: "flex",
  },
  sideMenuWrap: {
    width: 280,
    height: 880,
    borderRight: "2px solid #eeeeee",
    paddingRight: "3%",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",

      paddingRight: "0%",
    },
  },
  contentWrap: {
    width: "100%",
    padding: "0 3%",
    height: "auto",
    maxWidth: 1160,
    overflow: "hidden",
  },
}));

function ManagementIndex() {
  const classes = useStyle();
  const [createType, setCreateType] = useState(0);

  return (
    <div className={classes.root}>
      <div className={classes.sideMenuWrap}>
        <SideMenu />
      </div>
      <div className={classes.contentWrap}>
        <Switch>
          <Route exact path="/employer/management">
            <EmployerInfoIndex />
          </Route>
          <Route exact path="/employer/management/profile">
            <EmployerInfoIndex />
          </Route>
          <Route exact path="/employer/management/profile/edit">
            <EmployerInfoSettings />
          </Route>
          <RecuitmentContext.Provider value={{ createType, setCreateType }}>
            <Route exact path="/employer/management/recruitment">
              <RecruitmentIndex />
            </Route>
            <Route path="/employer/management/recruitment/create">
              <CreateIndex />
            </Route>

            <Route exact path="/employer/management/favorite">
              <FavoriteIndex />
            </Route>
            <Route path="/employer/management/favorite/info">
              <DoctorInfo />
            </Route>

            <Route exact path="/employer/management/applicant">
              <ApplicantIndex />
            </Route>
            <Route exact path="/employer/management/applicant/info">
              <ApplicantInfo />
            </Route>
            <Route exact path="/employer/management/applicant/approve">
              <ApproveIndex />
            </Route>
            <Route exact path="/employer/management/notification">
              <NotificationIndex />
            </Route>
            <Route exact path="/employer/management/notification/info">
              <NotificationInfo />
            </Route>
            <Route exact path="/employer/management/cap">
              <CommutingAndPayIndex />
            </Route>
            <Route exact path="/employer/management/cap/info">
              <CommutingAndPayInfo />
            </Route>
            <Route exact path="/employer/management/invoice">
              <InvoiceIndex />
            </Route>
            <Route exact path="/employer/management/invoice/review">
              <ReviewPDF />
            </Route>
            <Route exact path="/employer/management/trace">
              <TraceIndex />
            </Route>
            <Route exact path="/employer/management/settings">
              <AccountSettingsIndex />
            </Route>
            <Route exact path="/employer/management/calendar">
              <CalendarIndex />
            </Route>
          </RecuitmentContext.Provider>
        </Switch>
      </div>
    </div>
  );
}

export default ManagementIndex;
