export const applicantList = [
  {
    id: 1,
    name: "織田信永",
    corporation: "東名厚木病院",
    statusContent: "採用",
    status: 0,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:30",
    no: "001-001",
    date: "2021/10/10",
    amount: "500,000",
    payStatus: "",
  },
  {
    id: 2,
    name: "織田信永",
    corporation: "東名厚木病院",
    statusContent: "キャンセル",
    status: 1,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:31",
    no: "001-002",
    date: "2021/10/10",
    amount: "500,000",
    payStatus: "",
  },
  {
    id: 3,
    name: "織田信永",
    corporation: "東名厚木病院",

    statusContent: "検討中",
    status: 2,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:32",
    no: "001-003",
    date: "2021/10/10",
    amount: "500,000",
    payStatus: "",
  },
  {
    id: 4,
    name: "織田信永",
    corporation: "東名厚木病院",

    statusContent: "交渉中",
    status: 3,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:33",
    no: "001-004",
    date: "2021/10/10",
    amount: "500,000",
    payStatus: "",
  },
  {
    id: 5,
    name: "織田信永",
    corporation: "東名厚木病院",

    statusContent: "最終確認待ち",
    status: 4,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:34",
    no: "001-005",
    date: "2021/10/10",
    amount: "500,000",
    payStatus: "",
  },
];
