import React from "react";
import jobInfoImg from "../../../../assets/images/Demo/img_jobinfo.png";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import {
  demoConfirmData2,
  demoConfirmData3,
  demoConfirmData4,
  demoConfirmData5,
  demoConfirmData6,
  demoConfirmData9,
} from "./DemoData";

function JobInfoContent() {
  return (
    <>
      <figure>
        <img src={jobInfoImg} alt="" style={{ width: "100%" }} />
      </figure>

      <MedSearchInfoList title="勤務先情報" dataList={demoConfirmData9} />
      <MedSearchInfoList title="勤務内容" dataList={demoConfirmData2} />
      <MedSearchInfoList title="医師条件" dataList={demoConfirmData3} />
      <MedSearchInfoList
        title="日時・給与・人数指定"
        dataList={demoConfirmData4}
      />
      <MedSearchInfoList title="その他支給" dataList={demoConfirmData5} />
      <MedSearchInfoList title="その他特記事項" dataList={demoConfirmData6} />
    </>
  );
}

export default JobInfoContent;
