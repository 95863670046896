import React from "react";
import MedSearchFilterGender from "../../components/MedSearchFilter/MedSearchFilterGender";
import MedSearchFilterExperience from "../../components/MedSearchFilter/MedSearchFilterExperience";
import MedSearchFilterDepartment2 from "../../components/MedSearchFilter/MedSearchFilterDepartment2";
import WorkRegion from "../../components/MedSearchFilter/WorkRegion";
import SearchBar from "../../components/MedSearchFilter/SearchBar";
import Skill from "../../components/MedSearchFilter/Skill";
import Qualification from "../../components/MedSearchFilter/Qualification";

function Filter() {
  return (
    <>
      <SearchBar />
      <WorkRegion title="居住地" />
      <MedSearchFilterDepartment2 />
      <MedSearchFilterGender />
      <MedSearchFilterExperience />
      <Skill />
      <Qualification />
    </>
  );
}

export default Filter;
