export const demoConfirmData1 = [
  { id: 1, title: "勤務先名", value: "船橋病院" },
  { id: 2, title: "住所", value: "千葉県　船橋市" },
  { id: 3, title: "アクセス", value: "新京成線　最寄駅より徒歩 10 分以内" },
  { id: 4, title: "集合場所", value: "集合場所" },
  { id: 5, title: "緊急連絡先", value: "0120-148-051" },
];

export const demoConfirmData2 = [
  { id: 1, title: "職務の種別", value: "病棟管理、一般外来、当直" },
  {
    id: 2,
    title: "職務の詳細",
    value:
      "有 2～3 回/月。夜間当直料：1回分は給与に含む、2 回目以降 50,000 円/回，当直体制：内科系 1 名、外科系 1 名、研修医",
  },
];

export const demoConfirmData3 = [
  {
    id: 1,
    title: "募集科目",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科",
  },
  { id: 2, title: "居住地域", value: "神奈川県　厚木市" },
  {
    id: 3,
    title: "その他条件",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
  },
];

export const demoConfirmData4 = [
  { id: 1, title: "勤務日時指定", value: "平日　8：30～17：15" },
  { id: 2, title: "給与", value: "5 年標準：980万円\n10 年標準：1,340万円" },
  { id: 3, title: "募集人数", value: 5 },
];
export const demoConfirmData5 = [
  { id: 1, title: "成果報酬", value: "" },
  { id: 2, title: "交通費", value: "有" },
  { id: 3, title: "残業代・その他", value: "" },
];
export const demoConfirmData6 = [
  {
    id: 1,
    title: "備考",
    value:
      "※年俸は、経験・能力に応じて考慮します。\n賞与：無\n歩合：無\n昇給：有\n査定：有\n退職金：有\n試用期間：無\n昇給等は法人や病院の実績等が考慮されます。",
  },
  { id: 2, title: "持参物", value: "無" },
  { id: 3, title: "環境", value: "無" },
];

export const demoConfirmData7 = [
  { id: 1, title: "名前", value: "織田信長" },
  { id: 2, title: "電話番号", value: "0120-148-051" },
  { id: 3, title: "住所", value: "千葉県　船橋市" },
];

export const demoConfirmData8 = [
  { id: 1, title: "名前", value: "明智光秀" },
  { id: 2, title: "電話番号", value: "0120-123-456" },
  { id: 3, title: "勤務日時", value: "平日　8：30～17：15" },
  {
    id: 4,
    title: "履歴書・証明書の内容",
    value: (
      <img
        alt=""
        src="/assets/images/Demo/img_certificatesample.jpg"
        style={{ margin: "2%", maxWidth: 300, width: "100%", height: "auto" }}
      />
    ),
  },
];

export const demoConfirmData9 = [
  { id: 1, title: "勤務先名", value: "船橋病院" },
  { id: 2, title: "住所", value: "千葉県　船橋市" },
  {
    id: 3,
    title: "募集科目",
    value: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科",
  },
];

export const demoConfirmData10 = [
  { id: 1, title: "ふりがな", value: "やまだ たろう" },
  { id: 2, title: "氏名", value: "山田 太郎" },
  {
    id: 3,
    title: "現住所",
    value: "〒000-0000 東京都港区赤坂",
  },
  { id: 4, title: "希望科目", value: "内科全般、訪問診療" },
  { id: 5, title: "臨床年数", value: "5" },
  { id: 6, title: "性別", value: "男" },
  { id: 7, title: "生年月日", value: "西暦〇〇年〇〇月〇〇日　（満〇〇歳）" },
  // { id: 8, title: "携帯番号", value: "090-0000-0000" },
  // { id: 9, title: "Email", value: "doctor.space@gmail.com" },
  { id: 10, title: "スキル", value: "電子カルテ、胃カメラ・・・・" },
];

export const demoConfirmData11 = [
  { id: 1, title: "2009年", value: "「〇〇について」　論文を発表" },
  { id: 2, title: "2008年", value: "「〇〇について」　論文を発表" },
  { id: 3, title: "2007年", value: "「〇〇について」　論文を発表" },
];

export const demoConfirmData12 = [
  {
    id: 1,
    title: "医師免許証 ここに表示",
    value: (
      <img
        alt=""
        src="/assets/images/Demo/img_certificatesample.jpg"
        style={{ margin: "2%", maxWidth: 300, width: "100%", height: "auto" }}
      />
    ),
  },
  { id: 2, title: "保険医登録票", value: "" },
];
export const demoConfirmData13 = [
  { id: 1, title: "希望年収", value: "10,000,000" },
  { id: 2, title: "通勤範囲", value: "30" },
  { id: 3, title: "その他", value: "ooooo" },
];

export const demoConfirmData14 = [
  { id: 1, title: "1988/03", value: "〇〇大学医学部〇〇科　卒業" },
  { id: 2, title: "1982/04", value: "〇〇大学医学部〇〇科　入学" },
  { id: 3, title: "1982/03", value: "東京都港区高校　卒業" },
];

export const demoConfirmData15 = [
  { id: 1, title: "1995/10", value: "〇〇病院　入職　（〇〇科　医長）" },
  { id: 2, title: "1995/08", value: "〇〇大学医学部附属病院　退職" },
  { id: 3, title: "1988/04", value: "〇〇大学医学部附属病院　入職" },
];

export const demoConfirmData16 = [
  { id: 1, title: "2000/03", value: "日本〇〇科学会認定〇〇医　取得" },
  { id: 2, title: "1995/04", value: "日本〇〇科学会専門医　取得" },
  { id: 3, title: "1994/03", value: "日本〇〇科学会認定医　取得" },
];
export const demoConfirmData17 = [
  { id: 1, title: "1994", value: "日本〇〇科学会　会員" },
];

export const demoConfirmData18 = [
  // { id: 1, title: "ふりがな", value: "やまだ たろう" },
  // { id: 2, title: "氏名", value: "山田 太郎" },
  {
    id: 3,
    title: "現住所",
    value: "〒000-0000 東京都港区赤坂",
  },
  { id: 4, title: "希望科目", value: "内科全般、訪問診療" },
  { id: 5, title: "臨床年数", value: "5" },
  { id: 6, title: "性別", value: "男" },
  // { id: 7, title: "生年月日", value: "西暦〇〇年〇〇月〇〇日　（満〇〇歳）" },
  // { id: 8, title: "携帯番号", value: "090-0000-0000" },
  // { id: 9, title: "Email", value: "doctor.space@gmail.com" },
  { id: 10, title: "スキル", value: "電子カルテ、胃カメラ・・・・" },
];

export const demoConfirmData19 = [
  { id: 1, title: "勤務日時", value: "2021/12/12 08:30 ~ 17:30" },
];
