import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import { MedSearchColors } from "../../../../assets/theme";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

export default function Qualification() {
  const [qualificationList, setQualificationList] = useState([
    {
      qualification: "",
      description: "",
    },
  ]);

  const addQualification = () => {
    let tempList = qualificationList;
    tempList.push({
      qualification: "",
      description: "",
    });
    setQualificationList([...tempList]);
  };
  const removeQualification = () => {
    //剩下最後一個不能刪
    if (qualificationList.length === 1) return;

    let tempList = qualificationList;
    tempList.pop();
    setQualificationList([...tempList]);
  };

  const changeData = (index, key, val) => {
    let tempList = qualificationList;
    tempList[index][key] = val;
    setQualificationList([...tempList]);
  };

  return (
    <>
      <Typography variant="h6" style={{ margin: "50px 0 10px 0" }}>
        免許・資格
      </Typography>
      {qualificationList.map((qua, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            backgroundColor: MedSearchColors.bgcard,
            padding: 8,
            margin: "6px 0px",
          }}
        >
          <Grid item xs={12} sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">保有資格(免許)</FormLabel>
              <RadioGroup
                row
                name="qualifications"
                value={qua.qualification}
                onChange={(e) =>
                  changeData(index, "qualification", e.target.value)
                }
              >
                <FormControlLabel
                  value={"0"}
                  control={<Radio />}
                  label="認定医"
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="専門医"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="指導医"
                />
                <FormControlLabel
                  value={"3"}
                  control={<Radio />}
                  label="その他"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {qua.qualification !== "3" && (
            <Grid item xs={12} sm={8}>
              <TextField
                label="Description"
                value={qua.description}
                onChange={(e) =>
                  changeData(index, "description", e.target.value)
                }
                fullWidth
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
          )}
        </Grid>
      ))}

      <div
        style={{
          textAlign: "center",
          margin: "2px auto",
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addQualification}
          size="large"
        >
          追加
        </Button>

        <Button
          variant="text"
          color="primary"
          startIcon={<RemoveIcon />}
          onClick={removeQualification}
          size="large"
        >
          削除
        </Button>
      </div>
    </>
  );
}
