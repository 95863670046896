import React from "react";
import { Route, Switch, useParams } from "react-router";
import {
  SPOT,
  FULL_TIME,
  PART_TIME,
} from "../../../assets/constant/TypesOfEmployment";
import PartTimeJobsFilter from "../../../components/MedSearchFilter/PartTimeJobsFilter";
import FullTimeJobsFilter from "../../../components/MedSearchFilter/FullTimeJobsFilter";
import MedSearchLayoutTwoColumn from "../../../components/MedSearchLayoutTwoColumn";
import Filter from "../../DoctorsList/Filter";
import JobsInfoIndex from "./JobsInfo/JobsInfoIndex";
import JobsList from "./JobsList";
import SpotJobsFilter from "../../../components/MedSearchFilter/SpotJobsFilter";

const filter = (type) => {
  switch (parseInt(type)) {
    case SPOT:
      return <SpotJobsFilter />;
    case FULL_TIME:
      return <FullTimeJobsFilter />;
    case PART_TIME:
      return <PartTimeJobsFilter />;
    default:
      return <Filter />;
  }
};

const contentSection = () => {
  return (
    <Switch>
      <Route exact path="/jobs/:type/info">
        <JobsInfoIndex />
      </Route>
      <Route path="/jobs/:type">
        <JobsList />
      </Route>
    </Switch>
  );
};

export default function JobsIndex() {
  const { type } = useParams();
  console.log("JobsIndex type:", type);

  return (
    <>
      <MedSearchLayoutTwoColumn
        leftSideContent={filter(type)}
        rightSideContent={contentSection()}
      />
    </>
  );
}
