import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { MedSearchColors } from "../../../assets/theme";
import { DatePicker } from "@material-ui/pickers";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
} from "../../../assets/constant/TypesOfEmployment";
import MedSearchJapanCityDialog from "../../../components/MedSearchJapanCityDialog";
const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: "3% auto",
    maxWidth: "fit-content",
    width: "100%",
  },
}));
export default function Filter() {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [isJapanCityDialogOpen, setIsJapanCityDialogOpen] = useState(false);
  //已選的城市清單
  const [selectedCityList, setSelectedCityList] = useState([]);
  const [tabsIndex, setTabsIndex] = useState(0);
  const handleChangeTabsIndex = (event, newValue) => {
    setTabsIndex(newValue);
  };
  const tabList = [
    { id: 1, title: "応募済み" },
    { id: 2, title: "要承認" },
    { id: 3, title: "交渉中" },
    { id: 4, title: "勤務確定" },
    { id: 5, title: "審査待ち" },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        style={{
          backgroundColor: MedSearchColors.bgcard,
          padding: 8,
          margin: "20px 0px",
          boxShadow: "1px 1px 3px 0 rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid item xs={6} sm={4}>
          <DatePicker
            clearable
            value={startDate}
            format="yyyy/MM/dd"
            onChange={setStartDate}
            label="開始日"
            cancelLabel="削除"
            clearLabel="キャンセル"
            okLabel="完了"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <DatePicker
            clearable
            value={endDate}
            format="yyyy/MM/dd"
            onChange={setEndDate}
            label="終了日"
            cancelLabel="削除"
            clearLabel="キャンセル"
            okLabel="完了"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            select
            label="状態"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            fullWidth
          >
            {tabList.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="地域"
            value={selectedCityList}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            onClick={() => setIsJapanCityDialogOpen(true)}
          />
        </Grid>

        <Grid item xs={5} sm={3}>
          <TextField
            select
            label="タイプ"
            value={selectedJobType}
            onChange={(e) => setSelectedJobType(e.target.value)}
            fullWidth
          >
            {typeList.map((type) => (
              <MenuItem key={type.id} value={type.value}>
                {type.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={7} sm={7}>
          <TextField label="キーワード" fullWidth />
        </Grid>
        <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
          >
            検索
          </Button>
        </Grid>
      </Grid>
      <Tabs
        value={tabsIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTabsIndex}
        variant="scrollable"
        className={classes.tabs}
      >
        {tabList.map((item) => (
          <Tab key={item.id} label={item.title} />
        ))}
      </Tabs>
      <MedSearchJapanCityDialog
        isJapanCityDialogOpen={isJapanCityDialogOpen}
        setIsJapanCityDialogOpen={setIsJapanCityDialogOpen}
        selectedCityList={selectedCityList}
        setSelectedCityList={setSelectedCityList}
      />
    </>
  );
}
const statusList = [
  { id: 1, text: "要承認" },
  { id: 2, text: "交渉中" },
  { id: 3, text: "審査待ち" },
  { id: 4, text: "キャンセル" },
  { id: 5, text: "勤務確定" },
];
const typeList = [
  { id: 1, text: "スポット", value: SPOT },
  { id: 2, text: "常勤", value: FULL_TIME },
  { id: 3, text: "非常勤", value: PART_TIME },
];
