//よくある質問（Q &A）
import React from "react";
import Typography from "@material-ui/core/Typography";
import MedSearchEmployerAccordion from "../../components/MedSearchEmployerAccordion";

function QandA() {
  return (
    <div style={{ width: "100%", maxWidth: 1000, margin: "0px auto" }}>
      <Typography variant="h1" color="initial">
        よくある質問（Q&A）
      </Typography>
      <MedSearchEmployerAccordion headerTitle={"サービスの内容と特徴は？"}>
        <Typography>
          Doctor Space
          は、仲介者をはさまず、直接ご希望の医師と交渉・契約できる、画期的な医師求人プラットフォームです。
        </Typography>
        <br />
        <Typography>
          仲介者を挟まないため、格安の利用料でご活用頂けます。
        </Typography>
        <br />
        <Typography>
          また医師と雇用主（医療機関等）をダイレクトに繋ぐ伝達ツールと高度な自動化システムにより、従来型サービスよりも格段に早く、ご希望の医師を採用出来ます。
        </Typography>
        <br />
        <Typography>
          詳細は：「<a href="#servicefeatures">サービスの特徴</a>
          」をご覧下さい。
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"利用料はいくらですか？"}>
        <Typography>
          当社の求人掲載プラットフォームは、業界最安圏の利用料となっております。
        </Typography>
        <br />
        <Typography> 詳細につきましては、以下をご参照下さいませ。</Typography>「
        <a href="/">利用料金の規定</a>」
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"サービスの利用手順は？"}>
        <Typography>
          アカウント登録後、管理者様および雇用主様の確認資料をUPLOADして頂きます。当社審査後、速やかに求人募集をご利用になれます。（原則24hr対応ですので、深夜帯や休日にご利用頂くことも可能です。）
        </Typography>
        <br />
        <Typography>
          アカウント登録〜ご採用の流れにつきましては、以下をご参照下さいませ。
        </Typography>
        <Typography>
          　「<a href="#howtouse">ご利用方法」</a>
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"スカウトとは？"}>
        <Typography>
          スカウトでは、採用担当者様が、チャットを通じて採用したい医師に直接コンタクトを取り、勤務交渉を行います。
        </Typography>
        <br />
        <Typography>
          スカウトの利点として、候補医師の経歴や資格等から、ご要望に合致する医師を絞り込め、厳選されたハイグレードな医師の採用が可能です。
        </Typography>
        <br />
        <Typography>
          また、一般には情報公開できない求人も、チャットを通じて個別対応することで対応可能です。さらに医師の要望を的確に把握し、柔軟に対応することで成約率が向上し、早期採用が期待出来ます。
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"医師求人の方法"}>
        <Typography>医師求人には以下の二つの方法があります。</Typography>
        <br />
        <Typography>1.募集要項を作成する方法</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          ログイン後、「募集要項の管理」から、スポット、非常勤、常勤のいずれかを選択し、求人情報をご登録の上、募集を開始して頂くと、求人情報が医師に公開されます。
        </Typography>
        <br />
        <Typography>2.医師をスカウトする方法</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          ログイン後、「スカウト対象医師」から、条件に合致する医師を検索し、「採用検討」を選択すると、チャットを通じて採用検討中の医師との交渉を開始できます。
          （採用決定までの間、チャット以外での交渉は原則禁止です。）
        </Typography>
      </MedSearchEmployerAccordion>
    </div>
  );
}

export default QandA;
