import { withStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

export const MedSearchPagination = withStyles((theme) => ({
  root: {
    marginTop: "5%",
  },
  ul: {
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      "& .MuiPaginationItem-root": {
        margin: 0,
      },
    },
  },
}))(Pagination);
