// スポット 年収＊
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    maxWidth: 280,
    marginBottom: "3%",
  },
  blockWrap: {
    display: "flex",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
}));

export default function SpotSalary() {
  const classes = useStyles();

  return (
    <div className={classes.blockWrap}>
      <TextField
        required
        thousandSeparator={true}
        label="給与"
        variant="standard"
        placeholder="例：数字のみ入力可"
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
        className={classes.textFiled}
        style={{ marginRight: 12 }}
      />
      {/* <NumberFormat
        required
        customInput={TextField}
        thousandSeparator={true}
        label="給与"
        variant="standard"
        placeholder="例：数字のみ入力可"
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
        className={classes.textFiled}
        style={{ marginRight: 12 }}
      /> */}
    </div>
  );
}
