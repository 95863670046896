//勤務医の位置情報
import { Typography } from "@material-ui/core";
import React from "react";

function TraceIndex() {
  return (
    <div>
      <Typography variant="h1">勤務医の位置情報</Typography>
      <iframe
        title="trace"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3189143543545!2d139.69032371561218!3d35.69376893689084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d2aa55c5c85%3A0xa47b05cbe1ba1f94!2z5p2x5Lqs6Yar56eR5aSn5a246Yar6Zmi!5e0!3m2!1szh-TW!2stw!4v1633154703359!5m2!1szh-TW!2stw"
        style={{
          border: "2px solid #eeeeee",
          maxWidth: 880,
          minHeight: 600,
          width: "100%",
          height: "auto",
        }}
        allowfullscreen=""
        loading={"lazy"}
      ></iframe>
    </div>
  );
}

export default TraceIndex;
