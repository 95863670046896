//サービスの特徴
import React from "react";
import Typography from "@material-ui/core/Typography";
import MedSearchEmployerAccordion from "../../components/MedSearchEmployerAccordion";
import { MedSearchColors } from "../../assets/theme";

function ServiceFeatures() {
  return (
    <div style={{ width: "100%", maxWidth: 1000, margin: "0px auto" }}>
      <Typography variant="h1" color="initial">
        サービスの特徴
      </Typography>
      <Typography variant="subtitle1">
        Doctor Space
        は、従来にない特徴を備えた、画期的な医師求人のプラットフォームです。
      </Typography>
      <MedSearchEmployerAccordion
        headerTitle={"特徴1 ダイレクトなコミュニケーション"}
      >
        <Typography>従来型</Typography>
        <img
          src="/assets/images/flow/4.jpg"
          alt=""
          style={{ margin: 16, maxWidth: 450, width: "100%" }}
        />
        <Typography
          style={{
            color: MedSearchColors.zred,
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          Doctor Space
        </Typography>
        <img
          src="/assets/images/flow/5.jpg"
          alt=""
          style={{ margin: 16, maxWidth: 280, width: "100%" }}
        />

        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          医師とご採用者様の間に仲介者が入らず、ダイレクトなコミュニケーションを実現。
        </Typography>
        <Typography
          style={{
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          メリット
        </Typography>
        <Typography>(1) 迅速なレスポンス</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          仲介者を挟まないため、迅速なレスポンスが可能。
          <br />
          また原則24hr
          年中稼働のため、多忙な医療機関でも、時間的制約を受けずご利用になれます。
        </Typography>
        <Typography>(2) 人為的な伝達ミスの回避</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          仲介者が入ると、どうしても情報伝達の過程で人為的ミスが発生します。
          <br />
          医師と直接コンタクトすることで、コミュニケーションロスが減少します。
        </Typography>
        <Typography>(3) 緊急求人に対応</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          迅速なレスポンスにより、締切間近の緊急求人に対応可能。
          <br />
          これにより、医師の病欠等の突発的なアクシデントにも対応できます。
        </Typography>
      </MedSearchEmployerAccordion>

      <MedSearchEmployerAccordion headerTitle={"特徴2 割安な利用料"}>
        <Typography>
          Doctor Space
          は、仲介者を介さないため、業界最安値圏の利用料設定となっております。
          <br />
          利用料金の詳細は、こちら
        </Typography>
      </MedSearchEmployerAccordion>
      <MedSearchEmployerAccordion headerTitle={"特徴3 スカウト制"}>
        <Typography>
          「スカウト対象医師」の経歴等から、ご要望に合致する医師を絞り込め、ハイグレードな医師の採用が可能です。
          <br />
          また、一般には情報公開できない求人も、チャットを通じて個別対応することで対応可能です。
          <br />
          さらにチャットを通じて医師の要望を的確に把握し、柔軟に対応することで成約率が向上、早期採用が望めます。
        </Typography>
      </MedSearchEmployerAccordion>
      <MedSearchEmployerAccordion headerTitle={"特徴4 低い離脱率"}>
        <Typography>
          Doctor
          Spaceは、ポイント還元制度を導入しており、医師が一定期間勤務しないとポイント還元が受けられない。
          <br />
          仕組みとなっているため、定期勤務で採用した場合の早期離脱リスクが低くなり、採用コストの削減に寄与します。
        </Typography>
      </MedSearchEmployerAccordion>
      <MedSearchEmployerAccordion headerTitle={"特徴5 案件管理機能"}>
        <Typography>
          カレンダーと連携し、案件の進捗状況が一目瞭然で把握できる、便利な案件管理機能を実装。
          <br />
          これによりその他の予定との重複や抜け漏れ等を防ぐことが出来ます。
        </Typography>
      </MedSearchEmployerAccordion>
      <MedSearchEmployerAccordion headerTitle={"特徴6 充実の補助機能"}>
        <Typography>チャット機能</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          原則24hr年中、医師とチャット可能で、多忙な先生でも職務の合間に対応しやすくなっております。
          <br />
          また、迷惑な相手はブロック機能で連絡を遮断することが出来ます。
        </Typography>
        <Typography>自動アラート</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          締切前など、事前に自動でアラートが届くため、重要な予定の抜け漏れを防止できます。
        </Typography>
        <Typography>GPS機能</Typography>
        <Typography variant="h5" style={{ margin: "10px 0px" }}>
          緊急求人などの際、医師の位置情報を確認できるため、到着予定時刻等の予測に有用です。
        </Typography>
      </MedSearchEmployerAccordion>
    </div>
  );
}

export default ServiceFeatures;
