import React from "react";
import { Typography, makeStyles, IconButton } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { withStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import MedSearchList from "../../components/common/MedSearchList";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const MedSearchPagination = withStyles({
  root: {
    marginTop: "5%",
  },
  ul: {
    justifyContent: "center",
  },
})(Pagination);

const doctorsData = [{ id: 1 }, { id: 2 }, { id: 3 }];

export default function DoctorList() {
  const history = useHistory();

  return (
    <div>
      <Typography variant="h1">スカウト対象医師</Typography>

      {doctorsData.map((doctorData) => (
        <MedSearchList key={doctorData.id}>
          <div>
            <Typography variant="h3" style={{ margin: "6px 0" }}>
              居住地(都道府県)
            </Typography>
            <Typography variant="h4">北海道</Typography>
          </div>
          <div>
            <Typography variant="h3" style={{ margin: "6px 0" }}>
              希望（診療）科目
            </Typography>
            <Typography variant="h4">耳鼻咽喉科、心療内科</Typography>
          </div>

          <div>
            <Typography variant="h3" style={{ margin: "6px 0" }}>
              臨床経験(年数){" "}
            </Typography>
            <Typography variant="h4">28</Typography>
          </div>
          <div>
            <Typography variant="h3" style={{ margin: "6px 0" }}>
              性別
            </Typography>
            <Typography variant="h4">男</Typography>
          </div>
          <div>
            <IconButton
              title="医師詳細"
              onClick={() => history.push("/employer/doctors/info")}
            >
              <DescriptionOutlinedIcon color="default" />
            </IconButton>
            <IconButton title="お気に入り">
              <FavoriteBorderIcon />
            </IconButton>
            <IconButton title="チャット">
              <ChatOutlinedIcon />
            </IconButton>
          </div>
        </MedSearchList>
      ))}

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
