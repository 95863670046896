import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import MedSearchDoctorProfile2 from "./MedSearchDoctorProfile2";
import { MedSearchColors } from "../assets/theme";

export default function MedSearchDoctorProfileWithAccordion() {
  return (
    <Accordion
      style={{
        backgroundColor: MedSearchColors.primary005,
        maxWidth: 800,
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        style={{
          backgroundColor: MedSearchColors.primary,
          color: "white",
        }}
      >
        {"　"}
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={<AssignmentIndOutlinedIcon />}
          label="　医師の履歴書"
        />
      </AccordionSummary>
      <AccordionDetails style={{ paddingLeft: 8 }}>
        <div style={{ margin: "0 auto", width: "100%" }}>
          <MedSearchDoctorProfile2 showMoreInfo={true} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
