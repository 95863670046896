export const MedSearchMedicalDepartmentList = [
  {
    category_id: 1,
    category: "内科系",
    department_id: 1,
    department: "内科全般",
  },
  {
    category_id: 1,
    category: "内科系",
    department_id: 2,
    department: "消化器",
  },
  {
    category_id: 1,
    category: "内科系",
    department_id: 3,
    department: "呼吸器",
  },
  {
    category_id: 1,
    category: "内科系",
    department_id: 4,
    department: "循環器",
  },
  { category_id: 1, category: "内科系", department_id: 5, department: "腎臓" },
  {
    category_id: 1,
    category: "内科系",
    department_id: 6,
    department: "人工透析",
  },
  { category_id: 1, category: "内科系", department_id: 7, department: "神経" },
  {
    category_id: 1,
    category: "内科系",
    department_id: 8,
    department: "内分泌代謝・糖尿",
  },
  {
    category_id: 1,
    category: "内科系",
    department_id: 9,
    department: "膠原病・アレルギー",
  },
  { category_id: 1, category: "内科系", department_id: 10, department: "血液" },
  {
    category_id: 1,
    category: "内科系",
    department_id: 11,
    department: "老人(老年)",
  },
  {
    category_id: 2,
    category: "外科系",
    department_id: 12,
    department: "消化器・肛門",
  },
  {
    category_id: 2,
    category: "外科系",
    department_id: 13,
    department: "呼吸器",
  },
  {
    category_id: 2,
    category: "外科系",
    department_id: 14,
    department: "心臓血管",
  },
  { category_id: 2, category: "外科系", department_id: 15, department: "整形" },
  {
    category_id: 2,
    category: "外科系",
    department_id: 16,
    department: "脳神経",
  },
  { category_id: 2, category: "外科系", department_id: 17, department: "乳腺" },
  {
    category_id: 2,
    category: "外科系",
    department_id: 18,
    department: "泌尿器",
  },
  { category_id: 2, category: "外科系", department_id: 19, department: "形成" },
  {
    category_id: 2,
    category: "外科系",
    department_id: 20,
    department: "美容外科",
  },
  {
    category_id: 2,
    category: "外科系",
    department_id: 21,
    department: "内分泌",
  },
  { category_id: 2, category: "外科系", department_id: 22, department: "小児" },
  {
    category_id: 3,
    category: "眼科・皮膚科・耳鼻科",
    department_id: 23,
    department: "眼科",
  },
  {
    category_id: 3,
    category: "眼科・皮膚科・耳鼻科",
    department_id: 24,
    department: "皮膚科",
  },
  {
    category_id: 3,
    category: "眼科・皮膚科・耳鼻科",
    department_id: 25,
    department: "美容皮膚科",
  },
  {
    category_id: 3,
    category: "眼科・皮膚科・耳鼻科",
    department_id: 26,
    department: "耳鼻咽喉科",
  },
  {
    category_id: 4,
    category: "小児科・産婦人科",
    department_id: 27,
    department: "小児科",
  },
  {
    category_id: 4,
    category: "小児科・産婦人科",
    department_id: 28,
    department: "婦人科",
  },
  {
    category_id: 4,
    category: "小児科・産婦人科",
    department_id: 29,
    department: "産科",
  },
  {
    category_id: 5,
    category: "精神科・心療内科",
    department_id: 30,
    department: "精神科",
  },
  {
    category_id: 5,
    category: "精神科・心療内科",
    department_id: 31,
    department: "心療内科",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 32,
    department: "麻酔科",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 33,
    department: "ペインクリニック",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 34,
    department: "緩和ケア",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 35,
    department: "救命救急",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 36,
    department: "放射線科",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 37,
    department: "病理科",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 38,
    department: "在宅医療",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 39,
    department: "健診・人間ドック",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 40,
    department: "リハビリ",
  },
  {
    category_id: 6,
    category: "その他",
    department_id: 41,
    department: "その他",
  },
];
