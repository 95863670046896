//勤務確定通知
import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import NotificationList from "./NotificationList";

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: "3% auto",
    maxWidth: "fit-content",
    width: "100%",
  },
}));

export default function NotificationIndex() {
  return (
    <div>
      <Typography variant="h1">勤務確定通知</Typography>

      <NotificationList />
    </div>
  );
}
