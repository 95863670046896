import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  Divider,
  Badge,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { MedSearchPagination } from "../../../components/common/MedSearchPagination";
import { MedSearchColors } from "../../../assets/theme";
import { ReactComponent as IconListH } from "../../../assets/images/list_h.svg";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import Filter from "./Filter";
import {
  APPLIED,
  APPROVAL,
  CONFIRM,
  DOCTOR_JOBS_STATUS_LIST,
  NEGOTIATION,
  REVIEW,
} from "../../../assets/constant/DoctorJobsStatus";
import MedSearchStatusBox from "../../../components/common/MedSearchStatusBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 800,
  },
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  titleWrap: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: 96,
      justifyContent: "space-around",
    },
  },
  btnWrap: {
    display: "flex",
    justifyContent: "space-between",
    width: 140,
  },
  statusLabel: {
    marginTop: 6,
    marginRight: 10,
    fontSize: 18,
    color: MedSearchColors.primary,
  },
}));

const JobsListItem = withStyles((theme) => ({
  root: {
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    marginBottom: "2%",
  },
}))(ListItem);

export default function JobsIndex() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Typography variant="h1">案件管理</Typography>
      <Filter />
      <Typography variant="h2">合計 320 件ＯＯＯＯＯＯＯＯＯＯ</Typography>

      <List>
        {Array.from(Array(5), (e, i) => (
          <JobsListItem alignItems="flex-start" key={i}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: MedSearchColors.primary }}>
                <IconListH />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div className={classes.titleWrap}>
                  東名厚木病院 消化器內科
                  <div className={classes.btnWrap}>
                    {i === APPLIED && (
                      <span
                        className={classes.statusLabel}
                        style={{ color: MedSearchColors.primary }}
                      >
                        {`${DOCTOR_JOBS_STATUS_LIST[i]}`}
                      </span>
                    )}
                    {i === APPROVAL && (
                      // <span
                      //   className={classes.statusLabel}
                      //   style={{
                      //     background: MedSearchColors.primary,
                      //     color: "white",
                      //   }}
                      // >
                      //   {`${DOCTOR_JOBS_STATUS_LIST[i]}`}
                      // </span>
                      <Badge badgeContent="残り13hr" color="error">
                        <MedSearchStatusBox
                          content={DOCTOR_JOBS_STATUS_LIST[i]}
                          colorIndex={0}
                        />
                      </Badge>
                    )}
                    {i === NEGOTIATION && (
                      <Badge badgeContent="残り7日 " color="error">
                        <MedSearchStatusBox
                          content={DOCTOR_JOBS_STATUS_LIST[i]}
                          colorIndex={2}
                        />
                      </Badge>
                    )}
                    {i === CONFIRM && (
                      <span
                        className={classes.statusLabel}
                        style={{ color: MedSearchColors.orange }}
                      >
                        {`${DOCTOR_JOBS_STATUS_LIST[i]}`}
                      </span>
                    )}
                    {i === REVIEW && (
                      <span
                        className={classes.statusLabel}
                        style={{ color: MedSearchColors.zred }}
                      >
                        {`${DOCTOR_JOBS_STATUS_LIST[i]}`}
                      </span>
                    )}

                    <IconButton
                      style={{
                        width: 36,
                        height: 36,
                      }}
                    >
                      <ChatOutlinedIcon style={{ width: 26 }} />
                    </IconButton>
                  </div>
                </div>
              }
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="h4">
                    病院名
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" 東名厚木病院"}
                  </Typography>
                  <Divider style={{ margin: "8px auto" }} />

                  <Typography component="span" variant="h4">
                    勤務地
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" 神奈川県　厚木市"}
                  </Typography>
                  <Divider style={{ margin: "8px auto" }} />

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h4"
                      style={{ minWidth: 55 }}
                    >
                      募集科目
                    </Typography>
                    <Typography component="div" variant="h5">
                      消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科
                    </Typography>
                  </div>
                  <Divider style={{ margin: "8px auto" }} />

                  <Typography component="span" variant="h4">
                    職務内容
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" 職務内容"}
                  </Typography>
                  <Divider style={{ margin: "8px auto" }} />

                  <Typography component="span" variant="h4">
                    給与
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" 給与"}
                  </Typography>
                  <Divider style={{ margin: "8px auto" }} />

                  <Typography component="span" variant="h4">
                    勤務日時
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" 勤務日時"}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => history.push(`/doctor/jobs/info/${i}`)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </JobsListItem>
        ))}
      </List>

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
