//募集要項ー編集
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import MedSearchDepartmentAccordion from "../../../../../components/common/MedSearchDepartmentAccordion";
import MedSearchJapanCityDialog from "../../../../../components/MedSearchJapanCityDialog";
import MedSearchMedicalDepartmentDialog from "../../../../../components/MedSearchMedicalDepartmentDialog";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
}));

export default function Step3() {
  const classes = useStyles();

  // const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const [genderDesignation, setGenderDesignation] = useState("no");
  const [isJapanCityDialogOpen, setIsJapanCityDialogOpen] = useState(false);
  const [isMedicalDepartmentDialogOpen, setIsMedicalDepartmentDialogOpen] =
    useState(false);
  //已選的城市清單
  const [selectedCityList, setSelectedCityList] = useState([]);
  //已選的科別清單
  const [selectedDeparmentList, setSelectedDeparmentList] = useState([]);

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: "3%" }}>
        必須入力＊
      </Typography>
      <TextField
        label="募集科目 (必須)"
        required
        value={selectedDeparmentList}
        style={{ backgroundColor: "white", width: "100%", marginBottom: "3%" }}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => setIsMedicalDepartmentDialogOpen(true)}
      />
      <TextField
        label="居住地域 (必須)"
        required
        value={selectedCityList}
        style={{ backgroundColor: "white", width: "100%", marginBottom: "3%" }}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => setIsJapanCityDialogOpen(true)}
      />

      <MedSearchJapanCityDialog
        isJapanCityDialogOpen={isJapanCityDialogOpen}
        setIsJapanCityDialogOpen={setIsJapanCityDialogOpen}
        selectedCityList={selectedCityList}
        setSelectedCityList={setSelectedCityList}
      />
      <MedSearchMedicalDepartmentDialog
        isMedicalDepartmentDialogOpen={isMedicalDepartmentDialogOpen}
        setIsMedicalDepartmentDialogOpen={setIsMedicalDepartmentDialogOpen}
        setSelectedDeparmentList={setSelectedDeparmentList}
      />

      <Typography variant="h6" style={{ margin: "3% 0px" }}>
        任意入力
      </Typography>
      <TextField
        label="スキル・経験"
        variant="standard"
        placeholder="例：必要手技、専門医資格、臨床経験年数など"
        className={classes.textFiled}
        style={{ marginBottom: "3%" }}
      />

      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">性別指定</FormLabel>
          <RadioGroup
            value={genderDesignation}
            onChange={(e) => setGenderDesignation(e.target.value)}
            style={{ flexDirection: "row" }}
          >
            <FormControlLabel value="no" control={<Radio />} label="指定なし" />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="女性限定"
            />
            <FormControlLabel
              value="male"
              control={<Radio />}
              label="男性限定"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}
