import { useState, useEffect } from "react";

export const useScrollYPosition = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0);
  useEffect(() => {
    const updateYPosition = () => {
      setScrollYPosition(window.scrollY);
    };
    window.addEventListener("scroll", updateYPosition);
    updateYPosition();
    return () => {
      window.removeEventListener("scroll", updateYPosition);
    };
  }, []);
  return scrollYPosition;
};
