//出勤・給与の確認
import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CommutingAndPayList from "./CommutingAndPayList";

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: "3% auto",
    maxWidth: "fit-content",
    width: "100%",
  },
}));

export default function CommutingAndPayIndex() {
  return (
    <div>
      <Typography variant="h1">出勤・給与の確認</Typography>

      <CommutingAndPayList />
    </div>
  );
}
