import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";

import _ from "lodash";
import { MedSearchJapanCityList } from "../assets/data/MedSearchJapanCityList";
import { MedSearchColors } from "../assets/theme";
import { withStyles } from "@material-ui/styles";

export default function MedSearchJapanCityDialogV2({
  isJapanCityDialogOpen,
  setIsJapanCityDialogOpen,
  selectedCityList,
  setSelectedCityList,
  japanCityList,
  setJapanCityList,
}) {
  const japanCheckedRegionObject = {};

  //從MedSearchJapanCityList取得資料並於useEffect中加入checked欄位以進行選取判斷
  // const [japanCityList, setJapanCityList] = useState({});
  const [isHokkaidoChecked, setIsHokkaidoChecked] = useState(false); //北海道 的checkbox是否點選
  const [isTohokuChecked, setIsTohokuChecked] = useState(false); //東北 的checkbox是否點選
  const [isKantoChecked, setIsKantoChecked] = useState(false); //關東 的checkbox是否點選
  const [isHokushinetsuChecked, setIsHokushinetsuChecked] = useState(false); //北信越 的checkbox是否點選
  const [isTokaiChecked, setIsTokaiChecked] = useState(false); //東海 的checkbox是否點選
  const [isKinkiChecked, setIsKinkiChecked] = useState(false); //近畿 的checkbox是否點選 的checkbox是否點選
  const [isChugokuChecked, setIsChugokuChecked] = useState(false); //中国 的checkbox是否點選 的checkbox是否點選
  const [isShikokuChecked, setisShikokuChecked] = useState(false); //四国 的checkbox是否點選 的checkbox是否點選
  const [isKyushuChecked, setIsKyushuChecked] = useState(false); //九州・沖縄 的checkbox是否點選 的checkbox是否點選
  japanCheckedRegionObject["北海道"] = isHokkaidoChecked;
  japanCheckedRegionObject["東北"] = isTohokuChecked;
  japanCheckedRegionObject["関東"] = isKantoChecked;
  japanCheckedRegionObject["北信越"] = isHokushinetsuChecked;
  japanCheckedRegionObject["東海"] = isTokaiChecked;
  japanCheckedRegionObject["近畿"] = isKinkiChecked;
  japanCheckedRegionObject["中国"] = isChugokuChecked;
  japanCheckedRegionObject["四国"] = isShikokuChecked;
  japanCheckedRegionObject["九州・沖縄"] = isKyushuChecked;

  useEffect(() => {
    //從MedSearchJapanCityList取得資料並於useEffect中加入checked欄位以進行選取判斷
    const tempList = MedSearchJapanCityList.map((city) => ({
      ...city,
      checked: false,
    }));
    setJapanCityList(_.groupBy(tempList, "region"));
  }, []);

  const RegionCheckbox = withStyles({
    root: { color: "white", padding: 5 },
    colorPrimary: {
      color: "white",
      "&.Mui-checked": {
        color: "white",
      },
    },
  })(Checkbox);

  // 選項有變更時，更新選擇的清單以供父組件顯示
  useEffect(() => {
    let tempSelectedList = [];
    let tempSelectedCityList = [];
    Object.entries(japanCityList).map(([region, cityList]) => {
      let tempList = [];
      tempList = cityList.filter((city) => city.checked === true);
      if (tempList.length > 0) tempSelectedList.push(tempList);
    });
    tempSelectedList.map((selectedCity) => {
      selectedCity.map((city) => tempSelectedCityList.push(city.city));
    });
    setSelectedCityList(tempSelectedCityList);
  }, [japanCityList]);

  //當使用者點選Region(關東…等)會全選該Region的所有city(東京都、神奈川県、埼玉県…等)
  const handleCheckedRegion = (region, e) => {
    //更新所選地區的 每個 city 的checkbox --- start
    const tempJapanCityList = japanCityList;
    tempJapanCityList[region] = tempJapanCityList[region].map((city) => ({
      ...city,
      checked: e.target.checked,
    }));
    setJapanCityList({ ...tempJapanCityList });
    //更新所選地區的 每個 city 的checkbox--- end

    //更新所選地區的checkbox --- start
    switch (region) {
      case "北海道":
        setIsHokkaidoChecked(e.target.checked);
        break;
      case "東北":
        setIsTohokuChecked(e.target.checked);
        break;
      case "関東":
        setIsKantoChecked(e.target.checked);
        break;
      case "北信越":
        setIsHokushinetsuChecked(e.target.checked);
        break;
      case "東海":
        setIsTokaiChecked(e.target.checked);
        break;
      case "近畿":
        setIsKinkiChecked(e.target.checked);
        break;
      case "中国":
        setIsChugokuChecked(e.target.checked);
        break;
      case "四国":
        setisShikokuChecked(e.target.checked);
        break;
      case "九州・沖縄":
        setIsKyushuChecked(e.target.checked);
        break;
      default:
        break;
    } //更新所選地區的checkbox --- end
  };

  const handleCheckedCity = (region, cityId, e) => {
    const tempJapanCityList = japanCityList;
    tempJapanCityList[region] = japanCityList[region].map((city) => ({
      ...city,
      checked:
        city.city_id.toString() === cityId.toString()
          ? e.target.checked
          : city.checked,
    }));
    setJapanCityList({ ...tempJapanCityList });
  };

  //清除所有已選項目
  const clearAllChecked = () => {
    setIsHokkaidoChecked(false); //北海道 的checkbox是否點選
    setIsTohokuChecked(false); //東北 的checkbox是否點選
    setIsKantoChecked(false); //關東 的checkbox是否點選
    setIsHokushinetsuChecked(false); //北信越 的checkbox是否點選
    setIsTokaiChecked(false); //東海 的checkbox是否點選
    setIsKinkiChecked(false); //近畿 的checkbox是否點選 的checkbox是否點選
    setIsChugokuChecked(false); //中国 的checkbox是否點選 的checkbox是否點選
    setisShikokuChecked(false); //四国 的checkbox是否點選 的checkbox是否點選
    setIsKyushuChecked(false); //九州・沖縄 的checkbox是否點選 的checkbox是否點選

    const tempObjs = {};
    Object.entries(japanCityList).map(([region, cityList]) => {
      let tempList = cityList.map((city) => ({ ...city, checked: false }));
      tempObjs[region] = tempList;
    });

    setJapanCityList({ ...tempObjs });
  };

  return (
    <div>
      <Dialog
        open={isJapanCityDialogOpen}
        onClose={() => setIsJapanCityDialogOpen(false)}
        maxWidth="lg"
      >
        <DialogTitle
          disableTypography={true}
          style={{
            fontWeight: 800,
            fontSize: 18,
            color: MedSearchColors.dark03dark,
          }}
        >
          {"都道府県を選ぶ"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            alignContent="center"
          >
            {Object.entries(japanCityList).map(([region, cityList]) => (
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel
                    style={{
                      borderLeft: `8px solid ${MedSearchColors.xy}`,
                      backgroundColor: MedSearchColors.secondary,
                      color: "white",
                    }}
                  >
                    <FormControlLabel
                      key={region}
                      control={
                        <RegionCheckbox
                          size="small"
                          color="primary"
                          checked={japanCheckedRegionObject[region]}
                          onChange={(e) => handleCheckedRegion(region, e)}
                        />
                      }
                      label={region}
                      labelPlacement="start"
                    />
                  </FormLabel>

                  <FormGroup style={{ flexDirection: "row" }}>
                    {cityList.map((city) => (
                      <FormControlLabel
                        key={city.city_id}
                        control={
                          <Checkbox
                            disabled={japanCheckedRegionObject[region]}
                            size="small"
                            checked={city.checked}
                            onChange={(e) =>
                              handleCheckedCity(region, city.city_id, e)
                            }
                          />
                        }
                        label={
                          <span style={{ marginRight: 12, fontSize: 14 }}>
                            {city.city}
                          </span>
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => setIsJapanCityDialogOpen(false)}
            color="primary"
          >
            完成
          </Button>
          <Button
            style={{ color: MedSearchColors.zred }}
            onClick={clearAllChecked}
          >
            クリア
          </Button>
          <Button
            onClick={() => setIsJapanCityDialogOpen(false)}
            color="default"
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
