export const applicantList = [
  {
    id: 1,
    name: "織田信永",
    statusContent: "採用",
    status: 0,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:30",
  },
  {
    id: 1,
    name: "織田信永",
    statusContent: "キャンセル",
    status: 1,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:31",
  },
  {
    id: 1,
    name: "織田信永",
    statusContent: "検討中",
    status: 2,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:32",
  },
  {
    id: 1,
    name: "織田信永",
    statusContent: "交渉中",
    status: 3,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:33",
  },
  {
    id: 1,
    name: "織田信永",
    statusContent: "最終確認待ち",
    status: 4,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:34",
  },
];
