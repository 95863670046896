//よくある質問 Q &A
import React from "react";
import {
  Typography,
  makeStyles,
  withStyles,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { MedSearchColors } from "../../../assets/theme";
import { ReactComponent as IconRactQ } from "../../../assets/images/icon_ract_Q.svg";

const useStyles = makeStyles((theme) => ({
  listBox: {
    backgroundColor: MedSearchColors.bgcard,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: 20,
  },
  btnWrap: {
    textAlign: "right",
  },
}));

const InfoListItem = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    marginBottom: 8,
  },
}))(ListItem);

const doctorQAList = [
  { id: 1, title: "サービスの内容と特徴は？" },
  { id: 2, title: "利用料はかかりますか？" },
  { id: 3, title: "サービスの利用手順は？" },
  { id: 4, title: "ポイント還元とその用途は？" },
  { id: 5, title: "スカウトとは？" },
  { id: 6, title: "好条件（高給与）の求人を獲得するには？" },
  { id: 7, title: "スポット、非常勤、常勤とはどのような勤務形態ですか？" },
];

function QandAList() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1">よくある質問 Q &A</Typography>
      <div className={classes.listBox}>
        <Typography variant="h2">勤務医Q &A</Typography>
        <List component="nav" aria-label="secondary mailbox folders">
          {doctorQAList.map((item) => (
            <InfoListItem button key={item.id}>
              <ListItemAvatar>
                <Avatar variant="square">
                  <IconRactQ />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                // secondary="2021-01-14 18:30"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </InfoListItem>
          ))}
        </List>
        {/* <Typography variant="h2">雇用主Q &A</Typography>
        <List component="nav" aria-label="secondary mailbox folders">
          {employerList.map((item) => (
            <InfoListItem button key={item.id}>
              <ListItemAvatar>
                <Avatar variant="square">
                  <IconRactQ />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                // secondary="2021-01-14 18:30"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </InfoListItem>
          ))}
        </List> */}
        <div className={classes.btnWrap}>
          <Button variant="text" color="default" endIcon={<ChevronRightIcon />}>
            その他の質問
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QandAList;
