import React from "react";
import {
  makeStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MedSearchColors } from "../../assets/theme";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const MedSearchAccordion = withStyles({
  root: {
    boxShadow: "none",
    backgroundColor: MedSearchColors.dark05lightgray,
    marginBottom: 8,
  },
})(Accordion);

const MedSearchAccordionSummary = withStyles({
  root: {
    color: MedSearchColors.dark04gray,
    border: `solid 1px ${MedSearchColors.dark04gray}`,
    backgroundColor: MedSearchColors.white,
    minHeight: 28,
    height: 26,
    "&.Mui-expanded": {
      minHeight: 36,
    },
  },
})(AccordionSummary);

const MedSearchAccordionDetails = withStyles({
  root: {
    color: MedSearchColors.dark03dark,
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0 5px",
  },
})(AccordionDetails);

export default function MedSearchSecondaryAccordion(props) {
  const { HeaderTitle, isExpanded, children, handleCheckedCategory } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MedSearchAccordion defaultExpanded={isExpanded}>
        <MedSearchAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                size="small"
                onClick={(e) => handleCheckedCategory(HeaderTitle, e)}
              />
            }
            label={
              <span style={{ fontSize: 20, color: MedSearchColors.dark01 }}>
                {HeaderTitle}
              </span>
            }
          />
        </MedSearchAccordionSummary>
        <MedSearchAccordionDetails>{children}</MedSearchAccordionDetails>
      </MedSearchAccordion>
    </div>
  );
}
