import React, { useState } from "react";
import { Typography, Button, IconButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { DropzoneDialog } from "material-ui-dropzone";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
  },
  uploadImgWrap: {
    maxWidth: 600,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("730")]: {
      flexDirection: "column",
      height: 510,
    },
    "& figure": {
      position: "relative",
      // width: "100%",
      width: 194,
      height: 120,
      [theme.breakpoints.down("730")]: {
        minWidth: 280,
        minHeight: 160,
      },
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  removeImgBtn: {
    position: "absolute",
    top: "3%",
    right: "2%",
    width: 38,
    height: 38,
  },
  uploadImgBtn: {
    width: 194,
    height: 120,
    [theme.breakpoints.down("730")]: {
      minWidth: 280,
      minHeight: 160,
    },
  },
  editTextField: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 30,
  },
  textFieldWrap: {
    display: "flex",
    maxWidth: 600,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  departmentWrap: {
    maxWidth: 630,
    marginBottom: 30,
  },
  uploadCertificateWrap: {
    "& figure": {
      position: "relative",
      width: 280,
      height: 180,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

export default function Certificate() {
  const classes = useStyles();

  const [uploadImg, setUploadImg] = useState(false);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">医師免許証（必須）</Typography>
          <figure>
            <img
              src="/assets/images/Demo/certificate.png"
              alt=""
              style={{ width: "100%", maxWidth: 380 }}
            />
            <IconButton
              style={{ background: "white" }}
              className={classes.removeImgBtn}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </figure>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">保険医登録票</Typography>
          <figure>
            <img
              src="/assets/images/Demo/certificate.png"
              alt=""
              style={{ width: "100%", maxWidth: 380 }}
            />
            <IconButton
              style={{ background: "white" }}
              className={classes.removeImgBtn}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </figure>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">臨床研修修了登録証</Typography>
          <Typography variant="h5">（2004年以降に医籍登録の方のみ）</Typography>
          <Button
            variant="contained"
            onClick={() => setUploadImg(true)}
            className={classes.uploadImgBtn}
          >
            <CameraAltIcon />
          </Button>
        </Grid>
      </Grid>

      <DropzoneDialog
        dialogTitle="Upload Image"
        open={uploadImg}
        onSave={(e) => setUploadImg(e)}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => setUploadImg(false)}
      />
    </>
  );
}
