import React from "react";
import { makeStyles } from "@material-ui/core";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import hospitalImg from "../../../../assets/images/Demo/hospital-1.png";
import { demoBasicInfoList, demoHospitalInfo } from "./DemoData";

const useStyles = makeStyles((theme) => ({
  imgSection: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  image: {
    width: "33%",
    [theme.breakpoints.down("xs")]: {
      width: "97%",
      marginBottom: "2%",
    },
  },
}));
export default function BasicInfoContent() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.imgSection}>
        <img src={hospitalImg} alt="" className={classes.image} />
        <img src={hospitalImg} alt="" className={classes.image} />
        <img src={hospitalImg} alt="" className={classes.image} />
      </div>

      <MedSearchInfoList title={"基本情報"} dataList={demoBasicInfoList} />
      <MedSearchInfoList title={"詳細情報"} dataList={demoHospitalInfo} />
    </>
  );
}
