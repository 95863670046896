//曜日を指定
import React, { useState } from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
} from "@material-ui/core";

const defaultSkillList = [
  { id: 1, title: "電子カルテ", checked: false },
  { id: 2, title: "縫合", checked: false },
  { id: 3, title: "挿管", checked: false },
  { id: 4, title: "胃ろう交換", checked: false },
  { id: 5, title: "バルーン交換", checked: false },
  { id: 6, title: "気切チューブ交換", checked: false },
  { id: 7, title: "CV管理", checked: false },
  { id: 8, title: "胃カメラ", checked: false },
  { id: 9, title: "大腸ファイバー", checked: false },
  { id: 10, title: "腹部エコー", checked: false },
  { id: 11, title: "心エコー", checked: false },
  { id: 12, title: "胸腔穿刺", checked: false },
  { id: 13, title: "その他", checked: false },
];

export default function Skill() {
  const [skillList, setSkillList] = useState(defaultSkillList);
  const selectSkill = (event, checkedSkill) => {
    const tempList = skillList.map((skill) =>
      skill.id === checkedSkill.id
        ? { ...skill, checked: event.target.checked }
        : skill
    );
    setSkillList([...tempList]);
  };
  return (
    <>
      <MedSearchPrimaryAccordion
        HeaderIcon={<EventAvailableIcon />}
        HeaderTitle="手技"
        isExpanded={false}
      >
        <FormControl component="fieldset">
          <FormGroup
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {skillList.map((skill) => (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={skill.checked}
                    onChange={(e) => selectSkill(e, skill)}
                    name={skill.title}
                  />
                }
                label={skill.title}
              />
            ))}
          </FormGroup>
        </FormControl>
        {skillList[12].checked && (
          <TextField label="その他" variant="outlined" size="small" />
        )}
      </MedSearchPrimaryAccordion>
    </>
  );
}
