import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import StarIcon from "@material-ui/icons/Star";
import {
  demoConfirmData1,
  demoConfirmData19,
  demoConfirmData2,
  demoConfirmData7,
} from "../Recruitment/Create/DemoData";
import MedSearchDialog from "../../../../components/common/MedSearchDialog";
import ConfirmForm from "./ConfirmForm";
import MedSearchDoctorProfileWithAccordion from "../../../../components/MedSearchDoctorProfileWithAccordion";

export default function CommutingAndPayInfo() {
  const [openDialog, setOpenDialog] = useState(false);
  const fabMenuList = [
    {
      id: 1,
      icon: <ChevronLeftIcon />,
      title: "戻　る",
      link: "/employer/management/cap",
    },
    {
      id: 2,
      icon: <StarIcon />,
      title: "出勤確認",
      click: () => setOpenDialog(true),
    },
  ];
  return (
    <>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">出勤・給与の確認 - 評価</Typography>
      <MedSearchInfoList title="勤務先情報" dataList={demoConfirmData1} />
      <MedSearchInfoList title="勤務内容" dataList={demoConfirmData2} />
      <MedSearchInfoList title="勤務時間" dataList={demoConfirmData19} />

      <MedSearchInfoList title="緊急連絡先" dataList={demoConfirmData7} />
      {/* <MedSearchInfoList title="勤務医情報" dataList={demoConfirmData8} /> */}
      <MedSearchDoctorProfileWithAccordion />
      <MedSearchDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"出勤確認"}
      >
        <ConfirmForm />
      </MedSearchDialog>
    </>
  );
}
