import React from "react";
import { makeStyles } from "@material-ui/core";
import { MedSearchColors } from "../../assets/theme";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
  TypesOfEmployment,
} from "../../assets/constant/TypesOfEmployment";
import MedSearchTabs from "../common/MedSearchTabs";
import FullTimeContent from "./FullTimeContent";
import FullTimeJobsFilter from "./FullTimeJobsFilter";
import PartTimeJobsFilter from "./PartTimeJobsFilter";
import SpotJobsFilter from "./SpotJobsFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 4,
    width: "100%",
    backgroundColor: MedSearchColors.dark05lightgray,
  },
}));

const tabsList = [
  {
    id: 0,
    title: TypesOfEmployment[FULL_TIME],
    content: <FullTimeJobsFilter />,
  },
  {
    id: 1,
    title: TypesOfEmployment[PART_TIME],
    content: <PartTimeJobsFilter />,
  },
  { id: 2, title: TypesOfEmployment[SPOT], content: <SpotJobsFilter /> },
];

export default function MedSearchFilterIndex() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MedSearchTabs tabsList={tabsList} />
    </div>
  );
}
