// 常勤、非常勤 - 勤務日時指定

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  FormGroup,
  Checkbox,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { MedSearchColors } from "../../../../../../assets/theme";
import { DefaultDaysWorkingTimeList } from "./DataList";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
  datetimeTitle: {
    marginBottom: "2%",
    marginTop: "6%",
    display: "flex",
    alignContent: "center",
    lineHeight: 2,
    fontSize: 16,
    fontWeight: 500,
    color: MedSearchColors.dark01,
  },
  blockWrap: {
    display: "flex",
    marginTop: "10%",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
  daysTimePickerWrap: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
    margin: "2% 0",
  },
}));

export default function WorkingDatetime() {
  const classes = useStyles();
  const [weekOption, setWeekOption] = useState(0);
  //weekOption 1 => 每週
  //weekOption 2 => 指定 （可指定每月的哪幾週）
  //weekOption 3 => その他 （提供輸入欄位 自由輸入）
  const [daysWorkingTimeList, setDaysWorkingTimeList] = useState(
    DefaultDaysWorkingTimeList
  );

  const [specifyWeeks, setSpecifyWeeks] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  const handleSpecifyWeeksChange = (event) => {
    setSpecifyWeeks({
      ...specifyWeeks,
      [event.target.name]: event.target.checked,
    });
  };
  const { first, second, third, fourth, fifth } = specifyWeeks;

  const handleDaysChange = (event, changeDay) => {
    const tempList = daysWorkingTimeList.map((day) =>
      day.id === changeDay.id ? { ...day, checked: event.target.checked } : day
    );
    setDaysWorkingTimeList([...tempList]);
  };

  const [startOfDuty, setStartOfDuty] = useState(1);
  const startOfDutyList = [
    { id: 1, text: "応相談" },
    { id: 2, text: "随時" },
    { id: 3, text: "時期指定" },
    { id: 4, text: "その他" },
  ];
  const [startDateOfDuty, setStartDateOfDuty] = useState(null);

  const setBeginTime = (time, changeDay) => {
    const tempList = daysWorkingTimeList.map((day) =>
      day.id === changeDay.id ? { ...day, beginTime: time } : day
    );
    setDaysWorkingTimeList([...tempList]);
  };
  const setEndTime = (time, changeDay) => {
    const tempList = daysWorkingTimeList.map((day) =>
      day.id === changeDay.id ? { ...day, endTime: time } : day
    );
    setDaysWorkingTimeList([...tempList]);
  };

  return (
    <div>
      <div className={classes.datetimeTitle}>
        <DateRangeIcon />
        勤務日時指定
      </div>

      <FormControl component="fieldset">
        <FormLabel component="legend" labelPlacement="start">
          週
        </FormLabel>
        <RadioGroup
          value={weekOption}
          onChange={(e) => setWeekOption(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="0" control={<Radio />} label="毎週" />
          {/* 想自選 每個月的哪幾週 */}
          <FormControlLabel value="1" control={<Radio />} label="指定" />
          <FormControlLabel value="2" control={<Radio />} label="その他" />
        </RadioGroup>
      </FormControl>

      <br />

      {/* 可自選 每個月的哪幾週 */}
      {weekOption === "1" ? (
        <FormControl component="fieldset">
          <FormLabel component="legend">Weeks</FormLabel>
          <FormGroup style={{ flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={first}
                  onChange={handleSpecifyWeeksChange}
                  name="first"
                />
              }
              label="1"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={second}
                  onChange={handleSpecifyWeeksChange}
                  name="second"
                />
              }
              label="2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={third}
                  onChange={handleSpecifyWeeksChange}
                  name="third"
                />
              }
              label="3"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={fourth}
                  onChange={handleSpecifyWeeksChange}
                  name="fourth"
                />
              }
              label="4"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={fifth}
                  onChange={handleSpecifyWeeksChange}
                  name="fifth"
                />
              }
              label="5"
            />
          </FormGroup>
        </FormControl>
      ) : null}

      {/* 可自行輸入*/}
      {weekOption === "2" ? <TextField label="自由入力" /> : null}

      {/* <FormControl component="fieldset" style={{ marginTop: "6%" }}>
        <FormLabel component="legend">曜日</FormLabel>
        <FormGroup style={{ flexDirection: "row" }}>
          {daysList.map((day) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={day.checked}
                  onChange={day.change}
                  name={day.name}
                />
              }
              label={day.day}
            />
          ))}
        </FormGroup>
      </FormControl> */}

      {/* <div
        style={{
          fontWeight: 400,
          fontSize: 20,
          color: "rgba(0, 0, 0, 0.54)",
          marginTop: "3%",
          marginBottom: 0,
        }}
      >
        時
      </div>
      <TimePicker
        clearable
        ampm={false}
        label="開始時間"
        cancelLabel="削除"
        clearLabel="キャンセル"
        okLabel="完了"
        value={firstStartTime}
        onChange={setFirstStartTime}
        style={{ marginRight: 16 }}
      />
      <TimePicker
        clearable
        ampm={false}
        label="終了時間"
        cancelLabel="削除"
        clearLabel="キャンセル"
        okLabel="完了"
        value={firstEndTime}
        onChange={setFirstEndTime}
      /> */}

      <FormControl component="fieldset" style={{ marginTop: "6%" }}>
        <FormLabel component="legend">曜日</FormLabel>
        <FormGroup style={{ flexDirection: "row" }}>
          {daysWorkingTimeList.map((day) => (
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={day.checked}
                  onChange={(e) => handleDaysChange(e, day)}
                  name={day.name}
                />
              }
              label={day.day}
            />
          ))}
        </FormGroup>
      </FormControl>
      {daysWorkingTimeList.map((day) =>
        !day.checked ? null : (
          <div className={classes.daysTimePickerWrap}>
            <Typography variant="h3" style={{ marginRight: 12 }}>
              {day.day}
            </Typography>
            <TimePicker
              clearable
              ampm={false}
              label="開始時間"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
              value={day.beginTime}
              onChange={(selectedTime) => setBeginTime(selectedTime, day)}
              style={{ marginRight: 16 }}
            />
            <TimePicker
              clearable
              ampm={false}
              label="終了時間"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
              value={day.endTime}
              onChange={(selectedTime) => setEndTime(selectedTime, day)}
            />
          </div>
        )
      )}
      <div style={{ marginTop: "6%" }}>
        <TextField
          select
          label="勤務開始時期"
          value={startOfDuty}
          onChange={(e) => setStartOfDuty(e.target.value)}
          style={{ marginRight: 18, minWidth: 200 }}
        >
          {startOfDutyList.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>

        {/* 設定 指定時間 */}
        {startOfDuty === 3 ? (
          <DatePicker
            clearable
            format="yyyy/MM/dd"
            value={startDateOfDuty}
            onChange={setStartDateOfDuty}
            label="開始日"
            cancelLabel="削除"
            clearLabel="キャンセル"
            okLabel="完了"
          />
        ) : null}

        {/* 自行輸入 */}
        {startOfDuty === 4 ? <TextField label="自由入力" /> : null}
      </div>
    </div>
  );
}
