import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Typography,
  Avatar,
  Button,
} from "@material-ui/core";
import MedSearchList from "../../../../components/common/MedSearchList";
import { useHistory } from "react-router-dom";

import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { applicantList } from "./DemoList";
import { MedSearchPagination } from "../../../../components/common/MedSearchPagination";
import { ReactComponent as IconHospital } from "../../../../assets/images/icon/icon_hospital.svg";

const useStyles = makeStyles((theme) => ({
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  contentWrap: {
    [theme.breakpoints.down("sm")]: {
      margin: "3% 0",
    },
  },
}));

export default function NotificationList() {
  const classes = useStyles();
  const [chronological, setChronological] = useState(false);
  const history = useHistory();

  return (
    <div>
      <div className={classes.infoWrap}>
        <Typography variant="h2">合計 341 件ＯＯＯＯＯＯＯＯＯＯ</Typography>
        <Button
          variant="text"
          onClick={() => setChronological(!chronological)}
          endIcon={chronological ? <ExpandLess /> : <ExpandMore />}
        >
          時間
        </Button>
      </div>

      {applicantList.map((item) => (
        <MedSearchList key={item.id}>
          <Avatar>
            <IconHospital />
          </Avatar>

          <div className={classes.contentWrap}>
            <Typography variant="h3">{item.title}</Typography>
            <Typography variant="h4">{item.subtitle}</Typography>
          </div>
          <span>{item.name}</span>
          <div className={classes.btnWrap}>
            <IconButton
              title="View"
              onClick={() =>
                history.push("/employer/management/notification/info")
              }
            >
              <DescriptionOutlinedIcon color="default" />
            </IconButton>
            <IconButton title="メッセージ">
              <MessageOutlinedIcon />
            </IconButton>
          </div>
        </MedSearchList>
      ))}

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
