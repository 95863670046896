import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 75,
    [theme.breakpoints.down("xs")]: {
      padding: 26,
    },
  },
  slider: {
    "& .slick-slide>div": {
      padding: "0 1%",
    },
  },
  img: {
    maxWidth: 410,
    height: "auto",
    width: "100%",
  },
}));

function RecommendedInfo() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.root}>
      <Typography variant="h1">おすすめ情報</Typography>
      <Slider {...settings} className={classes.slider}>
        <img
          src="assets/images/img_index_ad01.jpg"
          alt=""
          className={classes.img}
        />

        <img
          src="assets/images/img_index_ad02.jpg"
          alt=""
          className={classes.img}
        />
        <img
          src="assets/images/img_index_ad03.jpg"
          alt=""
          className={classes.img}
        />
        <img
          src="assets/images/img_index_ad04.jpg"
          alt=""
          className={classes.img}
        />
      </Slider>
    </div>
  );
}

export default RecommendedInfo;
