import React, { useState } from "react";
import { makeStyles, AppBar, Tabs, Tab, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { MedSearchColors } from "../../assets/theme";

function MedSearchTabpanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{ backgroundColor: "white", paddingTop: 6, paddingLeft: 0 }}
    >
      {value === index && children}
    </div>
  );
}

MedSearchTabpanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: MedSearchColors.dark05lightgray,
  },
  selectedTab: {
    color: MedSearchColors.primary,
  },
}));

const MedSearchAppBar = withStyles({
  // root: { width: "100%" },
})(AppBar);
const MSTabs = withStyles({
  root: {
    color: MedSearchColors.dark04gray,
    backgroundColor: MedSearchColors.white,
  },
})(Tabs);
const MedSearchTab = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
  },
})(Tab);

export default function MedSearchTabs({ tabsList }) {
  const classes = useStyles();
  const [selectedTabIndex, setselectedTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setselectedTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <MedSearchAppBar position="static">
        <MSTabs
          value={selectedTabIndex}
          onChange={handleChange}
          variant="fullWidth"
        >
          {tabsList.map((item) => (
            <MedSearchTab
              key={item.id}
              label={item.title}
              className={
                item.id === selectedTabIndex ? classes.selectedTab : null
              }
            />
          ))}
        </MSTabs>
      </MedSearchAppBar>
      {tabsList.map((item) => (
        <MedSearchTabpanel value={selectedTabIndex} index={item.id}>
          {item.content}
        </MedSearchTabpanel>
      ))}
    </div>
  );
}
