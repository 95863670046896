import { makeStyles, Checkbox, withStyles } from "@material-ui/core";
import React from "react";
import { MedSearchColors } from "../../assets/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 4,
    color: "white",
    backgroundColor: MedSearchColors.dark04gray,
    minHeight: 26,
    height: 38,
    fontSize: 15,
    fontWeight: 400,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > .MuiSvgIcon-root": {
      marginLeft: 16,
      marginRight: 8,
    },
  },
}));

const MedSearchWhiteCheckbox = withStyles({
  root: {
    color: "white",
  },
  checked: {
    color: "white !important",
  },
})(Checkbox);
export default function MedSearchFilterCheckBar({
  title,
  icon,
  isChecked,
  setIsChecked,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {icon}
      {title}
      <MedSearchWhiteCheckbox
        color="secondary"
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </div>
  );
}
