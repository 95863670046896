import { Typography } from "@material-ui/core";
import React from "react";

import MedSearchFab from "../../../../components/common/MedSearchFab";
import ChatIcon from "@material-ui/icons/Chat";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MedSearchDoctorProfile2 from "../../../../components/MedSearchDoctorProfile2";

const fabMenuList = [
  {
    id: 1,
    icon: <ChatIcon />,
    title: "スカウト開始 (チャット)",
    link: "",
  },
  {
    id: 2,
    icon: <DeleteForeverOutlinedIcon />,
    title: "削除",
    link: "",
  },
];

export default function DoctorInfo() {
  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">お気に入り医師の詳細</Typography>
      <MedSearchDoctorProfile2 />
    </div>
  );
}
