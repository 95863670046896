//土日勤務
import React, { useState } from "react";
import EventIcon from "@material-ui/icons/Event";
import MedSearchFilterCheckBar from "../common/MedSearchFilterCheckBar";

function WorkOnSatAndSun() {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <MedSearchFilterCheckBar
        title="土日勤務なし"
        icon={<EventIcon />}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    </>
  );
}

export default WorkOnSatAndSun;
