import React, { useState } from "react";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const fabMenuList = [
  {
    id: 1,
    icon: <ChevronLeftIcon />,
    title: "戻る",
    link: "/employer/management/invoice",
  },
];
export default function ReviewPDF() {
  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      {/* <embed
        src="/assets/files/Invoice-1.pdf"
        width="200px"
        height="200px"
        // style={{ width: 300, height: 300 }}
      /> */}
      <iframe
        title="reviewpdf"
        src="/assets/files/Invoice-1.pdf"
        style={{ width: "100%", height: "100vh", border: "none" }}
      ></iframe>
    </div>
  );
}
