import React, { Fragment, useState } from "react";
import {
  Avatar,
  Badge,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { DataGridPro, GridToolbar, jaJP } from "@mui/x-data-grid-pro";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
  TypesOfEmployment,
} from "../../../assets/constant/TypesOfEmployment";

export default function FavoriteIndex() {
  const rows = [
    {
      id: 1,
      avatar: "",
      col1: "東名厚木病院",
      col2: "神奈川県　厚木市",
      col3: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
      col4: "職務内容",
      col5: "5,000",
      col6: "2022-01-01",
      col7: FULL_TIME,
      col8: "2022-01-01",
    },
    {
      id: 2,
      avatar: "",
      col1: "東名厚木病院",
      col2: "神奈川県　厚木市",
      col3: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
      col4: "職務内容",
      col5: "5,000",
      col6: "2022-01-01",
      col7: SPOT,
    },
    {
      id: 3,
      avatar: "",
      col1: "東名厚木病院",
      col2: "神奈川県　厚木市",
      col3: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
      col4: "職務内容",
      col5: "5,000",
      col6: "2022-01-01",
      col7: FULL_TIME,
    },
    {
      id: 4,
      avatar: "",
      col1: "東名厚木病院",
      col2: "神奈川県　厚木市",
      col3: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
      col4: "職務内容",
      col5: "5,000",
      col6: "2022-01-01",
      col7: PART_TIME,
    },
    {
      id: 5,
      avatar: "",
      col1: "東名厚木病院",
      col2: "神奈川県　厚木市",
      col3: "消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科",
      col4: "職務内容",
      col5: "5,000",
      col6: "2022-01-01",
      col7: FULL_TIME,
    },
  ];
  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 80,
      renderCell: (rowData) => <Avatar alt="Doctor" src={rowData.row.avatar} />,
    },

    { field: "col1", headerName: "病院名", width: 150 },
    { field: "col2", headerName: "勤務地", width: 150 },
    { field: "col3", headerName: "募集科目", width: 200 },
    {
      field: "col7",
      headerName: "タイプ ",
      width: 80,
      renderCell: (rowData) => TypesOfEmployment[rowData.row.col7],
    },

    { field: "col4", headerName: "職務内容", width: 160 },
    {
      field: "col5",
      headerName: "給与 ",
      width: 120,
      renderCell: (rowData) =>
        rowData.row.col7 === SPOT
          ? `${rowData.row.col5}円`
          : `${rowData.row.col5}万円`,
    },
    { field: "col6", headerName: "勤務日時 ", width: 120 },

    {
      field: "col8",
      headerName: " ",
      width: 300,
      renderCell: (rowData) => (
        <Fragment>
          <IconButton title="求人詳細">
            <DescriptionOutlinedIcon color="default" />
          </IconButton>
          <IconButton title="応募">
            <ContactMailOutlinedIcon color="default" />
          </IconButton>
          <IconButton title="チャット">
            <ForumOutlinedIcon />
          </IconButton>
          <IconButton>
            <FavoriteBorderOutlinedIcon />
          </IconButton>
        </Fragment>
      ),
    },
  ];
  const theme = createTheme(jaJP);

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h1">お気に入り</Typography>

      <Typography variant="h2">
        合計 {rows.length} 件ＯＯＯＯＯＯＯＯＯＯ
      </Typography>
      <DataGridPro
        components={{ Toolbar: GridToolbar }}
        rows={rows}
        columns={columns}
        pagination
        autoHeight
      />
    </ThemeProvider>
  );
}
