import React from "react";
import { makeStyles } from "@material-ui/core";

import { MedSearchColors } from "../../assets/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "16px 20px",
    marginBottom: 10,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 8,
    },
    "&:hover": {
      backgroundColor: MedSearchColors.primary010,
    },
  },
}));

export default function MedSearchList({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
