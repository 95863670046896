import React from "react";

import { makeStyles, Grid, Typography } from "@material-ui/core";
import { MedSearchColors } from "../../assets/theme";
import MedSearchPrimaryAccordion from "../../components/common/MedSearchPrimaryAccordion";
import { ReactComponent as IconLocal } from "../../assets/images/icon_collapse/local.svg";
import MedSearchFilterIndex from "../../components/MedSearchFilter/MedSearchFilterIndex";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    maxWidth: 1290,
    margin: "0 auto",
    marginTop: 20,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
}));

function CommonFilter() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1" style={{ marginLeft: 13 }}>
        詳細求人検索
      </Typography>
      <Typography variant="h2" style={{ marginLeft: 18 }}>
        常勤・非常勤・アルバイト・健診の医師求人情報が検索できます。
      </Typography>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        alignContent="center"
      >
        <Grid item xs={12} md={6}>
          <MedSearchPrimaryAccordion
            HeaderIcon={<IconLocal />}
            HeaderTitle="希望勤務地"
            isExpanded={true}
          >
            <img
              src="assets/images/japan_map/img_japan_map_bg.png"
              alt=""
              width="100%"
              height="auto"
              maxWidth="520px"
            />
          </MedSearchPrimaryAccordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <MedSearchFilterIndex />
        </Grid>
      </Grid>
    </div>
  );
}

export default CommonFilter;
