//アカウント設定

import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import { MedSearchColors } from "../../../../assets/theme";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 480,
    height: 530,
  },
  divider: {
    backgroundColor: MedSearchColors.primary,
    height: 2,
    margin: "5% 0",
  },
}));
const fabMenuList = [
  {
    id: 1,
    icon: <SaveOutlinedIcon />,
    title: "設定を更新",
    link: "/employer/management",
  },
  {
    id: 2,
    icon: <ReplyOutlinedIcon />,
    title: "デフォルト設定に戻す",
    link: "/employer/management",
  },
];

function AccountSettingsIndex() {
  const classes = useStyles();
  const [receiveNotification, setReceiveNotification] = useState(null);

  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />

      <Typography variant="h1">アカウント設定</Typography>
      <Typography variant="h6">管理者情報</Typography>
      <form action="" className={classes.form}>
        <TextField required label="Email" defaultValue="" />
        <TextField
          required
          label="パスワード"
          defaultValue=""
          type="password"
        />
        <TextField required label="名前" defaultValue="" />
        <TextField label="住所" defaultValue="" />
        <TextField required label="電話番号" defaultValue="" />

        <Divider className={classes.divider} />
        <Typography variant="h6">環境設定</Typography>

        <FormControl component="fieldset">
          <FormLabel component="legend">通知を受け取る</FormLabel>
          <RadioGroup
            value={receiveNotification}
            onChange={(e) => setReceiveNotification(e.target.value)}
            style={{ flexDirection: "row" }}
          >
            <FormControlLabel value="true" control={<Radio />} label="はい" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="しない"
            />
          </RadioGroup>
        </FormControl>
      </form>
    </div>
  );
}

export default AccountSettingsIndex;
