import React from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { ReactComponent as IconTime } from "../../assets/images/icon_collapse/time.svg";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@material-ui/core";

const dataList = [
  { id: 1, text: "２ 年未満" },
  { id: 2, text: "２〜５ 年" },
  { id: 3, text: "５〜10 年" },
  { id: 4, text: "10〜15 年" },
  { id: 5, text: "15 年以上" },
];
function MedSearchFilterExperience() {
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <MedSearchPrimaryAccordion
      HeaderIcon={<IconTime />}
      HeaderTitle="臨床経験年数"
      isExpanded={false}
    >
      <RadioGroup
        value={value}
        onChange={handleChange}
        style={{ flexDirection: "row", justifyContent: "space-around" }}
      >
        {dataList.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id.toString()}
            control={<Radio size="small" />}
            label={
              <Typography variant="h5" style={{ marginLeft: -4 }}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </MedSearchPrimaryAccordion>
  );
}

export default MedSearchFilterExperience;
