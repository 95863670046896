import {
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  withStyles,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
} from "../../assets/constant/TypesOfEmployment";
import { MedSearchColors } from "../../assets/theme";

const useStyles = makeStyles((theme) => ({
  tab: {
    color: MedSearchColors.dark01,
  },
  menuWrap: {
    display: "flex",
  },

  logo: {
    width: 300,
    height: "auto",
    transition: "0.3s",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 250,
    },
  },

  btnWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
    width: 215,
    [theme.breakpoints.down("1150")]: {
      display: "none",
    },
  },
  toggleMenuBtn: {
    display: "none",
    marginRight: 0,
    [theme.breakpoints.down("1150")]: {
      display: "initial",
    },
  },
}));

const HeaderAppBar = withStyles({
  root: {
    backgroundColor: "white",
    height: 80,
  },
})(AppBar);

const HeaderToolBar = withStyles({
  root: {
    width: "100%",
    height: "100%",
    maxWidth: 1440,
    justifyContent: "space-between",
    margin: "0 auto",
  },
})(Toolbar);

const HeaderTabs = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("1150")]: {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: MedSearchColors.primary,
  },
}))(Tabs);

const HeaderTab = withStyles({
  root: { minWidth: 50 },
})(Tab);

const menuList = {};

menuList["common"] = [
  { id: 0, title: "常勤求人", link: `/jobs/${FULL_TIME}` },
  { id: 1, title: "非常勤求人", link: `/jobs/${PART_TIME}` },
  { id: 2, title: "スポット求人", link: `/jobs/${SPOT}` },
  { id: 3, title: "ご利用方法", link: "/" },
  { id: 4, title: "採用者の方へ", link: "/employer" },
  { id: 6, title: "ログイン", link: "/" },
  { id: 7, title: "新規登録(無料)", link: "/" },
];

menuList["employer"] = [
  {
    id: 0,
    title: "サービスの特徴",
    link: { pathname: "/employer", hash: "#servicefeatures" },
  },
  { id: 1, title: "求人の手順", link: "#howtouse" },
  { id: 2, title: "医師を検索", link: "/employer/doctors" },
  { id: 3, title: "ご利用料金", link: "/employer" },
  { id: 4, title: "Ｑ&A", link: "#qanda" },
  { id: 5, title: "採用者ログイン", link: "/employer" },
  { id: 6, title: "新規登録(無料)", link: "/" },
];

function MedSearchHeader({ employerMode = false }) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(null);
  const [popMenuAnchor, setPopMenuAnchor] = useState(null);
  const history = useHistory();

  const handleToggleMenuClick = (event) => {
    setPopMenuAnchor(event.currentTarget);
  };
  const handlePopMenuClose = (event) => {
    setPopMenuAnchor(null);
  };
  const handleClickMenu = (linkpath) => {
    setPopMenuAnchor(null);
    history.push(linkpath);
  };

  return (
    <div>
      <HeaderAppBar position="fixed" color="primary">
        <HeaderToolBar>
          <img
            src="assets/images/logo-2x.png"
            alt="Doctors Space"
            className={classes.logo}
            onClick={() => history.push(employerMode ? "/employer" : "/")}
          />

          <div className={classes.menuWrap}>
            <HeaderTabs
              value={selectedTab}
              onChange={(e, i) => setSelectedTab(i)}
            >
              {menuList[employerMode ? "employer" : "common"].map((item) =>
                item.id !== 6 && item.id !== 7 ? (
                  <HeaderTab
                    key={item.id}
                    label={item.title}
                    className={classes.tab}
                    onClick={() => history.push(item.link)}
                  />
                ) : null
              )}
            </HeaderTabs>
            <div className={classes.btnWrap}>
              {!employerMode && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ height: 36, color: "white" }}
                >
                  ログイン
                </Button>
              )}

              <Button
                variant="contained"
                size="small"
                style={{
                  height: 36,
                  background: MedSearchColors.orange,
                  color: "white",
                  padding: "0 12px",
                }}
              >
                新規登録(無料)
              </Button>
            </div>

            <IconButton
              onClick={() =>
                history.push(employerMode ? "/employer/management" : "/doctor")
              }
            >
              <AccountCircle />
            </IconButton>
            <IconButton
              className={classes.toggleMenuBtn}
              onClick={handleToggleMenuClick}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </div>
        </HeaderToolBar>
      </HeaderAppBar>
      <Menu
        anchorEl={popMenuAnchor}
        keepMounted
        open={Boolean(popMenuAnchor)}
        onClose={handlePopMenuClose}
      >
        {menuList[employerMode ? "employer" : "common"].map((item) => (
          <MenuItem key={item.id} onClick={() => handleClickMenu(item.link)}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MedSearchHeader;
