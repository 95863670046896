import { Typography, makeStyles, Grid, Avatar } from "@material-ui/core";
import React, { useState } from "react";
import { MedSearchColors } from "../../../../assets/theme";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import BlockIcon from "@material-ui/icons/Block";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { demoConfirmData9 } from "../Recruitment/Create/DemoData";
import MedSearchStatusBox from "../../../../components/common/MedSearchStatusBox";
import { ReactComponent as IconHospital } from "../../../../assets/images/icon/icon_hospital.svg";
import { useLocation } from "react-router";
import {
  CANCELED,
  CONSIDERATION,
  RECRUITMENT,
  WAITING_CONFIRMATION,
} from "./ApplicantStatusConstant";
import { SCOUT, SPOT } from "../../../../assets/constant/TypesOfEmployment";
import MedSearchDoctorProfile2 from "../../../../components/MedSearchDoctorProfile2";

const useStyles = makeStyles((theme) => ({
  profile: {
    marginTop: 20,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: "16px 14px",
    margin: "30px auto",
    width: "100%",
  },
}));

export default function ApplicantInfo() {
  const classes = useStyles();
  const location = useLocation();
  const { applicant } = location.state;

  //在指定狀態下，「採用」btn 設為disable
  const approvedBtnDisableList = [RECRUITMENT, CANCELED, WAITING_CONFIRMATION];
  //在指定狀態下，「不採用」btn 設為disable
  const rejectedBtnDisableList = [RECRUITMENT, CANCELED, WAITING_CONFIRMATION];
  const fabMenuList = [];
  if (applicant.status === CONSIDERATION && applicant.employmentType !== SPOT)
    fabMenuList.push({
      id: 0,
      icon: <ForumOutlinedIcon />,
      title: "交渉継続",
      link: "/employer/management/applicant",
    });
  if (
    !approvedBtnDisableList.includes(applicant.status) &&
    applicant.employmentType !== SPOT
  )
    fabMenuList.push({
      id: 1,
      icon: <CheckOutlinedIcon />,
      title: "採　用",
      link: "/employer/management/applicant/approve",
    });
  if (!rejectedBtnDisableList.includes(applicant.status))
    fabMenuList.push({
      id: 2,
      icon: <BlockIcon />,
      title: "不採用",
      link: "/employer/management/applicant",
      // disabled: rejectedBtnDisableList.includes(applicant.status),
    });

  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">応募医師管理</Typography>
      <MedSearchStatusBox
        content={applicant.statusContent}
        colorIndex={applicant.status}
      />
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center"
        alignContent="center"
        style={{ marginTop: 0, marginBottom: 30, width: "100%" }}
      >
        <Grid item xs={12} md={4} lg={2}>
          <Avatar
            style={{ backgroundColor: "#a9dcf3", width: 100, height: 100 }}
          >
            <IconHospital style={{ width: 100, height: 100 }} />
          </Avatar>
        </Grid>

        <Grid item xs={12} md={8}>
          <MedSearchInfoList title="勤務先情報" dataList={demoConfirmData9} />
        </Grid>
      </Grid>

      {/* スカウト(SCOUT)  這比較像仲介、獵人頭的角色，不提供看太多細節資料 */}
      <MedSearchDoctorProfile2
        showMoreInfo={applicant.employmentType !== SCOUT ? true : false}
      />
    </div>
  );
}
