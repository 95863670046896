import {
  Typography,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState } from "react";
import { skillList } from "../../../../assets/data/SkillList";
import MedSearchAddressSetting from "../../../../components/common/MedSearchAddressSetting";
import DoctorSpaceCheckboxDialog from "../../../../components/DoctorSpaceCheckboxDialog";
import Skill from "../../../../components/MedSearchFilter/Skill";
import MedSearchMedicalDepartmentDialog from "../../../../components/MedSearchMedicalDepartmentDialog";
import MedSearchMedicalDepartmentDialogV2 from "../../../../components/MedSearchMedicalDepartmentDialogV2";

function BasicInfo() {
  const [gender, setGender] = useState("male");
  const [experience, setExperience] = useState(0);

  const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false);
  //已選的科別清單
  const [selectedDeparmentList, setSelectedDeparmentList] = useState([]);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6" style={{ margin: "30px 0" }}>
            基本情報
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="お名前" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="フリガナ" />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            label="生年月日"
            defaultValue="西暦〇年〇月〇日"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">性別</FormLabel>
            <RadioGroup
              row
              name="gender1"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel value="male" control={<Radio />} label="男" />
              <FormControlLabel value="female" control={<Radio />} label="女" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="携帯番号（固定電話でも可）" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="E-mail" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MedSearchAddressSetting />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="希望科目 (必須)"
            required
            value={selectedDeparmentList.toString()}
            onClick={() => setOpenDepartmentDialog(true)}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <MedSearchMedicalDepartmentDialogV2
            open={openDepartmentDialog}
            setOpen={setOpenDepartmentDialog}
            setSelectedDeparmentList={setSelectedDeparmentList}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">臨床経験年数</FormLabel>
            <RadioGroup
              row
              name="experience"
              value={experience}
              onChange={(e) => setExperience(parseInt(e.target.value))}
            >
              <FormControlLabel value={0} control={<Radio />} label="2年未満" />
              <FormControlLabel value={1} control={<Radio />} label="2〜5年" />
              <FormControlLabel value={2} control={<Radio />} label="5〜10年" />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="10〜15年"
              />
              <FormControlLabel value={4} control={<Radio />} label="15年" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <DoctorSpaceCheckboxDialog title={"手技"} dataList={skillList} />
        </Grid>
      </Grid>
    </>
  );
}

export default BasicInfo;
