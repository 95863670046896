import { Typography } from "@material-ui/core";
import React from "react";

import { demoBasicInfoList, demoHospitalInfo } from "./DemoData";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import EmployerPhotoGallery from "./EmployerPhotoGallery";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MedSearchFab from "../../../../components/common/MedSearchFab";

const fabMenuList = [
  {
    id: 1,
    icon: <EditIcon />,
    title: "編集",
    link: "/employer/management/profile/edit",
  },
  { id: 2, icon: <DeleteOutlineIcon />, title: "削除", link: "" },
  {
    id: 3,
    icon: <CloudDownloadOutlinedIcon />,
    title: "ダウンロード",
    link: "",
  },
];

function EmployerInfoIndex() {
  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />

      <Typography variant="h1">雇用主の情報設定</Typography>

      <EmployerPhotoGallery />
      <MedSearchInfoList title={"基本情報"} dataList={demoBasicInfoList} />
      <MedSearchInfoList title={"詳細情報"} dataList={demoHospitalInfo} />
      <Typography variant="h6" style={{ marginTop: "5%", marginBottom: "2%" }}>
        {"アクセス"}
      </Typography>
      <iframe
        title="hosptialmap"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3189143543545!2d139.69032371561218!3d35.69376893689084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d2aa55c5c85%3A0xa47b05cbe1ba1f94!2z5p2x5Lqs6Yar56eR5aSn5a246Yar6Zmi!5e0!3m2!1szh-TW!2stw!4v1633154703359!5m2!1szh-TW!2stw"
        style={{
          border: "2px solid #eeeeee",
          maxWidth: 600,
          minHeight: 350,
          width: "100%",
          height: "auto",
        }}
        allowfullscreen=""
        loading={"lazy"}
      ></iframe>
    </div>
  );
}

export default EmployerInfoIndex;
