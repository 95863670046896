import React, { useState } from "react";
import { Button, Chip } from "@material-ui/core";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { ReactComponent as IconLocal } from "../../assets/images/icon_collapse/local.svg";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import MedSearchJapanCityDialogV2 from "../MedSearchJapanCityDialogV2";

const useStyles = makeStyles((theme) => ({
  // contentWrap: {},
  cityChip: {
    margin: 5,
    backgroundColor: "white",
    padding: "6px 8px",
    borderRadius: 0,
  },
}));
export default function WorkRegion({ title = "希望勤務地" }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCityList, setSelectedCityList] = useState([]);
  const [japanCityList, setJapanCityList] = useState({});

  return (
    <>
      <MedSearchPrimaryAccordion
        HeaderIcon={<IconLocal />}
        HeaderTitle={title}
        isExpanded={true}
      >
        <div className={classes.contentWrap}>
          {Object.entries(japanCityList).map(([region, cityList]) =>
            cityList.map((city) =>
              city.checked ? (
                <Chip label={city.city} className={classes.cityChip} />
              ) : null
            )
          )}
        </div>
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          {title}
        </Button>
      </MedSearchPrimaryAccordion>
      <MedSearchJapanCityDialogV2
        isJapanCityDialogOpen={openDialog}
        setIsJapanCityDialogOpen={setOpenDialog}
        selectedCityList={selectedCityList}
        setSelectedCityList={setSelectedCityList}
        japanCityList={japanCityList}
        setJapanCityList={setJapanCityList}
      />
    </>
  );
}
