//時間帯指定
import React, { useState, useEffect } from "react";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import { TimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import * as dateFns from "date-fns";
import { makeStyles, MenuItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    minHeight: 150,
    padding: "0px 2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
}));

function WorkingTimeDuration() {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [workingHours, setWorkingHours] = useState(8);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    setStartTime(dateFns.format(selectedTime, "HH:mm"));
    console.log("dateFns addHours:");
    // 超過24小時 為 隔天 => 翌日
    // 超過48小時 為 後天 => 翌々日
    const prefixList = ["", "翌日", "翌々日"];
    let endHours =
      parseInt(dateFns.getHours(selectedTime)) + parseInt(workingHours);
    setEndTime(
      prefixList[Math.floor(endHours / 24)] +
        " " +
        dateFns.format(dateFns.addHours(selectedTime, workingHours), "HH:mm")
    );
  }, [selectedTime, workingHours]);
  return (
    <>
      <MedSearchPrimaryAccordion
        HeaderIcon={<AvTimerIcon />}
        HeaderTitle="時間帯指定"
        isExpanded={true}
      >
        <div className={classes.contentWrap}>
          <div>
            <TimePicker
              clearable
              ampm={false}
              label="開始時間"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
              value={selectedTime}
              onChange={setSelectedTime}
              style={{ marginRight: 16 }}
            />
            <TextField
              select
              label="Hours"
              value={workingHours}
              onChange={(e) => setWorkingHours(e.target.value)}
            >
              {Array.from(Array(49), (e, i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <Typography
              variant="h3"
              color="initial"
              style={{ marginBottom: 12 }}
            >
              【開始時刻】 {startTime}
            </Typography>
            <Typography variant="h3" color="initial">
              【終了時刻】 {endTime}
            </Typography>
          </div>
        </div>
      </MedSearchPrimaryAccordion>
    </>
  );
}

export default WorkingTimeDuration;
