import { makeStyles, SvgIcon } from "@material-ui/core";
import React from "react";
import { Switch, Route } from "react-router";
import ResumeIndex from "./Resume/ResumeIndex";

import { ReactComponent as Doctor01SVG } from "../../assets/images/icon/icon_leftmenu_doctor01.svg";
import { ReactComponent as Doctor02SVG } from "../../assets/images/icon/icon_leftmenu_doctor02.svg";
import { ReactComponent as Doctor03SVG } from "../../assets/images/icon/icon_leftmenu_doctor03.svg";
import { ReactComponent as Doctor05SVG } from "../../assets/images/icon/icon_leftmenu_doctor05.svg";
import { ReactComponent as Doctor06SVG } from "../../assets/images/icon/icon_leftmenu_doctor06.svg";

import { ReactComponent as Doctor08Svg } from "../../assets/images/icon/icon_leftmenu_doctor08.svg";
import { ReactComponent as Doctor04Svg } from "../../assets/images/icon/icon_leftmenu_doctor04.svg";
import { ReactComponent as Doctor07Svg } from "../../assets/images/icon/icon_leftmenu_doctor07.svg";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import LocalActivityOutlinedIcon from "@material-ui/icons/LocalActivityOutlined";
import MedSearchSideMenu from "../../components/MedSearchSideMenu";
import EditIndex from "./Resume/Edit/EditIndex";
import FavoriteIndex from "./Favorite/FavoriteIndex";
import JobsInfoIndex from "../General/Jobs/JobsInfo/JobsInfoIndex";
import JobsIndex from "./Jobs/JobsIndex";
import UploadCertifiacte from "./Resume/upload-certificate/UploadCertifiacte";
import JobsInfo from "./Jobs/JobsInfo";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1440,
    height: "fit-content",
    minHeight: "100vh",
    margin: "2% auto",
    marginTop: 100,
    display: "flex",
  },
  sideMenuWrap: {
    width: 280,
    height: 880,
    borderRight: "2px solid #eeeeee",
    paddingRight: "3%",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",

      paddingRight: "0%",
    },
  },
  contentWrap: {
    width: "100%",
    padding: "0 3%",
    height: "auto",
    maxWidth: 1160,
    overflow: "hidden",
  },
}));

const menuList = [
  {
    id: 1,
    icon: <SvgIcon component={Doctor01SVG} title="履歴書の管理" />,
    title: "履歴書の管理",
    link: "/doctor/resume",
  },
  {
    id: 2,
    icon: <SvgIcon component={CloudUploadOutlinedIcon} title="証明書UPLOAD" />,
    title: "証明書UPLOAD",
    link: "/doctor/upload_certificate",
  },
  {
    id: 3,
    icon: <SvgIcon component={Doctor02SVG} title="お気に入り" />,
    title: "お気に入り",
    link: "/doctor/favorite",
  },
  {
    id: 4,
    icon: <SvgIcon component={Doctor03SVG} title="案件管理" />,
    title: "案件管理",
    link: "/doctor/jobs",
  },
  {
    id: 5,
    icon: <SvgIcon component={Doctor04Svg} title="勤務確定通知" />,
    title: "勤務確定通知",
    link: "/doctor/",
  },
  {
    id: 6,
    icon: <SvgIcon component={Doctor05SVG} title="出勤後の評価" />,
    title: "出勤後の評価",
    link: "/doctor/",
  },
  {
    id: 7,
    icon: <SvgIcon component={Doctor06SVG} title="出勤・給与の確認" />,
    title: "メッセージ・チャット",
    link: "/doctor/",
  },

  {
    id: 8,
    icon: <SvgIcon component={Doctor07Svg} title="カレンダー" />,
    title: "カレンダー",
    link: "/doctor/",
  },
  {
    id: 9,
    icon: (
      <SvgIcon component={LocalActivityOutlinedIcon} title="ポイントの管理" />
    ),
    title: "ポイントの管理",
    link: "/doctor/point",
  },
  {
    id: 10,
    icon: <SvgIcon component={Doctor08Svg} title="アカウント設定" />,
    title: "アカウント設定",
    link: "/doctor/",
  },
];

function DoctorIndex() {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.sideMenuWrap}>
        <MedSearchSideMenu menuList={menuList} />
      </div>
      <div className={classes.contentWrap}>
        <Switch>
          <Route path="/doctor" exact>
            <ResumeIndex />
          </Route>

          <Route path="/doctor/resume" exact>
            <ResumeIndex />
          </Route>
          <Route path="/doctor/upload_certificate" exact>
            <UploadCertifiacte />
          </Route>
          <Route path="/doctor/resume/edit" exact>
            <EditIndex />
          </Route>
          <Route path="/doctor/favorite" exact>
            <FavoriteIndex />
          </Route>
          <Route path="/doctor/favorite/info" exact>
            <JobsInfoIndex />
          </Route>
          <Route path="/doctor/jobs/info/:jobStatus" exact>
            <JobsInfo />
          </Route>
          <Route path="/doctor/jobs" exact>
            <JobsIndex />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default DoctorIndex;
