import {
  FULL_TIME,
  PART_TIME,
  SCOUT,
  SPOT,
  TypesOfEmployment,
} from "../../../../assets/constant/TypesOfEmployment";
import {
  CANCELED,
  CONSIDERATION,
  NEGOTIATION,
  RECRUITMENT,
  WAITING_CONFIRMATION,
} from "./ApplicantStatusConstant";

export const applicantList = [
  {
    id: 1,
    name: "織田信永",
    statusContent: "採用",
    status: RECRUITMENT,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:30",
    employmentType: SPOT,
  },
  {
    id: 2,
    name: "織田信永",
    statusContent: "キャンセル",
    status: CANCELED,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:31",
    employmentType: FULL_TIME,
  },
  {
    id: 3,
    name: "織田信永",
    statusContent: "検討中",
    status: CONSIDERATION,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:32",
    employmentType: PART_TIME,
  },
  {
    id: 4,
    name: "織田信永",
    statusContent: "交渉中",
    status: NEGOTIATION,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:33",
    employmentType: SCOUT,
  },
  {
    id: 5,
    name: "織田信永",
    statusContent: "最終確認待ち",
    status: WAITING_CONFIRMATION,
    title: "【道東×皮膚科クリニック】破格の年収．．",
    subtitle: "1W-4385-12459 / 2021-01-14 18:34",
    employmentType: SPOT,
  },
];
