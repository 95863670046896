// スポット 年収＊
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    maxWidth: 280,
    marginBottom: "3%",
  },
  blockWrap: {
    display: "flex",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
}));

export default function FullTimeSalary() {
  const classes = useStyles();

  const [salaryOption, setSalaryOption] = useState(null);
  // salaryOption => 1 固定
  // salaryOption => 2 變動
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" labelPlacement="start">
          年収
        </FormLabel>
        <RadioGroup
          value={salaryOption}
          onChange={(e) => setSalaryOption(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="1" control={<Radio />} label="固定" />
          <FormControlLabel value="2" control={<Radio />} label="変動" />
        </RadioGroup>
      </FormControl>
      <br />
      {salaryOption === "1" ? (
        // <NumberFormat
        <TextField
          required
          // customInput={TextField}
          // thousandSeparator={true}
          type="text"
          label="年収"
          variant="standard"
          placeholder="年収を記載。数字のみ入力可"
          InputProps={{
            endAdornment: <InputAdornment position="end">万円</InputAdornment>,
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />
      ) : (
        <div className={classes.blockWrap}>
          <TextField
            required
            label="下限"
            variant="standard"
            placeholder="例：数字のみ入力可"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
            className={classes.textFiled}
            style={{ marginRight: 12 }}
          />
          <TextField
            label="上限"
            variant="standard"
            placeholder="例：数字のみ入力可"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
            className={classes.textFiled}
            style={{ marginRight: 12 }}
          />
          {/* <NumberFormat
            required
            customInput={TextField}
            thousandSeparator={true}
            label="下限"
            variant="standard"
            placeholder="例：数字のみ入力可"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
            className={classes.textFiled}
            style={{ marginRight: 12 }}
          />
          <NumberFormat
            customInput={TextField}
            thousandSeparator={true}
            label="上限"
            variant="standard"
            placeholder="例：数字のみ入力可"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
            className={classes.textFiled}
            style={{ marginRight: 12 }}
          /> */}
        </div>
      )}
    </div>
  );
}
