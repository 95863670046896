//募集要項ー編集
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import SpotWorkingDatetime from "./SpotWorkingDatetime";
import WorkingDatetime from "./WorkingDatetime";
import NumberFormat from "react-number-format";
import { RecuitmentContext } from "../../RecuitmentContext";
import SpotSalary from "./SpotSalary";
import PartTimeSalary from "./PartTimeSalary";
import FullTimeSalary from "./FullTimeSalary";
import {
  FULL_TIME,
  PART_TIME,
  SPOT,
} from "../../../../../../assets/constant/TypesOfEmployment";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    maxWidth: 280,
    marginBottom: "3%",
  },
  blockWrap: {
    display: "flex",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
}));

export default function Step4() {
  const classes = useStyles();
  const { createType } = useContext(RecuitmentContext);

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: "3%" }}>
        必須入力＊
      </Typography>

      {createType === SPOT ? <SpotSalary /> : null}
      {createType === FULL_TIME ? <FullTimeSalary /> : null}
      {createType === PART_TIME ? <PartTimeSalary /> : null}

      <TextField
        required
        label="募集人數"
        variant="standard"
        placeholder="数字のみ入力可"
        className={classes.textFiled}
        InputProps={{
          endAdornment: <InputAdornment position="end">人</InputAdornment>,
        }}
      />
      {/* <NumberFormat
        customInput={TextField}
        required
        isNumericString={true}
        label="募集人數"
        variant="standard"
        placeholder="数字のみ入力可"
        className={classes.textFiled}
        InputProps={{
          endAdornment: <InputAdornment position="end">人</InputAdornment>,
        }}
      /> */}
      {createType === SPOT ? <SpotWorkingDatetime /> : <WorkingDatetime />}
    </div>
  );
}
