import { createTheme } from "@material-ui/core/styles";
import { jaJP } from "@material-ui/core/locale";
import { MedSearchColors } from ".";

export const MedSearchTheme = createTheme(
  {
    palette: {
      primary: { main: "#14c8c8" },
      secondary: { main: "#19c897" },
      info: { main: "#4527a0" },
    },
    typography: {
      fontFamily: [
        "メイリオ",
        "Meiryo",
        "ヒラギノ角ゴ Pro W3",
        "Hiragino Kaku Gothic Pro",
        "ＭＳ Ｐゴシック",
        "sans-serif",
      ].join(","),
      // fontFamily: ["游ゴシック", "Times New Roman", "sans-serif"],
      fontSize: 16,
      h1: {
        position: "relative",
        fontSize: 26,
        letterSpacing: 2,
        fontWeight: 600,
        lineHeight: 3,
        color: MedSearchColors.primary,
        marginLeft: "1vw",
        "&::before": {
          position: "absolute",
          content: '" "',
          backgroundColor: MedSearchColors.primary,
          height: 34,
          width: 4,
          left: -12,
          top: 19,
        },
      },
      h2: {
        fontSize: 22,
        lineHeight: "40px",
        color: MedSearchColors.dark03dark,
      },
      h3: {
        fontSize: 18,
        lineHeight: "22px",
        color: MedSearchColors.dark03dark,
        fontWeight: 600,
      },
      h4: {
        fontSize: 12,
        color: MedSearchColors.dark04gray,
        fontWeight: 500,
        lineHeight: "16px",
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: MedSearchColors.dark03dark,
      },
      h6: {
        position: "relative",
        fontSize: 18,
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: 2,
        color: MedSearchColors.dark03dark,
        "&::before": {
          position: "absolute",
          content: '" "',
          backgroundColor: MedSearchColors.primary,
          height: 28,
          width: 2,
          left: -10,
          top: -3,
        },
      },
    },
  },
  jaJP
);
