import React from "react";

import {
  Dialog,
  DialogTitle,
  makeStyles,
  DialogContent,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import { MedSearchColors } from "../../assets/theme";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: MedSearchColors.primary,
    fontSize: 22,
    color: "white",
    textAlign: "center",
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    right: 8,
    top: 6,
    color: "white",
  },
  listItem: {
    "&:hover": {
      backgroundColor: MedSearchColors.secondary,
      color: "white",
    },
  },
}));

export default function MedSearchDialog({
  open,
  setOpen,
  title,
  content = false,
  children,
  showCloseButton = true,
  action = false,
}) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      className={classes.dialog}
    >
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        {title}　
        {showCloseButton && (
          <IconButton
            className={classes.closeBtn}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {content || <DialogContent>{children}</DialogContent>}
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  );
}
