import jaLocale from "date-fns/locale/ja";

const Calendar = {
  sunday: "日",
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  ok: " OK",
  today: " 今日",
  yesterday: " 昨日",
  hours: "時",
  minutes: "分 ",
  seconds: "秒",

  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",
  dateLocale: jaLocale,
};

export const localeJP = {
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "Last 7 Days",
  },
};
