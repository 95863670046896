//勤務日
import React, { useState } from "react";

import EventNoteIcon from "@material-ui/icons/EventNote";
import MedSearchPrimaryAccordion from "../common/MedSearchPrimaryAccordion";
import { DateRangePicker } from "rsuite";
import { Button } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

export default function WorkingDays() {
  const [workingDaysList, setWorkingDaysList] = useState([new Date()]);

  const addWorkingDays = () => {
    const tempList = workingDaysList;
    tempList.push(new Date());
    setWorkingDaysList([...tempList]);
  };
  const removeWorkingDays = () => {
    const tempList = workingDaysList;
    tempList.pop();
    setWorkingDaysList([...tempList]);
  };
  const setWorkingDays = (index, newVal) => {
    const tempList = workingDaysList;
    tempList[index] = newVal;
    setWorkingDaysList([...tempList]);
  };
  return (
    <>
      <MedSearchPrimaryAccordion
        HeaderTitle="勤務日"
        HeaderIcon={<EventNoteIcon />}
        isExpanded={true}
      >
        {workingDaysList.map((val, index) => (
          <DateRangePicker
            placeholder="勤務日"
            value={val}
            cleanable={false}
            ranges={[]}
            onOk={(newVal) => setWorkingDays(index, newVal)}
            size="lg"
            style={{ marginTop: 6 }}
          />
        ))}

        <div>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addWorkingDays}
            size="large"
          >
            日を追加
          </Button>

          <Button
            variant="text"
            color="primary"
            startIcon={<RemoveIcon />}
            onClick={removeWorkingDays}
            size="large"
          >
            日を削除
          </Button>
        </div>
      </MedSearchPrimaryAccordion>
    </>
  );
}
