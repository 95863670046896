import React from "react";
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MedSearchColors } from "../../assets/theme";

const MedSearchAccordion = withStyles({
  root: {
    backgroundColor: MedSearchColors.dark05lightgray,
    boxShadow: "none",
  },
})(Accordion);
const MedSearchAccordionSummary = withStyles({
  root: {
    color: "white",
    backgroundColor: MedSearchColors.dark04gray,
    minHeight: 26,
    height: 28,

    "&.Mui-expanded": {
      minHeight: 38,
    },
  },
  content: {
    alignItems: "center",
  },
})(AccordionSummary);

const MedSearchAccordionDetails = withStyles({
  root: {
    flexDirection: "column",
    padding: "2px 5px",
  },
})(AccordionDetails);

function MedSearchPrimaryAccordion(props) {
  const {
    HeaderIcon,
    HeaderTitle,
    isExpanded = false,
    children,
    lightTheme = false,
  } = props;

  return (
    <div style={{ marginTop: 4 }}>
      <MedSearchAccordion square={true} defaultExpanded={isExpanded}>
        <MedSearchAccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "white" }} fontSize="small" />
          }
          style={
            lightTheme
              ? { backgroundColor: MedSearchColors.secondary, height: 60 }
              : {}
          }
        >
          {HeaderIcon}
          <Typography
            style={{
              marginTop: 3,
              marginLeft: 8,
              color: "white",
              fontSize: 15,
            }}
          >
            {HeaderTitle}
          </Typography>
        </MedSearchAccordionSummary>
        <MedSearchAccordionDetails
          style={
            lightTheme
              ? {
                  backgroundColor: MedSearchColors.primary010,
                  padding: "6px 10px",
                }
              : {}
          }
        >
          {children}
        </MedSearchAccordionDetails>
      </MedSearchAccordion>
    </div>
  );
}

export default MedSearchPrimaryAccordion;
