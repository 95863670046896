//検討中
export const CONSIDERATION = 0;
//交渉中
export const NEGOTIATION = 1;
//交渉継続
// export const CONTINUE_NEGOTIATION = 2;
//採用
export const RECRUITMENT = 2;
//最終確認待ち (只有 スポット SPOT 會有此狀態 )
export const WAITING_CONFIRMATION = 3;
//キャンセル
export const CANCELED = 4;
