//Doctor Jobs Status
//応募済み
export const APPLIED = 0;
//要承認
export const APPROVAL = 1;
//交渉中
export const NEGOTIATION = 2;
//勤務確定
export const CONFIRM = 3;
//審査待ち
export const REVIEW = 4;

export const DOCTOR_JOBS_STATUS_LIST = [
  "応募済み",
  "要承認",
  "交渉中",
  "勤務確定",
  "審査待ち",
];
