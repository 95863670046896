import React, { Fragment, useState } from "react";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import BasicInfoContent from "../../General/Jobs/JobsInfo/BasicInfoContent";
import TrafficInfoContent from "../../General/Jobs/JobsInfo/TrafficInfoContent";
import JobInfoContent from "../../General/Jobs/JobsInfo/JobInfoContent";
import MedSearchFab from "../../../components/common/MedSearchFab";
import MedSearchTabs from "../../../components/common/MedSearchTabs";
import { useHistory, useParams } from "react-router-dom";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import CheckIcon from "@material-ui/icons/Check";

import {
  APPLIED,
  APPROVAL,
  CONFIRM,
  NEGOTIATION,
  REVIEW,
} from "../../../assets/constant/DoctorJobsStatus";
import MedSearchDialog from "../../../components/common/MedSearchDialog";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const fabMenuList = [
  {
    id: 1,
    icon: <DescriptionOutlinedIcon />,
    title: "応募",
    link: "/jobs",
  },
  {
    id: 2,
    icon: <SmsOutlinedIcon />,
    title: "チャット",
    link: "/jobs",
  },
  {
    id: 3,
    icon: <FavoriteBorderOutlinedIcon />,
    title: "お気に入り",
    link: "/jobs",
  },
];

const fabMenuList1 = [
  {
    id: 1,
    icon: <SmsOutlinedIcon />,
    title: "チャット",
    link: "/jobs",
  },
  {
    id: 2,
    icon: <ReplyOutlinedIcon />,
    title: "戻　る",
    link: "/doctor/jobs",
  },
];
const fabMenuList2 = [
  {
    id: 1,
    icon: <SmsOutlinedIcon />,
    title: "チャット",
    link: "/jobs",
  },
  {
    id: 2,
    icon: <FavoriteBorderOutlinedIcon />,
    title: "お気に入り",
    link: "/jobs",
  },
  {
    id: 3,
    icon: <ReplyOutlinedIcon />,
    title: "戻　る",
    link: "/doctor/jobs",
  },
];

export default function JobsInfo() {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  let { jobStatus } = useParams();
  const history = useHistory();
  const tabsList = [
    { id: 0, title: "求人情報", content: <JobInfoContent /> },
    { id: 1, title: "基本情報", content: <BasicInfoContent /> },
    { id: 2, title: "アクセス", content: <TrafficInfoContent /> },
  ];

  const fabMenuList3 = [
    {
      id: 1,
      icon: <CheckIcon />,
      title: "勤務確定",
      click: () => setOpenConfirmDialog(true),
    },
    {
      id: 2,
      icon: <SmsOutlinedIcon />,
      title: "チャット",
      link: "/jobs",
    },
    {
      id: 3,
      icon: <ReplyOutlinedIcon />,
      title: "戻　る",
      link: "/doctor/jobs",
    },
  ];
  const fabMenuObj = {};

  fabMenuObj[APPLIED] = fabMenuList1;
  //勤務確定
  fabMenuObj[CONFIRM] = fabMenuList1;
  //審査待ち
  fabMenuObj[REVIEW] = fabMenuList1;
  //交渉中
  fabMenuObj[NEGOTIATION] = fabMenuList2;
  //要承認
  fabMenuObj[APPROVAL] = fabMenuList3;

  return (
    <>
      <MedSearchFab menuList={fabMenuObj[jobStatus]} />
      <MedSearchTabs tabsList={tabsList} />
      <MedSearchDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title="勤務合意確認"
        children={
          <Fragment>
            <Typography
              variant="h5"
              color="initial"
              style={{ margin: "10px 0px" }}
            >
              当求人の勤務条件に合意の上、勤務します。
            </Typography>
            <Typography variant="h5" style={{ color: "red" }}>
              ※承認後のキャンセルなどはご遠慮願います。 <br />
              （承認ボタンを押すと、勤務確定）
            </Typography>
          </Fragment>
        }
        action={
          <Fragment>
            <Button
              color="secondary"
              onClick={() => history.push("/doctor/jobs")}
            >
              承　認
            </Button>{" "}
            <Button color="default" onClick={() => setOpenConfirmDialog(false)}>
              キャンセル
            </Button>
          </Fragment>
        }
      />
    </>
  );
}
