import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { MedSearchColors } from "../assets/theme";
import MedSearchInfoList from "./MedSearchInfoList";
import {
  demoConfirmData10,
  demoConfirmData11,
  demoConfirmData12,
  demoConfirmData13,
  demoConfirmData14,
  demoConfirmData15,
  demoConfirmData16,
  demoConfirmData17,
  demoConfirmData18,
} from "../views/Employer/Management/Recruitment/Create/DemoData";

const useStyles = makeStyles((theme) => ({
  profile: {
    marginTop: 20,
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    padding: "16px 14px",
    margin: "30px auto",
    width: "100%",
  },
}));

export default function MedSearchDoctorProfile3() {
  return (
    <div>
      <MedSearchInfoList title="医師の履歴書" dataList={demoConfirmData10} />

      <MedSearchInfoList title="学歴" dataList={demoConfirmData14} />

      <MedSearchInfoList title="職歴" dataList={demoConfirmData15} />

      <MedSearchInfoList title="免許・資格" dataList={demoConfirmData16} />
      <MedSearchInfoList title="学会" dataList={demoConfirmData17} />

      <>
        <MedSearchInfoList title="自己 PR" dataList={demoConfirmData11} />

        <TextField
          multiline
          rows={5}
          label="ご本人その他希望"
          defaultValue="当直はしたくない・・・・"
          InputProps={{
            readOnly: true,
          }}
          style={{ width: "100%", maxWidth: 800, marginTop: "3%" }}
        />
      </>

      <MedSearchInfoList title="証明書" dataList={demoConfirmData12} />
    </div>
  );
}
