import React from "react";
import MedSearchTabs from "../../../../components/common/MedSearchTabs";
import JobInfoContent from "./JobInfoContent";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import BasicInfoContent from "./BasicInfoContent";
import TrafficInfoContent from "./TrafficInfoContent";

const fabMenuList = [
  {
    id: 1,
    icon: <DescriptionOutlinedIcon />,
    title: "応募",
    link: "/jobs",
  },
  {
    id: 2,
    icon: <SmsOutlinedIcon />,
    title: "チャット",
    link: "/jobs",
  },
  {
    id: 3,
    icon: <FavoriteBorderOutlinedIcon />,
    title: "お気に入り",
    link: "/jobs",
  },
];
export default function JobsInfoIndex() {
  const tabsList = [
    { id: 0, title: "求人情報", content: <JobInfoContent /> },
    { id: 1, title: "基本情報", content: <BasicInfoContent /> },
    { id: 2, title: "アクセス", content: <TrafficInfoContent /> },
  ];
  return (
    <>
      <MedSearchFab menuList={fabMenuList} />
      <MedSearchTabs tabsList={tabsList} />
    </>
  );
}
