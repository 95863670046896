import { TextField } from "@material-ui/core";
import React, { useState } from "react";

export default function PR() {
  return (
    <>
      <TextField
        label="自己PR"
        fullWidth
        multiline
        rows={3}
        inputProps={{ maxLength: 300 }}
      />
    </>
  );
}
