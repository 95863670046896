import React, { useState, useEffect } from "react";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { MedSearchMedicalDepartmentList } from "../../assets/data/MedSearchMedicalDepartmentList";
import _ from "lodash";
import MedSearchSecondaryAccordion from "./MedSearchSecondaryAccordion";

export default function MedSearchDepartmentAccordion({
  setSelectedDepartmentList,
}) {
  //記錄每一個科系的checkbox選擇狀態
  const categoryObjects = {};
  const [isInternalChecked, setIsInternalChecked] = useState(false); //內科系 的checkbox是否點選
  const [isSurgeryChecked, setIsSurgeryChecked] = useState(false); //外科系 的checkbox是否點選
  const [isOphthalmologyChecked, setIsOphthalmologyChecked] = useState(false); //眼科・皮膚科・耳鼻科 的checkbox是否點選
  const [isPediatricsChecked, setIsPediatricsChecked] = useState(false); //小児科・産婦人科 的checkbox是否點選
  const [isPsychiatryChecked, setIsPsychiatryChecked] = useState(false); //精神科・心療内科 的checkbox是否點選
  const [isOthersChecked, setIsOthersChecked] = useState(false); //その他 的checkbox是否點選
  categoryObjects["内科系"] = isInternalChecked;
  categoryObjects["外科系"] = isSurgeryChecked;
  categoryObjects["眼科・皮膚科・耳鼻科"] = isOphthalmologyChecked;
  categoryObjects["小児科・産婦人科"] = isPediatricsChecked;
  categoryObjects["精神科・心療内科"] = isPsychiatryChecked;
  categoryObjects["その他"] = isOthersChecked;

  //從MedSearchMedicalDepartmentList取得資料並於useEffect中加入checked欄位以進行選取判斷
  const [medicalDepartmentList, setMedicalDepartmentList] = useState({});

  //從MedSearchMedicalDepartmentList取得資料並於useEffect中加入checked欄位以進行選取判斷
  useEffect(() => {
    const tempList = MedSearchMedicalDepartmentList.map((department) => ({
      ...department,
      checked: false,
    }));

    setMedicalDepartmentList(_.groupBy(tempList, "category"));
  }, []);

  //當使用者點選或取消checkbox時更新已選清單
  useEffect(() => {
    const tempSelectedList = [];
    Object.entries(medicalDepartmentList).forEach(
      ([cateory, departmentList]) => {
        departmentList.forEach((department) => {
          if (department.checked) tempSelectedList.push(department.department);
        });
      }
    );
    setSelectedDepartmentList(tempSelectedList);
  }, [medicalDepartmentList]);

  //記錄 選擇 單一科
  const handleCheckedDepartment = (category, departmentId, e) => {
    const tempMedicalDepartmentList = medicalDepartmentList;

    tempMedicalDepartmentList[category] = tempMedicalDepartmentList[
      category
    ].map((department) => ({
      ...department,
      checked:
        department.department_id.toString() === departmentId.toString()
          ? e.target.checked
          : department.checked,
    }));

    setMedicalDepartmentList({ ...tempMedicalDepartmentList });
  };

  //選擇某科別時，將該科別的 所有子項目 設定為選取
  const handleCheckedCategory = (category, e) => {
    const tempMedicalDepartmentList = medicalDepartmentList;
    tempMedicalDepartmentList[category] = tempMedicalDepartmentList[
      category
    ].map((department) => ({ ...department, checked: e.target.checked }));
    setMedicalDepartmentList({
      ...tempMedicalDepartmentList,
    });

    switch (category) {
      case "内科系":
        setIsInternalChecked(e.target.checked);
        break;
      case "外科系":
        setIsSurgeryChecked(e.target.checked);
        break;
      case "眼科・皮膚科・耳鼻科":
        setIsOphthalmologyChecked(e.target.checked);
        break;
      case "小児科・産婦人科":
        setIsPediatricsChecked(e.target.checked);
        break;
      case "精神科・心療内科":
        setIsPsychiatryChecked(e.target.checked);
        break;
      case "その他":
        setIsOthersChecked(e.target.checked);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {Object.entries(medicalDepartmentList).map(
        ([category, departmentList]) => (
          <MedSearchSecondaryAccordion
            key={category}
            HeaderTitle={category}
            isExpanded={true}
            handleCheckedCategory={handleCheckedCategory}
          >
            {departmentList.map((department) => (
              <FormControlLabel
                key={department.department_id}
                control={
                  <Checkbox
                    disabled={categoryObjects[category]}
                    size="small"
                    checked={department.checked}
                    onChange={(e) =>
                      handleCheckedDepartment(
                        category,
                        department.department_id,
                        e
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h5" style={{ marginLeft: -4 }}>
                    {department.department}
                  </Typography>
                }
                style={{ height: 34 }}
              />
            ))}
          </MedSearchSecondaryAccordion>
        )
      )}
    </>
  );
}
