// スポット - 勤務日時指定
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { MedSearchColors } from "../../../../../../assets/theme";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
  datetimeTitle: {
    marginBottom: "2%",
    marginTop: "6%",
    display: "flex",
    alignContent: "center",
    lineHeight: 2,
    fontSize: 16,
    fontWeight: 500,
    color: MedSearchColors.dark01,
  },
  datetimeWrap: {
    display: "flex",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
}));

export default function SpotWorkingDatetime() {
  const classes = useStyles();

  const [datetimeList, setDatetimeList] = useState([
    { id: 1, beginDatetime: null, endDatetime: null },
  ]);

  const changeBeginDatetime = (dateVal, changeItem) => {
    setDatetimeList(
      datetimeList.map((item) =>
        item.id === changeItem.id
          ? { ...changeItem, beginDatetime: dateVal }
          : item
      )
    );
  };
  const changeEndDatetime = (dateVal, changeItem) => {
    setDatetimeList(
      datetimeList.map((item) =>
        item.id === changeItem.id
          ? { ...changeItem, endDatetime: dateVal }
          : item
      )
    );
  };

  const addDatetime = () => {
    setDatetimeList([
      ...datetimeList,
      {
        id: datetimeList.length + 1,
        beginDatetime: null,
        endDatetime: null,
      },
    ]);
  };
  const removeDatetime = () => {
    // remove the latest one
    const removeId = datetimeList.length;
    //剩下最後一個不能刪
    if (removeId === 1) return;

    const tempList = datetimeList;
    tempList.pop();
    setDatetimeList([...tempList]);
  };
  return (
    <div style={{ marginBottom: "3%" }}>
      {datetimeList.map((item) => (
        <>
          <div className={classes.datetimeTitle}>
            <DateRangeIcon /> 日時{item.id}
          </div>
          <div className={classes.datetimeWrap}>
            <DateTimePicker
              required
              clearable
              value={item.beginDatetime}
              onChange={(e) => changeBeginDatetime(e, item)}
              label="開始日、時"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
              style={{ width: "100%", marginRight: 8 }}
            />
            <DateTimePicker
              required
              clearable
              value={item.endDatetime}
              onChange={(e) => changeEndDatetime(e, item)}
              label="終了日、時"
              cancelLabel="削除"
              clearLabel="キャンセル"
              okLabel="完了"
              style={{ width: "100%" }}
            />
          </div>
        </>
      ))}

      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={addDatetime}
        size="large"
      >
        日時を追加
      </Button>

      <Button
        variant="text"
        color="primary"
        startIcon={<RemoveIcon />}
        onClick={removeDatetime}
        size="large"
      >
        日時を削除
      </Button>
    </div>
  );
}
