export const defaultWorkingDayOfWeek = [
  {
    id: 1,
    day: "月",
    name: "monday",
    checked: true,
    beginTime: null,
    endTime: null,
  },
  {
    id: 2,
    day: "火",
    name: "tuesday",
    checked: false,
    beginTime: null,
    endTime: null,
  },
  {
    id: 3,
    day: "水",
    name: "sednesday",
    checked: false,
    beginTime: null,
    endTime: null,
  },
  {
    id: 4,
    day: "木",
    name: "thursday",
    checked: false,
    beginTime: null,
    endTime: null,
  },
  {
    id: 5,
    day: "金",
    name: "friday",
    checked: true,
    beginTime: null,
    endTime: null,
  },
  {
    id: 6,
    day: "土",
    name: "saturday",
    checked: false,
    beginTime: null,
    endTime: null,
  },
  {
    id: 7,
    day: "日",
    name: "sunday",
    checked: false,
    beginTime: null,
    endTime: null,
  },
];
