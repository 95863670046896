import React, { useState } from "react";
import { InputAdornment, TextField, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
export default function SearchBar() {
  const [keyWords, setKeyWords] = useState("");
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: 8,
        marginBottom: 5,
        padding: "2%",
        backgroundColor: "white",
      }}
    >
      <TextField
        label="キーワード"
        value={keyWords}
        variant="outlined"
        onChange={(e) => setKeyWords(e.target.value)}
        placeholder="この条件で検索する"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </div>
  );
}
