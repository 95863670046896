//カレンダー
import { Avatar, Calendar, CustomProvider } from "rsuite";
import * as dateFns from "date-fns";
import React from "react";
import "rsuite/dist/rsuite.min.css";
import { localeJP } from "./LocaleJP";
import Icon from "@rsuite/icons/lib/Icon";

import PeopleSpeakerIcon from "@rsuite/icons/PeopleSpeaker";
import MedSearchList from "../../../../components/common/MedSearchList";

function getTodoList(date) {
  const day = dateFns.getDate(date);

  switch (day) {
    case 10:
      return [
        { time: "10:30 am", title: "Meeting" },
        { time: "12:00 pm", title: "Lunch" },
      ];
    case 15:
      return [
        { time: "09:30 pm", title: "Products Introduction Meeting" },
        { time: "12:30 pm", title: "Client entertaining" },
        { time: "02:00 pm", title: "Product design discussion" },
        { time: "05:00 pm", title: "Product test and acceptance" },
        { time: "06:30 pm", title: "Reporting" },
        { time: "10:00 pm", title: "Going home to walk the dog" },
      ];
    default:
      return [];
  }
}

function renderCell(date) {
  const list = getTodoList(date);
  const displayList = list.filter((item, index) => index < 2);

  if (list.length) {
    return (
      <ul
        className="calendar-todo-list"
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {displayList.map((item, index) => (
          <li
            key={index}
            style={{
              margin: "0 2%",
            }}
          >
            <Avatar
              circle
              size="sm"
              style={{ background: "#14c8c8", padding: 6 }}
            >
              <Icon as={PeopleSpeakerIcon} />
            </Avatar>
          </li>
        ))}
      </ul>
    );
  }

  return null;
}

function CalendarIndex() {
  return (
    <>
      <CustomProvider locale={localeJP}>
        <Calendar
          bordered
          renderCell={renderCell}
          // onSelect={(e) => console.log(e)}
        />
      </CustomProvider>
      <MedSearchList>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            【道東×皮膚科クリニック】破格の年収．．
          </p>
          <p style={{ fontSize: 12, fontWeight: 500, color: "#999999" }}>
            2021-10-15 15:30
          </p>
        </div>
      </MedSearchList>
      <MedSearchList>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>
            【道東×皮膚科クリニック】破格の年収．．
          </p>
          <p style={{ fontSize: 12, fontWeight: 500, color: "#999999" }}>
            2021-10-15 18:30
          </p>
        </div>
      </MedSearchList>
    </>
  );
}

export default CalendarIndex;
