import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CleaningServices } from "@mui/icons-material";
import Step1 from "./Step1";
import MedSearchFab from "../../../../../components/common/MedSearchFab";
import RedoOutlinedIcon from "@material-ui/icons/RedoOutlined";
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  contentWrap: {
    width: "100%",
    maxWidth: 600,
    marginTop: "5%",
  },
  stepLabel: {
    "& .MuiStepIcon-root": {
      // color: "red",
    },
    "& .MuiStepIcon-text": {
      // fill: "white",
    },
  },
}));

function getSteps() {
  return [
    "勤務先情報",
    "職務內容",
    "医師条件",
    "日時・給与人数指定",
    "その他支給",
    "その他特記事項",
    "確認画面",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    case 3:
      return <Step4 />;
    case 4:
      return <Step5 />;
    case 5:
      return <Step6 />;
    case 6:
      return <Step7 />;

    default:
      return "Unknown step";
  }
}

export default function CreateIndex() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const history = useHistory();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleCreate = () => {
    history.push("/employer/management/recruitment");
  };

  const fabMenuList = [
    {
      id: 1,
      icon: <RedoOutlinedIcon />,
      title: "次のページ",
      link: "",
      click: () => handleNext(),
    },
    {
      id: 2,
      icon: <UndoOutlinedIcon />,
      title: "前のページ",
      link: "",
      click: () => handleBack(),
      disabled: activeStep === 0,
    },
  ];
  const fabMenuList2 = [
    {
      id: 1,
      icon: <PostAddIcon />,
      title: "登録",
      link: "",
      click: () => handleCreate(),
    },
    {
      id: 2,
      icon: <UndoOutlinedIcon />,
      title: "前のページ",
      link: "",
      click: () => handleBack(),
      disabled: activeStep === 0,
    },
  ];

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel style={{ padding: 0 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} style={{ padding: 0 }}>
              <StepLabel {...labelProps} className={classes.stepLabel}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div className={classes.contentWrap}>{getStepContent(activeStep)}</div>

      {activeStep === steps.length - 1 ? (
        <MedSearchFab menuList={fabMenuList2} />
      ) : (
        <MedSearchFab menuList={fabMenuList} />
      )}
    </div>
  );
}
