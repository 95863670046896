import React from "react";
import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from "@material-ui/core";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import MedSearchInfoList from "../../../../components/MedSearchInfoList";
import {
  demoConfirmData1,
  demoConfirmData19,
  demoConfirmData2,
  demoConfirmData7,
} from "../Recruitment/Create/DemoData";
import MedSearchDoctorProfile2 from "../../../../components/MedSearchDoctorProfile2";
import { MedSearchColors } from "../../../../assets/theme";
import MedSearchDoctorProfileWithAccordion from "../../../../components/MedSearchDoctorProfileWithAccordion";

const fabMenuList = [
  {
    id: 1,
    icon: <ChevronLeftIcon />,
    title: "戻る",
    link: "/employer/management/notification",
  },
];

export default function NotificationInfo() {
  return (
    <div>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">勤務確定通知</Typography>
      <MedSearchInfoList title="勤務先情報" dataList={demoConfirmData1} />
      <MedSearchInfoList title="勤務内容" dataList={demoConfirmData2} />
      <MedSearchInfoList title="勤務時間" dataList={demoConfirmData19} />

      <MedSearchInfoList title="緊急連絡先" dataList={demoConfirmData7} />
      {/* <MedSearchInfoList title="勤務医情報" dataList={demoConfirmData8} /> */}
      <MedSearchDoctorProfileWithAccordion />
    </div>
  );
}
