import React, { Fragment } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { MedSearchColors } from "../assets/theme";

import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  list: {
    minWidth: 260,
    transition: "0.3s",

    [theme.breakpoints.down("sm")]: {
      minWidth: 60,
    },
  },
  menuListItem: {
    minHeight: 60,
    borderBottom: `1px solid ${MedSearchColors.border}`,
    "&:hover .MuiTypography-root": {
      color: MedSearchColors.primary,
    },
    "&:hover .MuiListItemIcon-root": {
      color: MedSearchColors.primary,
    },
    [theme.breakpoints.down("sm")]: {
      width: 60,
    },
  },
  menuIcon: { minWidth: 44 },
  menuTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },

    "&.active>.MuiTypography-root": {
      fontSize: 16,
      color: MedSearchColors.primary,
    },
    "&>.MuiTypography-root": {
      fontSize: 16,
      color: MedSearchColors.dark03dark,
    },
  },
}));

function MedSearchSideMenu({ menuList }) {
  const classes = useStyle();

  return (
    <List className={classes.list}>
      {menuList.map((item) => (
        <Fragment key={item.id}>
          <ListItem
            className={classes.menuListItem}
            button
            component={Link}
            to={item.link}
          >
            <ListItemIcon className={classes.menuIcon} title={item.title}>
              {item.icon}
            </ListItemIcon>
            <ListItemText className={classes.menuTitle}>
              {item.title}
            </ListItemText>
          </ListItem>
        </Fragment>
      ))}
    </List>
  );
}

export default MedSearchSideMenu;
