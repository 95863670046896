export const demoBasicInfoList = [
  // { id: 1, title: "管理者 Email", value: "manager@test.com" },
  { id: 2, title: "貴院（貴社）名称", value: "東名厚木病院" },
  { id: 3, title: "所在地", value: "神奈川県　厚木市" },
  { id: 4, title: "郵送先住所", value: "243-8571 神奈川県厚木市船子 232" },
  { id: 5, title: "電話番号", value: "064-229-1771" },
  // { id: 6, title: "管理者氏名", value: "日本花子" },
  // { id: 7, title: "所属部署・役職", value: "" },
];

export const demoHospitalInfo = [
  { id: 1, title: "施設形態", value: "病院" },
  {
    id: 2,
    title: "診療科目",
    value:
      "内科 消化器内科 呼吸器内科 循環器内科 神経内科 心療内科 整形外科 リハビリテーション科 人工透析科",
  },
  { id: 3, title: "病床数", value: "100〜199 床" },
  { id: 4, title: "救急指定", value: "二次" },
  { id: 5, title: "常勤医師数", value: 30 },
  { id: 6, title: "電子カルテ", value: "有" },
  {
    id: 7,
    title: "医療設備",
    value:
      "人工透析、リハビリ室、MRI(1.5T)、CT（64.16）、エコー（頸・腹、心）他",
  },
  {
    id: 8,
    title: "認定施設",
    value:
      "日本内科学会教育関連病院 \n日本循環器学会研修施設 \n日本血液学会研修施設",
  },
  // { id: 9, title: "保育所", value: "有" },
  { id: 10, title: "交通手段", value: "243-8571 神奈川県厚木市船子 232" },
  { id: 11, title: "備考", value: "" },
];
