//募集要項ー編集
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
}));

export default function Step5() {
  const classes = useStyles();
  const [carfare, setCarfare] = useState("true");

  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: "3%" }}>
        必須入力＊
      </Typography>

      <FormControl component="fieldset">
        <FormLabel component="legend">交通費</FormLabel>
        <RadioGroup
          value={carfare}
          onChange={(e) => setCarfare(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="true" control={<Radio />} label="支給あり" />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="支給なし"
          />
        </RadioGroup>
      </FormControl>
      <br />
      {carfare === "true" ? (
        <TextField
          required
          label="交通費"
          variant="standard"
          placeholder="実費支給(上限○円)、公共交通機関のみ支給、一律 ○円など"
          className={classes.textFiled}
        />
      ) : null}

      <Typography variant="h6" style={{ margin: "3% 0px" }}>
        任意入力
      </Typography>
      <TextField
        label="成果報酬"
        variant="standard"
        placeholder="例：救急車受け入れ ○円/件、入院 ○円/件、オンコール出動 ○円/件 ・・など"
        className={classes.textFiled}
      />

      <TextField
        label="残業代・その他"
        variant="standard"
        placeholder="残業代の有無と金額。スキル、経験、資格等による増減など"
        className={classes.textFiled}
      />
    </div>
  );
}
