//募集要項ー編集
import { TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { SPOT } from "../../../../../assets/constant/TypesOfEmployment";
import { RecuitmentContext } from "../RecuitmentContext";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    marginBottom: "3%",
  },
}));

export default function Step6() {
  const classes = useStyles();
  const { createType } = useContext(RecuitmentContext);

  return (
    <div>
      <Typography variant="h6" style={{ margin: "3% 0px" }}>
        任意入力
      </Typography>
      <TextField
        label="備考"
        variant="standard"
        placeholder={
          createType === SPOT
            ? "例：初回集合時間、車通勤の可否、時間調整の可否、その他伝達事項"
            : "例：日時調整の可否、祝日勤務の有無、車通勤の可否、その他伝達事項"
        }
        className={classes.textFiled}
      />

      {createType === SPOT ? (
        <>
          <TextField
            label="持参物"
            variant="standard"
            placeholder="例：各種証明書、印鑑、口座情報、健診結果など"
            className={classes.textFiled}
          />
          <TextField
            label="環境"
            variant="standard"
            placeholder="例：カルテ(紙 or 電子)、ネット環境、 PC持込可否、個室の使用可否、ロッカー、TVなど"
            className={classes.textFiled}
          />
        </>
      ) : (
        <TextField
          label="見学・トライアル"
          variant="standard"
          placeholder="例：見学やトライアル勤務の可否。対応可能な曜日や時間帯"
          className={classes.textFiled}
        />
      )}
    </div>
  );
}
