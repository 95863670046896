import React, { useState } from "react";
import {
  IconButton,
  makeStyles,
  Typography,
  Button,
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { MedSearchPagination } from "../../../components/common/MedSearchPagination";
import { MedSearchColors } from "../../../assets/theme";
import { Divider } from "rsuite";
import { ReactComponent as IconListH } from "../../../assets/images/list_h.svg";
import { TypesOfEmployment } from "../../../assets/constant/TypesOfEmployment";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  infoWrap: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  titleWrap: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: 96,
      justifyContent: "space-around",
    },
  },
  btnWrap: {
    display: "flex",
    justifyContent: "space-between",
    width: 140,
  },
  typeLabel: {
    width: 100,
    fontSize: 16,
    color: MedSearchColors.primary,
    border: `1px solid ${MedSearchColors.primary}`,
    textAlign: "center",
    lineHeight: "28px",
  },
}));

const JobsListItem = withStyles((theme) => ({
  root: {
    boxShadow: `1px 1px 3px 0 ${MedSearchColors.shadow}`,
    marginBottom: "2%",
  },
}))(ListItem);

export default function JobsList() {
  const classes = useStyles();
  const [chronological, setChronological] = useState(false);
  const history = useHistory();
  let { type } = useParams();

  return (
    <div>
      <Typography variant="h1">{`${TypesOfEmployment[type]}求人リスト`}</Typography>

      <div className={classes.infoWrap}>
        <Typography variant="h2">合計 280 件ＯＯＯＯＯＯＯＯＯＯ</Typography>
        <Button
          variant="text"
          onClick={() => setChronological(!chronological)}
          endIcon={chronological ? <ExpandLess /> : <ExpandMore />}
        >
          新着順
        </Button>
      </div>

      <List>
        {Array.from(Array(6), (e, i) => (
          <JobsListItem alignItems="flex-start" key={i}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: MedSearchColors.primary }}>
                <IconListH />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <div className={classes.titleWrap}>
                  東名厚木病院 消化器內科
                  <div className={classes.btnWrap}>
                    <span className={classes.typeLabel}>
                      {TypesOfEmployment[type]}
                    </span>

                    <IconButton
                      style={{
                        backgroundColor: MedSearchColors.secondary,
                        borderRadius: 0,
                        width: 30,
                        height: 30,
                      }}
                    >
                      <FavoriteIcon style={{ color: "white", width: 26 }} />
                    </IconButton>
                  </div>
                </div>
              }
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="h4">
                    勤務地
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" "}
                    神奈川県　厚木市
                  </Typography>
                  <Divider style={{ margin: "8px auto" }} />
                  <Typography component="span" variant="h4">
                    勤務形態
                  </Typography>
                  <Typography component="span" variant="h5">
                    {" "}
                    {TypesOfEmployment[type]}
                  </Typography>

                  <Divider style={{ margin: "8px auto" }} />
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h4"
                      style={{ minWidth: 55 }}
                    >
                      募集科目
                    </Typography>
                    <Typography component="div" variant="h5">
                      消化器内科、腎臓内科、呼吸器外科、乳腺外科、救命救急科、救命救急科
                    </Typography>
                  </div>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => history.push(`/jobs/${type}/info`)}>
                <ArrowForwardIosIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </JobsListItem>
        ))}
      </List>

      <MedSearchPagination count={7} color="default" />
    </div>
  );
}
