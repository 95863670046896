// 常勤、非常勤 年収＊
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  textFiled: {
    width: "100%",
    maxWidth: 280,
    marginBottom: "3%",
  },
  blockWrap: {
    display: "flex",
    marginBottom: "3%",
    [theme.breakpoints.down(480)]: {
      flexDirection: "column",
    },
  },
}));

export default function PartTimeSalary() {
  const classes = useStyles();

  const [salaryOption, setSalaryOption] = useState("1");
  // salaryOption => 1 時給制
  // salaryOption => 2 日給制
  // salaryOption => 3 その他

  const [hourlyWageOption, setHourlyWageOption] = useState("1");
  // hourlyWageOption => 1 固定
  // hourlyWageOption => 2 變動

  // /*
  // salaryOption => 1 時給制 (時給制、日給制 情況下才有 固定、變動 選項)
  // salaryOption => 2 日給制
  //  hourlyWageOption => 1 固定
  //   */
  const wageSection = () => {
    return hourlyWageOption === "1" ? (
      <div>
        <TextField
          required
          customInput={TextField}
          label={salaryOption === "1" ? "時給" : "日給"}
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />

        {/* 
              salaryOption => 1 時給制
              salaryOption => 2 日給制 */}
        {/* <NumberFormat
          required
          customInput={TextField}
          thousandSeparator={true}
          label={salaryOption === "1" ? "時給" : "日給"}
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                
                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        /> */}
      </div>
    ) : (
      <div className={classes.blockWrap}>
        {/*              
              salaryOption => 1 時給制
              salaryOption => 2 日給制 */}
        <TextField
          required
          label="下限"
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />
        <TextField
          label="上限"
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />
        {/* <NumberFormat
          required
          customInput={TextField}
          thousandSeparator={true}
          label="下限"
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
              

                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />
        <NumberFormat
          customInput={TextField}
          thousandSeparator={true}
          label="上限"
          variant="standard"
          placeholder="数字のみ入力可"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
             
                {salaryOption === "1" ? "円/時" : "円/日"}
              </InputAdornment>
            ),
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        /> */}
      </div>
    );
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" labelPlacement="start">
          給与計算方式
        </FormLabel>
        <RadioGroup
          value={salaryOption}
          onChange={(e) => setSalaryOption(e.target.value)}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="1" control={<Radio />} label="時給制" />
          <FormControlLabel value="2" control={<Radio />} label="日給制" />
          <FormControlLabel value="3" control={<Radio />} label="その他" />
        </RadioGroup>
      </FormControl>
      <br />

      {/* 
         (時給制、日給制 情況下才有 固定、變動 選項)
            salaryOption => 1 時給制
            salaryOption => 2 日給制
      */}
      {salaryOption !== "3" ? (
        <>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend" labelPlacement="start">
              給与計算方式
            </FormLabel> */}
            <RadioGroup
              value={hourlyWageOption}
              onChange={(e) => setHourlyWageOption(e.target.value)}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel value="1" control={<Radio />} label="固定" />
              <FormControlLabel value="2" control={<Radio />} label="変動" />
            </RadioGroup>
          </FormControl>
          {wageSection()}
        </>
      ) : (
        // salaryOption => 3 その他
        <TextField
          required
          label="月給"
          variant="standard"
          placeholder="月給○万円など"
          InputProps={{
            endAdornment: <InputAdornment position="end">円/月</InputAdornment>,
          }}
          className={classes.textFiled}
          style={{ marginRight: 12 }}
        />

        // <NumberFormat
        //   required
        //   customInput={TextField}
        //   thousandSeparator={true}
        //   label="月給"
        //   variant="standard"
        //   placeholder="月給○万円など"
        //   InputProps={{
        //     endAdornment: <InputAdornment position="end">円/月</InputAdornment>,
        //   }}
        //   className={classes.textFiled}
        //   style={{ marginRight: 12 }}
        // />
      )}
    </div>
  );
}
