import { Typography } from "@material-ui/core";
import React from "react";
import MedSearchDoctorProfile3 from "../../../components/MedSearchDoctorProfile3";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MedSearchFab from "../../../components/common/MedSearchFab";
const fabMenuList = [
  {
    id: 1,
    icon: <EditIcon />,
    title: "作成/変更 履歴書",
    link: "/doctor/resume/edit",
  },
  {
    id: 2,
    icon: <DeleteOutlineIcon />,
    title: "履歴書を削除",
    link: "/",
  },
  {
    id: 2,
    icon: <CloudDownloadOutlinedIcon />,
    title: "ダウンロード履歴書",
    link: "/",
  },
];

export default function ResumeIndex() {
  return (
    <>
      <Typography variant="h1" color="initial">
        履歴書の管理
      </Typography>
      <MedSearchDoctorProfile3 />

      <MedSearchFab menuList={fabMenuList} />
    </>
  );
}
