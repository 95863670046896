import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import MedSearchDepartmentAccordion from "../../../../components/common/MedSearchDepartmentAccordion";
import MedSearchFab from "../../../../components/common/MedSearchFab";
import { DropzoneDialog } from "material-ui-dropzone";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import useJapanCity from "../../../../hooks/useJapanCity";
import MedSearchAddressSetting from "../../../../components/common/MedSearchAddressSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
  },
  uploadImgWrap: {
    maxWidth: 600,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("730")]: {
      flexDirection: "column",
      height: 510,
    },
    "& figure": {
      position: "relative",
      // width: "100%",
      width: 194,
      height: 120,
      [theme.breakpoints.down("730")]: {
        minWidth: 280,
        minHeight: 160,
      },
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  removeImgBtn: {
    position: "absolute",
    top: "3%",
    right: "2%",
    width: 38,
    height: 38,
  },
  uploadImgBtn: {
    width: 194,
    height: 120,
    [theme.breakpoints.down("730")]: {
      minWidth: 280,
      minHeight: 160,
    },
  },
  editTextField: {
    width: "100%",
    maxWidth: 600,
    marginBottom: 30,
  },
  textFieldWrap: {
    display: "flex",
    maxWidth: 600,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  departmentWrap: {
    maxWidth: 630,
    marginBottom: 30,
  },
  uploadCertificateWrap: {
    "& figure": {
      position: "relative",
      width: 280,
      height: 180,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

const fabMenuList = [
  {
    id: 1,
    icon: <SaveOutlinedIcon />,
    title: "情報を更新",
    link: "/employer/management",
  },
  {
    id: 2,
    icon: <ReplyOutlinedIcon />,
    title: "キャンセル",
    link: "/employer/management",
  },
];

export default function EmployerInfoSettings() {
  const classes = useStyles();
  //電子カルテ(電子病歷)Raddio
  const [eMedicalRecord, setEMedicalRecord] = useState(null);
  const [institution, setInstitution] = useState("1");
  const [uploadImg, setUploadImg] = useState(false);

  //選擇的醫療科系
  const [selectedDepartmentList, setSelectedDepartmentList] = useState({});
  const [japanZip, setJapanZip] = useState("5400002");
  const japanCity = useJapanCity(japanZip);

  return (
    <div className={classes.root}>
      <MedSearchFab menuList={fabMenuList} />
      <Typography variant="h1">雇用主の情報設定</Typography>
      <div className={classes.uploadImgWrap}>
        <figure>
          <img src="/assets/images/Demo/hospital-1.png" alt="" />
          <IconButton
            style={{ background: "white" }}
            className={classes.removeImgBtn}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </figure>
        <figure>
          <img src="/assets/images/Demo/hospital-1.png" alt="" />
          <IconButton
            style={{ background: "white" }}
            className={classes.removeImgBtn}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </figure>
        <Button
          variant="contained"
          onClick={() => setUploadImg(true)}
          className={classes.uploadImgBtn}
        >
          <CameraAltIcon />
        </Button>

        <DropzoneDialog
          dialogTitle="Upload Image"
          open={uploadImg}
          onSave={(e) => setUploadImg(e)}
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setUploadImg(false)}
        />
      </div>

      <Typography variant="h6" style={{ margin: "30px 0" }}>
        基本情報
      </Typography>
      {/* <TextField
        label="管理者 Email (非公開)"
        defaultValue="manager@test.com"
        className={classes.editTextField}
      /> */}
      <TextField
        required
        label="貴院（貴社）名称"
        defaultValue="東名厚木病院"
        className={classes.editTextField}
      />
      <div className={classes.textFieldWrap}>
        <TextField
          required
          label="所在地"
          defaultValue="神奈川県"
          className={classes.editTextField}
          style={{ marginRight: 8 }}
        />
        <TextField
          label=" "
          defaultValue="厚木市"
          className={classes.editTextField}
        />
      </div>
      <MedSearchAddressSetting />
      <TextField
        required
        label="電話番号"
        defaultValue="064-229-1771"
        className={classes.editTextField}
      />
      {/* <TextField
        label="管理者氏名 (非公開)"
        defaultValue="日本花子"
        className={classes.editTextField}
      /> */}
      {/* <TextField
        label="所属部署・役職 (非公開)"
        defaultValue="部署"
        className={classes.editTextField}
      /> */}
      <Typography variant="h6" style={{ margin: "30px 0" }}>
        雇用主の確認資料(情報)
      </Typography>
      <div style={{ marginBottom: 30 }}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">電子カルテ</FormLabel> */}
          <RadioGroup
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
            style={{ flexDirection: "row" }}
          >
            <FormControlLabel value="1" control={<Radio />} label="医療機関" />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="それ以外の法人"
            />
          </RadioGroup>
        </FormControl>
      </div>

      {institution === "1" ? (
        <TextField
          label="医療機関コード（必須）"
          defaultValue="医療機関コード"
          className={classes.editTextField}
          required
        />
      ) : (
        <div className={classes.uploadCertificateWrap}>
          <Typography
            variant="h5"
            style={{ color: "rgba(0, 0, 0, 0.54)", marginBottom: 16 }}
          >
            登記簿謄本（現在事項証明書 or 履歴事項証明書）
          </Typography>
          <figure>
            <img src="/assets/images/Demo/img_certificatesample.jpg" alt="" />
            <IconButton
              style={{ background: "white" }}
              className={classes.removeImgBtn}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </figure>

          {/* <Button
            variant="contained"
            onClick={() => setUploadImg(true)}
            className={classes.uploadImgBtn}
          >
            <CameraAltIcon />
          </Button> */}
        </div>
      )}

      <Typography variant="h1" style={{ marginTop: 30 }}>
        医療機関情報
      </Typography>
      <TextField
        label="施設形態"
        defaultValue="施設形態"
        className={classes.editTextField}
      />
      <div className={classes.departmentWrap}>
        <Typography
          variant="h5"
          style={{ color: "rgba(0, 0, 0, 0.54)", marginBottom: 6 }}
        >
          診療科目
        </Typography>
        <MedSearchDepartmentAccordion
          setSelectedDepartmentList={setSelectedDepartmentList}
        />
      </div>
      <TextField
        label="病床数"
        defaultValue="350"
        className={classes.editTextField}
      />
      <TextField
        label="救急指定"
        defaultValue="救急指定"
        className={classes.editTextField}
      />
      <TextField
        label="常勤医師数"
        defaultValue="常勤医師数"
        className={classes.editTextField}
      />
      <div style={{ marginBottom: 30 }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">電子カルテ</FormLabel>
          <RadioGroup
            value={eMedicalRecord}
            onChange={(e) => setEMedicalRecord(e.target.value)}
            style={{ flexDirection: "row" }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="あり" />
            <FormControlLabel value="no" control={<Radio />} label="なし" />
          </RadioGroup>
        </FormControl>
      </div>
      <TextField
        label="医療設備"
        defaultValue="医療設備"
        className={classes.editTextField}
      />
      <TextField
        label="認定施設"
        defaultValue="認定施設"
        className={classes.editTextField}
      />
      <TextField
        label="交通手段"
        defaultValue="交通手段"
        className={classes.editTextField}
      />
      <TextField
        label="備考"
        defaultValue="備考"
        className={classes.editTextField}
      />
    </div>
  );
}
