import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useHistory } from "react-router";

import MedSearchDialog from "../../../../../components/common/MedSearchDialog";

const useStyles = makeStyles((theme) => ({
  content: {
    height: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  btnWrap: {
    textAlign: "center",
  },
}));

export default function ApproveDialog({ open, setOpen }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <MedSearchDialog
        open={open}
        setOpen={setOpen}
        title="採用確認"
        showCloseButton={false}
      >
        <div className={classes.content}>
          <TextField
            required
            readOnly
            value={"12,345"}
            type="text"
            label="想定年収"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">万円</InputAdornment>
              ),
            }}
            className={classes.textFiled}
            style={{ marginRight: 12 }}
          />

          <TextField
            required
            readOnly
            label="請求書宛名"
            value="道東×皮膚科クリニック"
          />
          <div style={{ marginTop: 12 }}>
            <p
              style={{
                color: "rgba(0, 0, 0, 0.54)",
                fontSize: 18,
              }}
            >
              医療機関、あるいは法人名　
            </p>
            <TextField readOnly label="担当部署" value="皮膚科" />
            <TextField readOnly label="担当者名" value="織田信永" />
          </div>

          <div style={{ marginTop: 12 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">送付方法 *</FormLabel>
            </FormControl>
            <TextField
              label="Emailアドレス"
              value="doctor.space@gmail.com"
              fullWidth
            />
          </div>
          <div style={{ marginTop: 12 }}>
            <p style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 18 }}>
              開始予定時期
            </p>
            <TextField required readOnly label="年/月" value="2021/12" />

            <TextField readOnly label="日" value="5"></TextField>
          </div>

          <div className={classes.btnWrap}>
            <Button
              color="primary"
              onClick={() => history.push("/employer/management/applicant/")}
            >
              送信
            </Button>
            <Button onClick={() => setOpen(false)}>キャンセル</Button>
          </div>
        </div>
      </MedSearchDialog>
    </div>
  );
}
