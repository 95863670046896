import React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MedSearchColors } from "../../assets/theme";

const useStyle = makeStyles((theme) => ({
  fabWrap: {
    position: "fixed",
    top: "20%",
    right: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 200,
  },
  fab: {
    fontSize: 18,
    justifyContent: "flex-start",
    color: MedSearchColors.primary,
    backgroundColor: "white",
    border: `1px solid ${MedSearchColors.primary}`,
    marginBottom: 10,
    borderRadius: "3px !important ",
    padding: "20px 12px !important",

    "&:hover": {
      backgroundColor: MedSearchColors.primary,
      color: "white",
    },
    "&.active": {
      color: "white",
      backgroundColor: MedSearchColors.primary,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 44,
      padding: "20px 8px !important",
    },
  },
  fabTitle: {
    margin: "auto 6px",
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
      display: "none",
    },
  },
}));
export default function MedSearchFab({ menuList }) {
  const classes = useStyle();
  console.log(menuList);
  return (
    <div className={classes.fabWrap}>
      {menuList.map((item) =>
        item.link ? (
          <Fab
            key={item.id}
            variant="extended"
            size="small"
            color="primary"
            className={classes.fab}
            component={Link}
            to={item.link}
            disabled={item.disabled}
          >
            {item.icon} <span className={classes.fabTitle}> {item.title}</span>
          </Fab>
        ) : (
          <Fab
            key={item.id}
            variant="extended"
            size="small"
            color="primary"
            className={classes.fab}
            onClick={item.click}
            disabled={item.disabled}
          >
            {item.icon} <span className={classes.fabTitle}> {item.title}</span>
          </Fab>
        )
      )}
    </div>
  );
}
